import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import {
    MAX_L_TABLET,
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_XL_TABLET,
} from "src/pages/game-new/constants";
import { windowsSizeParams } from "src/consts/window-size-params";
import { appColors } from "src/consts/app-config";

import bgProfile from "./linear-gradient/img/bg-profile-full.png";

interface IFullscreenProps {
    isPwa: boolean;
}

const isPWA = (status: boolean) => {
    // Added scroll if needs
    return status ? "hidden" : "scroll";
};

export const useStyles = makeStyles({
    fullscreen: {
        position: "absolute",
        overflow: "hidden",
        top: 0,
        left: 0,
        right: 0,
        minHeight: "100%",
        maxHeight: "100%",
        background: "#000000",
        zIndex: -5,
        display: "grid",
        gridTemplateRows: "auto 1fr",
        "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: -1,
            width: "100%",
            height: "100%",
            //background:
            //    "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #343A59 0%, rgba(52, 58, 89, 0) 100%)",
            animationName: "$gameInfoDot",
            animationDelay: "0s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "1500ms",
            [theme.breakpoints.down("lg")]: {
                position: "fixed",
            },
        },
        "&.darkBlue": {
            "&::before": {
                //background:
                //    "black radial-gradient(50% 50% at 30% 40px, rgba(80, 0, 117, 0.3) 0%, rgba(9, 0, 35, 0.3) 100%)",
            },
        },
        "&.darkGreen": {
            "&.isGamePageGreen": {
                "&::before": {
                    [theme.breakpoints.up("xs")]: {
                        //background:
                        //    "radial-gradient(77.06% 208.73% at 62.26% 10.35%, #03373A 2.88%, rgba(52, 58, 89, 0) 71.12%)",
                    },
                    [theme.breakpoints.up(1250)]: {
                        //background:
                        //    "radial-gradient(63.19% 181.28% at 51.57% 62.5%, rgb(0, 73, 78) 0%, rgb(0, 0, 0) 100%)",
                    },
                },
            },
            "&::before": {
                //background:
                //    "radial-gradient(63.19% 181.28% at 51.57% 62.5%, rgb(0, 73, 78) 0%, rgb(0, 0, 0) 100%)",
            },
        },
        [theme.breakpoints.down("md")]: {
            "&.isGamePageGreen": {
                background: "#000000",
            },
            //background:
            //    "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #343A59 0%, rgb(13 17 36) 68%)",
        },
        [theme.breakpoints.down("sm")]: {
            overflowX: "hidden",
            overflowY: "hidden",
            // overflowY: isPWA(isPwa),
        },
        "&.deepBlack": {
            background: appColors.deepBlack,
            "&::before": {
                display: "none",
            },
        },
    },

    "@keyframes gameInfoDot": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },

    pokerLogoWrapper: {
        display: "flex",
        width: "fit-content",
        top: "-8px",
        position: "absolute",
        zIndex: 201,
        left: "50%",
        transform: "translate(-50%, 0%) scale(0.63)",
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            "@media screen and (orientation: portrait)": {
                left: "25%",
                transform: "translate(0%, 0%) scale(0.63)",
            },
        },
        [theme.breakpoints.down("lg")]: {
            top: "-3px",
            left: "50%",
            transform: "translate(-50%, 0%) scale(0.63)",
        },
        [theme.breakpoints.down("md")]: {
            left: "0%",
            transform: "translate(30%, 50%) scale(1)",
            top: "0%",
        },
        [theme.breakpoints.down(580)]: {
            transform: "translate(20%, 50%) scale(1)",
        },
        [theme.breakpoints.down("sm")]: {
            width: 150,
            transform: "translate(-15%, 50%) scale(0.7)",
        },
        [theme.breakpoints.down(410)]: {
            width: 120,
            transform: "translate(-15%, 50%) scale(0.6)",
        },
        [theme.breakpoints.down(360)]: {
            transform: "translate(-15%, 50%) scale(0.6)",
        },
        [theme.breakpoints.down(340)]: {
            transform: "translate(-20%, 50%) scale(0.55)",
        },
    },

    gradientContainter: {
        position: "absolute",
        overflow: "hidden",
        top: 0,
        left: 0,
        right: 0,
        minHeight: "100vh",
        boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
        backgroundImage: `url(${bgProfile})`,
        backgroundSize: "cover",
        zIndex: -1,
    },
    center: {
        left: "50%",
        top: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
    },
    mainContainer: ({ isPwa }: IFullscreenProps) => ({
        height: "100%",
        // padding: "20px 70px",
        padding: "20px 20px 20px 70px",
        //position: "relative",
        overflow: "auto",
        background: "rgba(5, 5, 5, 1)",

        "&::-webkit-scrollbar": {
            display: "none",
        },
        "scrollbar-width": "none",
        "&.isGamePage": {
            zIndex: 5,
            padding: 0,
            // height: `calc(100dvh - ${windowsSizeParams.desktopAppBarHeight}px)`,
            //=====
            overflow: "hidden",
            height: `calc(100dvh - ${windowsSizeParams.desktopAppBarHeightNew}px)`,
        },
        "@media screen and (max-width: 1536px)": {
            padding: "20px 10px 20px 30px",
        },
        [theme.breakpoints.down("xl")]: {
            padding: "20px 30px",
        },
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            padding: "10px 15px",
        },
        [theme.breakpoints.down(MAX_L_TABLET)]: {
            padding: "8px 12px",
        },
        [theme.breakpoints.down("lg")]: {
            padding: "0",
            overflow: "hidden",
        },
        [theme.breakpoints.down("sm")]: {
            "&.isGamePage": {
                top: 48,
                position: "fixed",
                height: `calc(100dvh - ${windowsSizeParams.mobileAppBarHeight}px)`,
                width: "100%",
            },
        },
        [theme.breakpoints.down(350)]: {
            "&.isGamePage": {
                padding: 0,
                margin: 0,
            },
        },
        [theme.breakpoints.up(1350)]: {
            "&.isMultitablePage": {
                padding: "5px 20px",
            },
        },
    }),
    pagesInfoContainer: {
        height: "100%",
        width: "100%",
        maxWidth: "100vw",
        display: "flex",
        justifyContent: "center",
        // paddingTop: "2vw",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "scrollbar-width": "none",
        "&.isGameInfoContainer": {
            paddingTop: "0vw",
            height: "100%",
            "@media screen and (min-width: 1024px) and (min-height: 700px)": {
                // paddingTop: 35,
            },
        },

        [theme.breakpoints.up(1350)]: {
            "&.isMultitablePage": {
                height: "calc(100vh - 75px)",
            },
        },

        "&.isDepositContainer": {
            height: "100%",
        },
    },
    toastContainer: {
        "& .Toastify__toast": {
            background: "linear-gradient(19.92deg,#5c1b0d 13.3%,#ba6250 86.7%)",
        },
    },
});
