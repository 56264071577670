import React, { FC, useEffect, useState } from "react";
import cn from "classnames";
import chroma from "chroma-js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Box } from "@mui/material";

import { CircularProgress } from "./components/circularProgress";
import { useStyles } from "./styles";
import { EGameRematchStatus } from "src/store/models";

interface Props {
    seconds: number;
    onEnd: () => void;
    pause: boolean;
    reset: number;
    backgroundImage?: string;
    size?: number;
    gameType?: string;
    className?: string;
    modalType?: EGameRematchStatus;
}

export const CountdownCircular: FC<Props> = ({
    seconds,
    onEnd,
    pause,
    reset,
    backgroundImage,
    size,
    gameType,
    className,
    modalType,
}) => {
    const [progress, setProgress] = useState<number>(seconds * 10);
    const [intervalId, setIntervalId] = useState<any>();
    const palette = chroma.scale(["#FF1C29", "#bab652", "#00BA6C"]);
    const styles = useStyles();

    useEffect(() => {
        if (pause) {
            clearInterval(intervalId);
        } else {
            setIntervalId(
                setInterval(() => {
                    setProgress((progress) => progress - 1);
                }, 100)
            );
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [pause]);

    useEffect(() => {
        setProgress(seconds * 10);
    }, [seconds, reset]);

    useEffect(() => {
        if (progress <= 0) {
            if (intervalId) clearInterval(intervalId);
            onEnd();
        }
    }, [progress]);

    const dataBar = ((seconds * 10 - progress) / (seconds * 10)) * 100;

    return (
        <Box component="div" className={cn(styles.progressWrapper, className)}>
            <CircularProgressbar
                className={cn(styles.progressCircular, {
                    acceptOrReject:
                        modalType === EGameRematchStatus.WAITING_FOR_ANSWER,
                })}
                value={dataBar}
                text={`${Math.floor(progress / 10)}`}
                styles={{
                    // Customize the root svg element
                    path: {
                        stroke: palette(progress / 100) as unknown as string,
                    },
                }}
            />
            <p
                className={cn(styles.progressText, {
                    acceptOrReject:
                        modalType === EGameRematchStatus.WAITING_FOR_ANSWER,
                    waitForOpponentToDecideAlert:
                        modalType === EGameRematchStatus.WAITING_FOR_ANSWER,
                })}
                style={{
                    color: palette(progress / 100),
                }}
            >
                {Math.floor(progress / 10)}
            </p>
        </Box>
    );
};
