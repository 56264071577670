import loadable from "@loadable/component";
import { Box, useMediaQuery } from "@mui/material";
import Badge from "@mui/material/Badge";
import cn from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { isAndroid, isIOS, isSafari } from "react-device-detect";
import GifPlayer from "react-gif-player";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { AxiosResponse } from "axios";

import DiscordIcon from 'src/assets/icons/social/discordIcons.svg';
import TelegramIcon from 'src/assets/icons/social/telegramIcons.svg';
import YoutubeIcon from 'src/assets/icons/social/youtubeIcons.svg';
import InstagramIcon from 'src/assets/icons/social/instaIcons.svg';
import TiktokIcon from 'src/assets/icons/social/tiktokIcons.svg';
import { socialPaths } from "src/consts/paths";

import virtualSportIcon from "src/assets/icons/virtualSports.png";
import pockerSlot from "src/assets/images/pockerSlot.png";
import sportSlot from "src/assets/images/sportSlot.png";
import cardIcon from "src/assets/icons/cardsIcon.svg";
import diceChessIcon from "src/assets/icons/diceChessIcon.svg";
import rouletteIcon from "src/assets/icons/rouletteIcon.svg";
import slotsIcon from "src/assets/icons/slotsIcon.svg";
import sportIcon from "src/assets/icons/sportIcon.svg";
import { useLocale } from "src/providers/LocaleProvider";
import { GameTypes } from "src/store/models";
import blurOnHover from "src/pages/lobbyNew/assets/backgrounds/backgroundBlurWild.svg";
import youTubeIcon from "src/assets/icons/social/YoutubeLogo.svg";
import twitchIcon from "src/assets/icons/social/TwitchLogo.svg";
import { MainActionButton } from "src/components/buttons/newDesignButtons/mainActionButton";
import { Footer } from "src/components/wrapper/footer/index";
import AppBar from "src/components/controls/app-bar/index";
import { getGames } from "src/service/api/externalGames/index";
import chessVideo from "src/assets/chessVideo.mp4";
import leftArrowIcon from "src/assets/icons/ArrowLeft.svg";
import HalykAppBar from "src/components/controls/app-bar/components/halykAppBar/index";
import halykVideoKZ from "src/assets/Halyk/kazmp4.mp4";
import halykVideoRUS from "src/assets/Halyk/compres_halyk_2.mp4";
import {
    ExternalGameType,
    ILiveDealerGameResponse,
    ISlotsResponse,
} from "src/entities/externalGame";
import { IExternalGameResponse } from "src/entities/externalGame/model/types";

import paths from "../../consts/paths";
import LoginForm from "../../dialogs/login/LoginForm";
import useStores from "../../hooks/useStores";
import { ModalProvider, ModalTypes } from "../landing/utils/context";
import { RegisterForm } from "../register/RegisterForm";
import { fileHelper } from "../../shared/helpers/FileHelper";
import {
    ISportEventResponse,
    SportEventData,
} from "../../service/api/lobby/requestResponses";
import { getSportEvent } from "../../service/api/lobby";

import MobileVersion from "./components/Mobile/mobileVersion";
import { useStyles as useLobbyStyles, useStyles } from "./styles";
import { getStreamList } from "../../service/api/stream";
import moment from "moment";
import PageLoader from "../../components/PageLoader";
import { Spinner } from "../../components/spinner";

const BusinessModal = loadable(() => import("./components/BusinessModal"));
const Notifications = loadable(() => import("src/components/Notifications"));

interface StreamData {
    imageURL: string;
    name: string;
    online: boolean;
    youtube: string;
    twitch: string;
    streamingSchedule: {
        from: string;
        to: string;
    };
}

// TODO: refactor
const NewLobbyPage = () => {
    const isNotMobile = useMediaQuery("(min-width: 1025px)");
    const history = useHistory();
    const { authStore, generalStore, lobbyStore, generalLobbyStore } =
        useStores();
    const user = authStore.currentUser;

    const {
        mainLanding: {
            balance: { errorOnSaveState },
            poker,
            sport,
            slots,
            liveDealer,
            virtualSport,
            viewAll,
            comingSoon,
        },
    } = useLocale();

    const { language } = useLocale();
    const [video, setVideo] = useState(halykVideoRUS);

    useEffect(() => {
        switch (language) {
            case "ru":
                return setVideo(halykVideoRUS);
            case "kk":
                return setVideo(halykVideoKZ);

            default:
                return setVideo(halykVideoRUS);
        }
    }, [language]);

    const [isMobile, setIsMobile] = useState<boolean>(false);

    const checkIsMobile = () => {
        let regMobile =
            /iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
        regMobile || window.innerWidth <= 450
            ? setIsMobile(true)
            : setIsMobile(false);
    };

    useEffect(() => {
        checkIsMobile();
    }, [window.innerWidth]);

    useEffect(() => {
        if (authStore.isAuthorized && generalStore.isAggregatorMode) {
            history.push(paths.diceChessLobby);
        }
    }, [authStore.isAuthorized]);

    const [modalState, setModalState] = useState({
        register: false,
        login: false,
        businessMsg: false,
        notifications: false,
    });

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    const handlePokerNavigation = () => {
        if (authStore.isAuthorized) {
            history.push(paths.jackpoker);
        } else {
            openModal("login");
        }
    };

    const ratio = window.innerWidth / window.innerHeight;
    const styles = useStyles({ ratio });

    const [slotsItems, setSlots] = useState<ISlotsResponse[]>([]);

    const [liveDealers, setLiveDealers] = useState<IExternalGameResponse[]>([]);

    useEffect(() => {
        const fetchSlots = async () => {
            try {
                const response = await getGames({
                    limit: 24,
                    offset: 0,
                    type: ExternalGameType.SLOTS,
                });
                setSlots(response.data.games);
            } catch (error) {
                console.error("Error fetching slots:", error);
            }
        };

        const fetchLiveDealers = async () => {
            try {
                const response = await getGames({
                    limit: 24,
                    offset: 0,
                    type: ExternalGameType.LIVE_DEALER,
                });
                setLiveDealers(response.data.games);
            } catch (error) {
                console.error("Error fetching live dealers:", error);
            }
        };

        fetchSlots();
        fetchLiveDealers();
    }, []);

    const [sportEvent, setSportEvent] = useState<SportEventData | null>(null);


    const [streams, setStreams] = useState<StreamData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        const fetchSportEvent = async () => {
            try {
                const response: AxiosResponse<ISportEventResponse> = await getSportEvent();
                console.log("Ответ от API спорта:", response.data);

                const eventData = response?.data?.data?.data || null;
                if (eventData) {
                    setSportEvent(eventData);
                } else {
                    setSportEvent(null);
                }
            } catch (error) {
                console.error("Ошибка при загрузке спортивного события:", error);
                setSportEvent(null);
            }
        };

        fetchSportEvent();
    }, []);



    const isStreamLive = (stream: StreamData) => {
        // Если нет времени начала, то стрим оффлайн
        if (!stream.streamingSchedule || !stream.streamingSchedule.from) {
            return false; // Стрим оффлайн
        }

        const now = moment(); // Текущее время
        const from = moment(stream.streamingSchedule.from, 'HH:mm'); // Время начала стрима
        const to = stream.streamingSchedule.to ? moment(stream.streamingSchedule.to, 'HH:mm') : null; // Время окончания, если указано

        // Проверка корректности времени начала
        if (!from.isValid()) return false;

        // Случай 1: Стрим начался, но время окончания не указано
        if (!to) {
            // Стрим идет, если текущее время больше или равно времени начала
            return now.isSameOrAfter(from);
        }

        // Случай 2: Стрим начинается и заканчивается в течение одного дня
        if (from.isBefore(to)) {
            return now.isBetween(from, to, null, '[)');
        }

        // Случай 3: Стрим начинается поздно и продолжается после полуночи
        if (from.isAfter(to)) {
            return now.isAfter(from) || now.isBefore(to);
        }

        // Случай 4: Стрим идет круглосуточно (начало и конец совпадают)
        if (from.isSame(to)) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        const fetchStreams = async () => {
            try {
                const response = await getStreamList();
                console.log("Stream data:", response.data.data);

                const updatedStreams = response.data.data.data.map(stream => ({
                    ...stream,
                    online: isStreamLive(stream)
                }));

                setStreams(updatedStreams);
                setIsLoading(false);
            } catch (err) {
                console.error("Error fetching streams:", err);
                setError('Failed to load streams');
                setIsLoading(false);
            }
        };

        fetchStreams();

        const intervalId = setInterval(() => {
            console.log(`[${moment().format('HH:mm:ss')}] Updating stream statuses`);
            setStreams(prevStreams =>
                prevStreams.map(stream => ({
                    ...stream,
                    online: isStreamLive(stream)
                }))
            );
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);


    //для скролла стримов вправо-влево 
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (!scrollContainer) return;

        const handleMouseDown = (e: MouseEvent) => {
            setIsDragging(true);
            setStartX(e.pageX - scrollContainer.offsetLeft);
            setScrollLeft(scrollContainer.scrollLeft);
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        const handleMouseMove = (e: MouseEvent) => {
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - scrollContainer.offsetLeft;
            const walk = (x - startX) * 2; // Scroll-fast
            scrollContainer.scrollLeft = scrollLeft - walk;
        };

        scrollContainer.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            scrollContainer.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isDragging, startX, scrollLeft]);
    //

    const isHalyk = generalStore.isHalyk;

    // Изначально прелоадер отключен
    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
        if (location.pathname === "/lobby") {
            // Только на главной странице включаем прелоадер
            setShowOverlay(true);
            const timer = setTimeout(() => {
                setShowOverlay(false);
            }, 3000); // 3 секунды

            return () => clearTimeout(timer);
        } else {
            // На других страницах прелоадер не показываем
            setShowOverlay(false);
        }
    }, [location]);

    return (
        <>
            {showOverlay && (
                <div className={styles.overlay}>
                    <Spinner isAbsolute background='none' />
                </div>
            )}
            {!isNotMobile ? (
                <>
                    <MobileVersion />
                </>
            ) : (
                <ModalProvider.Provider value={{ openModal, closeModal }}>
                    {modalState.register && <RegisterForm />}
                    {modalState.login && <LoginForm />}
                    {modalState.businessMsg && <BusinessModal />}
                    {modalState.notifications && <Notifications />}

                    <Box component="div" className={styles.containerLobby}>
                        {isHalyk ? (
                            <HalykAppBar isGamePage={false} sideMenuIsOpen={false} />
                        ) : (
                            <AppBar isGamePage={false} sideMenuIsOpen={false} />
                        )}
                        <Box component="div" className={styles.main}>
                            {generalStore.isHalyk ? (
                                // Меню для Halyk
                                <Box
                                    component="div"
                                    className={styles.menuHalyk}
                                >
                                    <nav className={styles.menuLinks}>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                if (
                                                    isIOS ||
                                                    isSafari ||
                                                    !authStore.checkIfUserHasAccessToSection(
                                                        GameTypes.slots
                                                    )
                                                )
                                                    return;
                                                history.push(paths.algaltente);
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={slotsIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {slots("Slots")}
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                if (
                                                    !authStore.checkIfUserHasAccessToSection(
                                                        GameTypes.liveDealer
                                                    )
                                                )
                                                    return;
                                                history.push(paths.liveDealer);
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={rouletteIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {liveDealer("Live Casino")}
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                history.push(
                                                    paths.virtualSports
                                                );
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={virtualSportIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {virtualSport("Virtual Sport")}
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={`${styles.menuLink} ${styles.inactiveMenuLink}`}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={cardIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {poker("Poker")} (
                                                {comingSoon("soon")})
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                if (
                                                    !authStore.checkIfUserHasAccessToSection(
                                                        GameTypes.sports
                                                    )
                                                )
                                                    return;
                                                history.push(paths.sports);
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={sportIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {sport("Sport")}
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                    </nav>
                                </Box>
                            ) : (
                                // Меню для Beturanga
                                <Box component="div" className={styles.menu}>
                                    <nav className={styles.menuLinks}>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                history.push(
                                                    paths.diceChessLobby
                                                );
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={diceChessIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                Dicechess
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                if (
                                                    !authStore.checkIfUserHasAccessToSection(
                                                        GameTypes.jackpoker
                                                    )
                                                )
                                                    return;
                                                history.push(paths.jackpoker);
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={cardIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {poker("Poker")}
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                if (
                                                    !authStore.checkIfUserHasAccessToSection(
                                                        GameTypes.sports
                                                    )
                                                )
                                                    return;
                                                history.push(paths.sports);
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={sportIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {sport("Sport")}
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                if (
                                                    isIOS ||
                                                    isSafari ||
                                                    !authStore.checkIfUserHasAccessToSection(
                                                        GameTypes.slots
                                                    )
                                                )
                                                    return;
                                                history.push(paths.algaltente);
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={slotsIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {slots("Slots")}
                                            </p>{" "}
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                if (
                                                    !authStore.checkIfUserHasAccessToSection(
                                                        GameTypes.liveDealer
                                                    )
                                                )
                                                    return;
                                                history.push(paths.liveDealer);
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={rouletteIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {liveDealer("Live Casino")}
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                history.push(
                                                    paths.virtualSports
                                                );
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={virtualSportIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                {virtualSport("Virtual Sport")}
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                        <div className={styles.socialBlock}>
                                            <p className={styles.followUs}>
                                                Follow us
                                            </p>
                                            <div className={styles.socialIcons}>
                                                <a href={socialPaths.discord} target="_blank" rel="noopener noreferrer">
                                                    <img src={DiscordIcon} alt="Discord" className={styles.folowIcon} />
                                                </a>
                                                <a href={socialPaths.telegram} target="_blank" rel="noopener noreferrer">
                                                    <img src={TelegramIcon} alt="Telegram" className={styles.folowIcon} />
                                                </a>
                                                <a href={socialPaths.youtube} target="_blank" rel="noopener noreferrer">
                                                    <img src={YoutubeIcon} alt="Youtube" className={styles.folowIcon} />
                                                </a>
                                                <a href={socialPaths.instagram} target="_blank" rel="noopener noreferrer">
                                                    <img src={InstagramIcon} alt="Instagram" className={styles.folowIcon} />
                                                </a>
                                                <a href={socialPaths.tiktok} target="_blank" rel="noopener noreferrer">
                                                    <img src={TiktokIcon} alt="TikTok" className={styles.folowIcon} />
                                                </a>
                                            </div>
                                        </div>
                                    </nav>
                                </Box>
                            )}

                            <Box component="div" className={styles.content}>
                                <div className={styles.lobbyTitle}>
                                    {generalStore.isHalyk ? (
                                        <div
                                            className={
                                                styles.underHeroVideoHalyk
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.heroSectionHalyk
                                                }
                                            >
                                                <video
                                                    autoPlay
                                                    muted
                                                    loop
                                                    playsInline
                                                    className={styles.heroVideo}
                                                >
                                                    <source
                                                        src={video}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.heroSection}>
                                            <video
                                                autoPlay
                                                muted
                                                loop
                                                playsInline
                                                className={styles.heroVideo}
                                            >
                                                <source
                                                    src={chessVideo}
                                                    type="video/mp4"
                                                />
                                            </video>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text="I want Dicechess"
                                                className={styles.playButton}
                                                onClick={() => {
                                                    history.push(
                                                        paths.diceChessLobby
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}

                                        <div className={styles.underHeroContent}>
                                            <div className={styles.pokerSlots}>
                                                <img
                                                    src={pockerSlot}
                                                    alt="Poker Slots"
                                                    className={styles.pockerImage}
                                                />
                                                <MainActionButton
                                                    appearanceTheme="primary"
                                                    text={poker("Poker")}
                                                    className={styles.button}
                                                    onClick={() => {
                                                        history.push(paths.jackpoker);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.pokerSlots}>
                                                {sportEvent && sportEvent.participants && sportEvent.participants.length > 0 ? (
                                                    <div className={styles.sportEventDashboard}>
                                                        <div className={styles.eventHeader}>
                                                            <div className={styles.eventIcon}>
                                                                <img
                                                                    src={sportEvent.imageURL ? fileHelper.getMediaFileURL(sportEvent.imageURL) : ''}
                                                                    alt={sportEvent.title}
                                                                    className={styles.eventImage}
                                                                    onError={() => console.log("Ошибка загрузки изображения:", fileHelper.getMediaFileURL(sportEvent.imageURL))}
                                                                />
                                                            </div>
                                                            <span className={styles.eventName}>{sportEvent.title}</span>
                                                            <div className={styles.eventIcon} style={{ visibility: 'hidden' }}></div>
                                                        </div>
                                                        <div className={styles.participants}>
                                                            <div className={styles.participantContainer}>
                                                                <div className={styles.participant}>
                                                                    <div className={styles.participantAvatar}>
                                                                        <img
                                                                            src={sportEvent.participants[0].avatarURL ? fileHelper.getMediaFileURL(sportEvent.participants[0].avatarURL) : ''}
                                                                            alt={sportEvent.participants[0].name}
                                                                            className={styles.avatarImage}
                                                                            onError={() => console.log("Ошибка загрузки изображения:", fileHelper.getMediaFileURL(sportEvent.participants[0].avatarURL))}
                                                                        />
                                                                    </div>
                                                                    <span className={styles.participantPercentage}>{sportEvent.participants[0].betPercent}%</span>
                                                                    <span className={styles.participantName}>{sportEvent.participants[0].name}</span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.versusContainer}>
                                                                <div className={styles.versusLine}></div>
                                                                <span className={styles.versus}>VS</span>
                                                                <div className={styles.versusLine}></div>
                                                            </div>
                                                            <div className={styles.participantContainer}>
                                                                <div className={styles.participant}>
                                                                    <div className={styles.participantAvatar}>
                                                                        <img
                                                                            src={sportEvent.participants[1].avatarURL ? fileHelper.getMediaFileURL(sportEvent.participants[1].avatarURL) : ''}
                                                                            alt={sportEvent.participants[1].name}
                                                                            className={styles.avatarImage}
                                                                            onError={() => console.log("Ошибка загрузки изображения:", fileHelper.getMediaFileURL(sportEvent.participants[1].avatarURL))}
                                                                        />
                                                                    </div>
                                                                    <span className={styles.participantPercentage}>{sportEvent.participants[1].betPercent}%</span>
                                                                    <span className={styles.participantName}>{sportEvent.participants[1].name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.odds}>
                                                            {sportEvent.participants.map((participant, index) => (
                                                                <div key={index} className={styles.odd}>
                                                                    <span className={styles.oddNumber}>{index + 1}</span>
                                                                    <span className={styles.oddValue}>{participant.coefficient}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={sportSlot}
                                                        alt="Sport Slots"
                                                        className={styles.pockerImage}
                                                    />
                                                )}
                                                {/*<div className={styles.arrow}>*/}
                                                {/*    <img*/}
                                                {/*        src={leftArrowIcon}*/}
                                                {/*        alt="Left Arrow"*/}
                                                {/*        className={styles.leftArrow}*/}
                                                {/*    />*/}
                                                {/*    <img*/}
                                                {/*        src={leftArrowIcon}*/}
                                                {/*        alt="Right Arrow"*/}
                                                {/*        className={styles.rightArrow}*/}
                                                {/*    />*/}
                                                {/*</div>*/}
                                                <MainActionButton
                                                    appearanceTheme="primary"
                                                    text={sport("Sport")}
                                                    className={styles.button}
                                                    onClick={() => {
                                                        history.push(paths.sports);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    <div className={styles.slotsContainer}>
                                        <div className={styles.line}></div>
                                        <div className={styles.slotsHeader}>
                                            <h2 className={styles.slotsTitle}>
                                                {slots("Slots")}
                                            </h2>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text={viewAll("View all")}
                                                className={styles.viewAllButton}
                                                onClick={() => {
                                                    history.push(
                                                        paths.algaltente
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className={styles.slotsGrid}>
                                            {slotsItems.map((slot, index) => (
                                                <div
                                                    key={index}
                                                    onClick={async () => {
                                                        history.push(
                                                            `/slots/${slot.id}/play`
                                                        );
                                                    }}
                                                    className={styles.slotItem}
                                                >
                                                    <div
                                                        className={
                                                            styles.slotBody
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                fileHelper.getMediaFileURL(
                                                                    slot.image
                                                                ) || ""
                                                            }
                                                            className={
                                                                styles.slotImage
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className={styles.liveCasinoBlock}>
                                        <div className={styles.line}></div>
                                        <div className={styles.slotsHeader}>
                                            <h2 className={styles.slotsTitle}>
                                                {liveDealer("Live Casino")}
                                            </h2>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text={viewAll("View all")}
                                                className={styles.viewAllButton}
                                                onClick={() => {
                                                    history.push(
                                                        paths.liveDealer
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className={styles.slotsGrid}>
                                            {liveDealers.map(
                                                (dealer, index) => (
                                                    <div
                                                        key={index}
                                                        onClick={async () => {
                                                            history.push(
                                                                `/live-dealer/${dealer.id}/play`
                                                            );
                                                        }}
                                                        className={
                                                            styles.slotItem
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.slotBody
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    dealer.image
                                                                        ? fileHelper.getMediaFileURL(
                                                                              dealer.image
                                                                          )
                                                                        : ""
                                                                }
                                                                className={
                                                                    styles.slotImage
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>


                                    {generalStore.isHalyk && (
                                        <div className={styles.liveCasinoBlock}>
                                            <div className={styles.line}></div>
                                        </div>
                                    )}
                                   
                                    {!generalStore.isHalyk && (
                                        <div className={styles.streamChannelsBlock}>
                                            <div className={styles.line}></div>
                                            <div className={styles.streamHeader}>
                                                <h2 className={styles.streamTitle}>Our Stream Channels</h2>
                                            </div>
                                            <div
                                                ref={scrollContainerRef}
                                                className={styles.streamGridContainer}
                                                style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                                            >
                                                <div className={styles.streamGrid}>
                                                    {streams.map((stream, index) => (
                                                        <div key={index} className={styles.streamItem}>
                                                            <div className={styles.streamImageContainer}>
                                                                <img
                                                                    src={fileHelper.getMediaFileURL(stream.imageURL)}
                                                                    alt={stream.name}
                                                                    className={styles.streamImage}
                                                                />
                                                            </div>
                                                            <div className={styles.streamInfo}>
                                                                <p className={styles.streamName}>{stream.name}</p>
                                                                <p className={`${styles.streamStatus} ${stream.online ? styles.online : styles.offline}`}>
                                                                    {stream.online ? "Online" : "Offline"}
                                                                </p>
                                                                {/*<p className={styles.streamSchedule}>*/}
                                                                {/*    {stream.streamingSchedule.from} - {stream.streamingSchedule.to}*/}
                                                                {/*</p>*/}
                                                                <div className={styles.streamButtons}>
                                                                    {stream.youtube && (
                                                                        <a href={stream.youtube} target="_blank" rel="noopener noreferrer" className={styles.streamButton}>
                                                                            <img className={styles.streamIcon} src={youTubeIcon} alt="YouTube" />
                                                                        </a>
                                                                    )}
                                                                    {stream.twitch && (
                                                                        <a href={stream.twitch} target="_blank" rel="noopener noreferrer" className={styles.streamButton}>
                                                                            <img className={styles.streamIcon} src={twitchIcon} alt="Twitch" />
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.liveCasinoBlock}>
                                    <div className={styles.line}></div>
                                </div>

                                <div className={styles.menuFooter}>
                                    <Footer isMainPage={true} />
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </ModalProvider.Provider>
            )}
        </>
    );
};

export default observer(NewLobbyPage);
