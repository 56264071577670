import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import { Box } from "@mui/material";
import loadable from "@loadable/component";
import { observer } from "mobx-react";
import cn from "classnames";
import { Tab, Tabs } from "@mui/material";
import Badge from "@mui/material/Badge";

import { appColors, backgroundColors } from "src/consts/app-config";
import { MAX_XL_TABLET } from "src/pages/game-new/constants";

const ChatClockIcon = loadable(
    () => import("src/assets/icons/gameChatIcons/chatClockIcon")
);
const ChatMessageIcon = loadable(
    () => import("src/assets/icons/gameChatIcons/chatMessageIcon")
);
const FastGameIcon = loadable(
    () => import("src/assets/icons/gameChatIcons/fastGameIcon")
);

import chatNoActive from "src/assets/icons/gameChatIcons/redesignIcons/chatNoActive.svg";
import chatActive from "src/assets/icons/gameChatIcons/redesignIcons/chatActive.svg";
import watchersNoActive from "src/assets/icons/gameChatIcons/redesignIcons/watchersNoActive.svg";
import watchersActive from "src/assets/icons/gameChatIcons/redesignIcons/watchersActive.svg";
import history from "src/assets/icons/gameChatIcons/redesignIcons/history.svg";
import { EyeHidden } from "src/pages/game-new2/components/game-chat-history-new/icons/eye-hidden.icon";
import { Eye } from "src/pages/game-new2/components/game-chat-history-new/icons/eye.icon";
import { WatchingType } from "src/components/WatchingPlayers/components/types/watchingTypes";

import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import { EGameChatTab } from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";

import { useStyles } from "../styles";
import { IChatTabs } from "../types/IChat";

const ChatTabs = observer(
    ({
        handleTabChange,
        currentTab,
        showHideOpponent,
        isChatBlocked,
    }: IChatTabs) => {
        const appearanceTheme = useChangeTheme();

        const isTabletAndMobile = window.innerWidth <= MAX_XL_TABLET;

        const { gameStore, authStore, tournamentsStore } = useStores();
        const watchers = gameStore.guests;
        const me = authStore.currentUser?._id;
        const [uniqWatchers, setUniqWatchers] = useState<WatchingType[]>([]);
        const shouldHide = tournamentsStore.isHideChat;
        const chessboardSize = gameStore?.chessboardSize;
        const chatMessagesCount = gameStore?.newChatMessage?.length;

        const stickers = authStore.stickers;

        //useEffect(() => {
        //    if (Array.isArray(watchers) && watchers.length > 0) {
        //        const uniqWatchingObject = {
        //            //@ts-ignore
        //            ...[...new Set(watchers.map((guest) => guest._id))],
        //        };
        //        const uniqWatchingArray = watchers.filter(
        //            (guest, index) =>
        //                guest._id === uniqWatchingObject[index] &&
        //                guest._id !== me
        //        );
        //        setUniqWatchers(uniqWatchingArray);
        //    }
        //    if (Array.isArray(watchers) && watchers.length === 0) {
        //        setUniqWatchers([]);
        //    }
        //}, [watchers.length]);

        //const getLabelText = useMemo(() => {
        //    if (uniqWatchers.length > 99) {
        //        return "99+";
        //    }
        //    return uniqWatchers.length;
        //}, [uniqWatchers]);

        const classes = useStyles({
            shouldHide,
            showHideOpponent,
            appearanceTheme,
            chessboardSize,
            stickers,
        });

        return (
            <Tabs
                onChange={handleTabChange}
                value={currentTab}
                className={cn(classes.chatElementsWrapper, appearanceTheme)}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0)",
                    },
                }}
            >
                <Tab
                    key={EGameChatTab.chat}
                    aria-label={EGameChatTab.chat}
                    value={EGameChatTab.chat}
                    label={
                        <Badge
                            className={cn(classes.badgeNotifications, {
                                emptyNote:
                                    currentTab === EGameChatTab.chat ||
                                    chatMessagesCount === 0,

                                moreThan99: chatMessagesCount > 99,
                            })}
                            badgeContent={chatMessagesCount}
                            color="primary"
                        >
                            <img
                                src={
                                    currentTab === EGameChatTab.chat
                                        ? chatActive
                                        : chatNoActive
                                }
                                alt="chat"
                            />
                        </Badge>
                    }
                />
                {/* {!isTabletAndMobile && (
                    <Tab
                        icon={
                            <FastGameIcon
                                width={32}
                                height={32}
                                fillColor={appColors.white}
                            />
                        }
                        key={EGameChatTab.fastGame}
                        value={EGameChatTab.fastGame}
                        aria-label={EGameChatTab.fastGame}
                    />
                )} */}
                {/* 
                Turn off temporary
                <Tab
                    icon={<img src={history} alt="history" />}
                    key={EGameChatTab.history}
                    value={EGameChatTab.history}
                    aria-label={EGameChatTab.history}
                /> */}
                <Tab
                    icon={<img src={history} alt="history" />}
                    key={EGameChatTab.history}
                    value={EGameChatTab.history}
                    aria-label={EGameChatTab.history}
                />
                {/*<Tab*/}
                {/*    className={cn(classes.watchersTab, appearanceTheme, {})}*/}
                {/*    icon={*/}
                {/*        <img*/}
                {/*            src={*/}
                {/*                currentTab === EGameChatTab.watchers*/}
                {/*                    ? watchersActive*/}
                {/*                    : watchersNoActive*/}
                {/*            }*/}
                {/*            alt="history"*/}
                {/*        />*/}
                {/*    }*/}
                {/*    key={EGameChatTab.watchers}*/}
                {/*    value={EGameChatTab.watchers}*/}
                {/*    aria-label={EGameChatTab.watchers}*/}
                {/*    iconPosition="start"*/}
                {/*    label={`(${getLabelText})`}*/}
                {/*    sx={{*/}
                {/*        fontFamily: " Montserrat",*/}
                {/*        fontWeight: "700",*/}
                {/*        fontSize: uniqWatchers.length > 9 ? "10px" : "16px",*/}
                {/*    }}*/}
                {/*/>*/}
                <Box
                    component="div"
                    className={cn(classes.chatElementEye, appearanceTheme, {
                        shouldHide: shouldHide,
                    })}
                >
                    <Badge
                        className={cn(classes.badgeNotifications, "badgeEye", {
                            emptyNote:
                                chatMessagesCount === 0 ||
                                isChatBlocked ||
                                !shouldHide,
                            moreThan99: chatMessagesCount > 99,
                        })}
                        badgeContent={false}
                        color="primary"
                        onClick={() =>
                            tournamentsStore.regularGameChatToggler(!shouldHide)
                        }
                    >
                        {shouldHide ? <EyeHidden /> : <Eye />}
                    </Badge>
                </Box>
            </Tabs>
        );
    }
);

export default memo(ChatTabs);
