import "react-notifications/lib/notifications.css";

import loadable from "@loadable/component";
import { Box, Container } from "@mui/material";
import { configureScope } from "@sentry/react";
import axios from "axios";
import cn from "classnames";
import { observer } from "mobx-react";
import qs from "qs";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { NotificationContainer } from "react-notifications";
import {
    useHistory,
    useLocation,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { usePageVisibility } from "react-page-visibility";
import * as Sentry from "@sentry/react";

import { MAX_XL_TABLET } from "src/pages/game-new/constants";
import useChangeTheme from "src/hooks/useChangeTheme";
import { EColor } from "src/components/types";
import { appColors } from "src/consts/app-config";
import paths from "src/consts/paths";
import { windowsSizeParams } from "src/consts/window-size-params";
import useStores, { rootStore } from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";
import InternetSpeedMeter from "src/pages/internet-speed";
import { getLastVersion } from "src/service/api/versions";
import { GameMode, GameType, UserBalanceType } from "src/store/models";
import theme from "src/theme";
import runOneSignal from "src/utils/one-signal";
import TopPlayerMobile from "src/components/TopPlayers/topPlayerMobile";
import { CreateGameModal } from "src/features/createGame";
import { emitter } from "src/shared/emitter";
import { GameStoreProvider } from "src/hooks/useGameStore";
import { BooleanHelper } from "src/shared/helpers/BooleanHelper";
import { createLocale } from "src/service/api/locales";
import TopPlayers from "src/pages/lobby/components/TopPlayers/TopPlayers";

import endpoints from "../../core/endpoints";
import { doGet } from "../../core/rest";
import { useSocket } from "../../core/useSocket";
import { LocaleProvider } from "../../providers/LocaleProvider";
import AlertService from "../../service/alert-service";
import LobbySocket from "../../socket/lobby-socket";
import { LobbySpinner } from "../LobbySpinner";
import AppBar from "../controls/app-bar";
import FullScreenToggler from "../controls/app-bar/components/fullscreenToggler";
import Logo from "../controls/app-bar/components/logo/Logo";

import GameEventDialogsNew from "./components/gameEventsDialogWrapperNew/GameEventsDialogNew";
import { useStyles } from "./styles";
import { Footer } from "./footer";

const SideMenu = loadable(() => import("../SideMenu"));
const GameTournamentDialog = loadable(
    () => import("./components/GameTournamentDialog")
);

const OpponentSettingsFade = loadable(
    () => import("src/pages/lobby/components/OpponentSettingsFade")
);
const NewNotificationContainer = loadable(
    () => import("../NotificationContainer")
);

const EventEmitter = loadable(() => import("../EventEmitter"));
const FullScreenView = loadable(() => import("../FullScreenView"));
const UserSettingsContainer = loadable(() => import("src/pages/userSettings"));
const SideBar = loadable(() => import("../SideBar"));

axios.interceptors.request.use((config) => {
    const token = rootStore.authStore.getAuthToken();
    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

const noAuthPath = [
    "/login",
    "/register",
    "/auth-phone",
    "/recovery-password",
    "/reset-password",
    "/policy",
    "/privacy-policy",
    "/about-us",
    "/responsible-gaming",
    "/term-and-conditions",
    "/withdrawal-policy-and-refund-policy",
];

const pagesWithoutAppBar = ["/landing", paths.lobby, paths.login];
const { mobileAppBarHeight, largeTabletAppBarHeight, desktopAppBarHeight } =
    windowsSizeParams;

const Wrapper = ({ children }) => {
    const {
        authStore,
        generalStore,
        lobbyStore,
        friendsStore,
        tournamentsStore,
        achievementsStatStore,
    } = useStores();
    const [data, setData] = useState({});
    const currentLocation = useLocation();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [firstLoading, setLoaded] = useState<boolean>(false);
    const [dataThrottling, setDataThrottling] = useState(0);
    const [sentLocale, setSentLocales] = useState<string[]>([]);
    const [reloadStatus, setReloadStatus] = useState<string>(EColor.reload);
    const { search } = useLocation();
    const { id } = useParams<{ id: string }>();

    const { width, height } = useWindowSize();
    const isGamePage = useRouteMatch("/game/:id");
    const isLobbyFreePlayPage = useRouteMatch(paths.lobbyFreePlay);
    const isMultitablePage = location.pathname === paths.games;
    const [isPwa, setPwaStatus] = useState<boolean>(false);
    const appearanceTheme = useChangeTheme();

    const isAppBarHidden = pagesWithoutAppBar.includes(location.pathname);
    const isLobbyPage = currentLocation.pathname === paths.lobby;
    const isTournamentsPage = currentLocation.pathname === paths.tournaments;

    /* // TODO: turn off the sound when the game is on, etc.
    const { setIsPlaying, isPlaying } = useThemeSound({
        playing: !!(
            authStore.isAuthorized &&
            authStore.currentUser &&
            authStore.currentUser.settings.sound
        ),
        loop: true,
    });*/

    const [initSocket, socket] = useSocket({
        token: authStore.getAuthToken(),
    });
    useEffect(() => {
        initSocket(endpoints.localeSocket);
        generalStore.getJackpot();
        generalStore.getCountries();

        const isStandaloneAndroid = "(display-mode: standalone)";
        const isPwaStatus = window.matchMedia(isStandaloneAndroid).matches;
        setPwaStatus(isPwaStatus);

        if (isPwaStatus) {
            updateVersion();
        }
        const isHalyk = getHalykStatus();
        generalStore.setIsHalyk(isHalyk);

        runOneSignal();
        generalStore.getBanners();

        setTimeout(() => {
            lobbyStore.setShowLobbyLoader(false);
        }, 2500);

        window.onerror = async (message, file, line, col, error) => {
            emitter.emit("logger:debug", "error", {
                message: message as string,
                file,
                line,
                col,
                error,
            });

            return false;
        };
        window.addEventListener?.("error", (error) => {
            console.log("error", error);
            Sentry.captureException(error);

            return false;
        });

        const calcTime = () => {
            if (firstLoading) return;
            setLoaded(true);
        };
        const loaderTimeOut = setTimeout(calcTime, 4000);
        window.addEventListener("load", function () {
            if (firstLoading) return;
            setLoaded(true);
            clearTimeout(loaderTimeOut);
        });
    }, []);

    useEffect(() => {
        if (authStore.isAuthorized) {
            generalStore.getPaymentMethods();
            authStore.getMyNotifications();
            achievementsStatStore.myOpponentsSettings();
            achievementsStatStore.meOpponentsSettings();
        } else {
            const referralId = getRefId();
            authStore.setReferralId(referralId);
        }
    }, [authStore.isAuthorized]);

    const getHalykStatus = () => {
        try {
            const envIsHalyk = +(process.env.REACT_APP_IS_HALYK || "") === 1;
            if (envIsHalyk !== undefined) {
                localStorage.setItem("isHalyk", envIsHalyk ? "1" : "0");
                return envIsHalyk;
            }
            const storedIsHalyk = localStorage.getItem("isHalyk");
            return storedIsHalyk === "1";
        } catch (e) {
            return false;
        }
    };

    const getRefId = () => {
        try {
            const { ref } = qs.parse(search.substring(1));
            if (ref) {
                localStorage.setItem("refId", ref as string);
                return ref as string;
            } else {
                return localStorage.getItem("refId");
            }
        } catch (e) {}

        return null;
    };

    useEffect(() => {
        if (isLobbyFreePlayPage) {
            lobbyStore.setLobbyMode(UserBalanceType.coins);
        } else {
            lobbyStore.setLobbyMode(UserBalanceType.play);
        }
    }, [isLobbyFreePlayPage]);

    useEffect(() => {
        const url = window.location.pathname;
        if (url.includes(paths.algaltente)) {
            generalStore.setGameType(GameType.slots);
        } else if (url.includes(paths.liveDealer)) {
            generalStore.setGameType(GameType.liveDealer);
        } else if (url.includes(paths.jackpoker)) {
            generalStore.setGameType(GameType.jackpoker);
        } else if (url.includes(paths.sports)) {
            generalStore.setGameType(GameType.sports);
        } else {
            generalStore.setGameType(GameType.dicechess);
        }
    }, [window.location.pathname]);

    const styles = useStyles({ isPwa });
    const history = useHistory();

    const isVisible = usePageVisibility();
    useEffect(() => authStore.setVisibleWebAppStatus(isVisible), [isVisible]);

    const takeLocales = async () => {
        doGet({
            url: endpoints.locale,
        }).then(({ error, result }) => {
            if (error) {
                console.warn(error);
            } else {
                const localesMap = {};

                for (const locale of result.locales) {
                    localesMap[locale.language] = locale.data;
                }
                setData(localesMap);
                setDataLoaded(true);
            }
        });
    };

    const updateVersion = async () => {
        try {
            const { data } = await getLastVersion();
            const lastVersion = localStorage.getItem("version");
            if (lastVersion == null) {
                localStorage.setItem("version", data?.version);
            } else if (lastVersion !== data?.version) {
                localStorage.setItem("version", data?.version);
                window.location.reload();
            }
        } catch (e) {}
    };

    useEffect(() => {
        location.pathname !== paths.logout && authStore.getMe();
        generalStore.getVariables();
        if (!isTournamentsPage && tournamentsStore.tournamentModalInfo) {
            tournamentsStore.joinTournamnetHandlerModal(null);
            tournamentsStore.setTournamnetDataToModal(null);
        }
    }, [location.pathname]);

    useLayoutEffect(() => {
        authStore.getMeNew();
    }, []);

    useEffect(() => {
        const isMultiTableAvailable =
            (width ?? window.innerWidth) >= 1350 &&
            (height ?? window.innerHeight) >= 800;
        generalStore.setIsMultiTableAvailable(isMultiTableAvailable);
    }, [width, height]);

    useEffect(() => {
        if (!socket) return;
        socket.on("locale:update", () => setDataThrottling(50));
    }, [socket]);

    useEffect(() => {
        if (dataThrottling) {
            setTimeout(
                () => setDataThrottling((x) => (x > 0 ? x - 1 : 0)),
                100
            );
        } else {
            takeLocales();
        }
    }, [dataThrottling]);

    useEffect(() => {
        if (authStore.isLoading) {
            return;
        }
        const param = qs.parse(search.substring(1)) as {
            mode?: GameMode.AGGREGATOR;
            token?: string;
        };
        if (
            generalStore.mode !== GameMode.AGGREGATOR &&
            param?.mode === GameMode.AGGREGATOR
        ) {
            generalStore.setMode(GameMode.AGGREGATOR);
            return;
        }

        if (
            !authStore.isAuthorized &&
            !noAuthPath.find((p) => location.pathname.startsWith(p))
        ) {
            if (location.pathname === paths.main) {
                history.push(paths.login);
            }
        }
        if (
            authStore.isAuthorized &&
            ["/login", "/register", "/auth-phone", "/"].indexOf(
                location.pathname
            ) >= 0
        ) {
            if (generalStore.isDicechessMode) {
                history.push(paths.lobby);
            } else {
                history.push(paths.diceChessLobby);
            }
        }
    }, [
        authStore.isAuthorized,
        authStore.isLoading,
        search,
        authStore.currentUser?._id,
    ]);

    useEffect(() => {
        if (authStore.isAuthorized) {
            // if (generalStore.isDicechessMode) OneSignal.showSlidedownPrompt();

            friendsStore.getFriends();
            configureScope((scope) => {
                scope.setUser({
                    _id: authStore.currentUser?._id,
                    email: authStore.currentUser?.email,
                    nickname: authStore.currentUser?.nickname,
                });
            });
        }
    }, [authStore.isAuthorized]);

    const userLanguage = authStore.getLanguage();

    const onLocaleUnknown = useCallback(
        async (path, value) => {
            if (!dataLoaded) return;
            // await createLocale(path, value);
        },
        [dataLoaded, sentLocale, socket]
    );

    const setRedirect = (startTime: string) => {
        if (!startTime) return;
        const now = Date.now();
        const redirectTime = 3000;
        if (now - Date.parse(startTime) <= redirectTime) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (lobbyStore.allLobbyParticipatingGames) {
            const curGame = lobbyStore.allLobbyParticipatingGames.find((game) =>
                setRedirect(game.startedAt)
            );
            if (curGame && curGame.startedAt) {
                history.push(`/game/${curGame._id}`);
            }
        }
        // if (lobbyStore.connectionGameId) {
        //     const curGame = lobbyStore.allLobbyParticipatingGames.filter(
        //         (game) => game. === lobbyStore.connectionGameId
        //     );
        //     if (curGame && curGame.startedAt) {
        //         setRedirect(curGame.startedAt) &&
        //             history.push(`/game/${lobbyStore.connectionGameId}`);
        //     }
        // }
    }, [lobbyStore.allLobbyParticipatingGames.length]);

    return (
        <LocaleProvider
            locale={userLanguage}
            data={data}
            onUnknown={onLocaleUnknown}
        >
            <LobbySocket />

            <LobbySpinner
                isFullscreen
                isStart={authStore.isLoading || !firstLoading}
                // isFinish={!authStore.isLoading && firstLoading}
                isFinish={!authStore.isLoading || firstLoading}
            />
            <div className={cn(styles.fullscreen, appearanceTheme)}>
                <InternetSpeedMeter />
                <FullScreenToggler
                    width={30}
                    height={30}
                    fillColor={appColors.white}
                />
                <div style={{ position: "absolute" }}>
                    <ToastContainer
                        autoClose={false}
                        draggable={false}
                        className={styles.toastContainer}
                    />
                </div>
                {!isAppBarHidden && (
                    <>
                        <AppBar
                            isGamePage={!!isGamePage}
                            sideMenuIsOpen={generalStore.isSideMenuOpen}
                        />
                    </>
                )}
                {/*isPoker && (
                    <Box
                        component="div"
                        className={cn(styles.pokerLogoWrapper, appearanceTheme)}
                    >
                        <Logo />
                    </Box>
                )}*/}

                <Container
                    className={cn(styles.mainContainer, {
                        isGamePage: !!isGamePage,
                        isMultitablePage: isMultitablePage,
                    })}
                    maxWidth={false}
                >
                    {width && width < MAX_XL_TABLET + 1 ? (
                        <SideMenu
                            sideMenuIsOpen={generalStore.isSideMenuOpen}
                            setReloadStatus={setReloadStatus}
                            reloadStatus={reloadStatus}
                            isGamePage={isGamePage}
                        />
                    ) : null}
                    {/* {width && width < MAX_XL_TABLET + 1 && (
                        <TopPlayerMobile
                            paginationLimit={20}
                            maxCountOfPaganation={4}
                            topCountTitle={100}
                        />
                    )} */}
                    {width && width < MAX_XL_TABLET + 1 && (
                        <TopPlayers componentPlace="isMobile" />
                    )}

                    <div
                        className={cn(styles.pagesInfoContainer, {
                            isGameInfoContainer: isGamePage,
                            isMultitablePage: isMultitablePage,
                            isDepositContainer:
                                document.location.href.indexOf("/cashbox") >= 0,
                        })}
                    >
                        {children}
                    </div>
                    {/* {isNotMobile && !(isTablet && !!isGamePage) && false && <Footer />} */}
                </Container>
                <UserSettingsContainer />
                <GameEventDialogsNew />
                <GameTournamentDialog />
                <AlertService />
                <NewNotificationContainer />
                <FullScreenView />
                <EventEmitter />
                <OpponentSettingsFade />
                <CreateGameModal />
                {window.innerWidth >= MAX_XL_TABLET + 1 &&
                    !isLobbyPage &&
                    generalStore.isDicechessMode && <SideBar />}
                {/* {window.innerWidth > MAX_XL_TABLET && isLobbyAndLoginPage && (
                    <Footer />
                )}*/}
            </div>
            <NotificationContainer />
        </LocaleProvider>
    );
};
export default observer(Wrapper);
