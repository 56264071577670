import React, { useEffect, useState } from "react";

import "../../styles.css";
import { observer } from "mobx-react";
import { useMediaQuery } from "@mui/material";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import { errorService } from "src/service/services";
import FadeInOutVertical from "src/components/Animated";
import lastMoveIsUnAvailable from "src/utils/lastMoveIsUnAvailable";
import FenParser from "src/store/gameStore/utils/parser";
import FenHelper from "src/store/gameStore/utils/helper";
import { GameModeType, UserBalanceType } from "src/store/models";
import { usePlayer } from "src/sounds/use-player";
import throttle from "src/utils/throttle";

import giveUpIcon from "../../icons/flag-button.svg";
import handsIcon from "../../icons/hands-button.svg";
import closeIcon from "../../icons/closeIcon.svg";
import useStores from "../../../../hooks/useStores";
import { ButtonState } from "../../../../store/gameStore/dialogs";
import { MIN_L_TABLET } from "../../constants";

import { X2Button } from "./x2Button";
import { useStyles } from "./styles";
import DiceButtonWithSpinner from "./DiceButtonWithSpinner/DiceButtonWithSpinner";

interface IX2AndDice {
    isPlayerInfo?: boolean | undefined;
    isTournamentGame?: boolean | undefined;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    x2ForTabletandMobile?: boolean;
    isOpponent?: boolean;
}

const X2AndDice = ({
    isPlayerInfo,
    isTournamentGame,
    isMultiTable,
    x2ForTabletandMobile,
    isOpponent,
}: IX2AndDice) => {
    const { gameStore, authStore } = useStores();
    const userBet = gameStore.gameSettings?.bet;
    const isDoublingEnabled = gameStore.gameSettings?.doubling;
    const gameType = gameStore.currentGame?.type;
    const isDoubling = gameStore.currentGame?.settings.doubling;
    const isMyTurn = gameStore.gameState.isMyTurn();
    const isViewMode = gameStore.isViewMode();
    const activeClock = gameStore.gameState.activeClock;
    const myColor = gameStore.gameState.getMyColor();
    const showX2Button = gameStore.dialogs.getX2ButtonState();
    const showX2Bet = gameStore.dialogs.doublingDialog.isOpen;
    const toRoll = gameStore.gameState.toRoll();
    const isLastMoveAreUnAvailable = lastMoveIsUnAvailable(
        !gameStore.gameState.legalPieces.length,
        gameStore.gameState.history,
        gameStore.gameState.getMyColor()
    );
    const balanceType = gameStore.currentGame?.balanceType;
    const balance =
        balanceType === UserBalanceType.play
            ? authStore.balance
            : authStore.coinsBalance;

    const changeScreenStyles = useMediaQuery(`(max-width: 1023px)`);

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [x2Disable, setX2Disable] = useState<boolean>(false);
    const [diceDisable, setDiceDisable] = useState<boolean>(false);
    const [isSudoX2Disable, setSudoX2Disable] = useState<boolean>(false);
    const parse = new FenParser(FenHelper);
    const lastRolledPlayer = parse.getWhoLastRollsColor(
        gameStore.gameState.history
    );
    const player = usePlayer();

    const styles = useStyles();

    const {
        game: {
            doubling: { youHaveNotEnoughMoney },
            header: {
                actionButtons: { giveUp, draw },
            },
        },
    } = useLocale();

    const showSpinner = activeClock?.running && activeClock.color !== myColor;
    const x2Duration = 700;

    useEffect(() => {
        if (!toRoll && diceDisable) {
            setDiceDisable(false);
        }
    }, [toRoll, diceDisable]);

    useEffect(() => {
        if (!isMyTurn) {
            setSudoX2Disable(false);
        }
        if (isMyTurn && gameStore.gameState.hasSpecialActivity()) {
            setSudoX2Disable(false);
        }
    }, [isMyTurn]);

    /*if (gameStore.dialogs.x2WarningDialog.isOpen) {
        return (
            <X2WarningDialog
                ratio={1}
                onApprove={() => {
                    gameStore.dialogs.sendDouble();
                    gameStore.dialogs.closeX2Warning();
                }}
                onCancel={() => {
                    gameStore.dialogs.closeX2Warning();
                }}
            />
        );
    }*/

    const lastMyMoveIsUnAvailable =
        lastRolledPlayer === myColor && isLastMoveAreUnAvailable;

    const letsAutoRoll = (): void => {
        gameStore.roll();
        setDiceDisable(true);
    };

    //Autoroll logical
    // useEffect(() => {
    //     if (
    //         toRoll &&
    //         !showX2Bet &&
    //         !lastMyMoveIsUnAvailable &&
    //         !isDoubling &&
    //         gameType !== GameModeType.REGULAR
    //     ) {
    //         if (!diceDisable) {
    //             const throttled = throttle(letsAutoRoll, 500);
    //             throttled();
    //         }
    //         setSudoX2Disable(true);
    //     }
    // }, [isDoubling, showX2Bet, lastMyMoveIsUnAvailable, toRoll, diceDisable]);

    if (!userBet && gameType === GameModeType.REGULAR) return null;

    const handleToggleShowMenu = () => setShowMenu((prev) => !prev);

    const handleGiveUp = () => {
        gameStore.dialogs.confirmGiveUp();
    };

    const x2ButtonHide = !(toRoll && showX2Button === ButtonState.active);

    // Hide this component if there is ViewMode
    if (isOpponent) return null;

    if (showMenu && changeScreenStyles) {
        return (
            <FadeInOutVertical key="menu-animation">
                <div className={styles.menuWrapper}>
                    <div
                        className={styles.circleButton}
                        onClick={() => {
                            // iOSDetect() && player('drawOfferSoundPlay');
                            // !iOSDetect() && player('drawOfferSoundPlay');
                            gameStore.setShowDrawDialog();
                        }}
                    >
                        <img
                            src={handsIcon}
                            className={cn(styles.button, styles.circleImage)}
                        />
                        <div className={styles.textButton}>{draw("Draw")}</div>
                    </div>

                    <div
                        className={styles.circleButton}
                        onClick={() => {
                            handleGiveUp();
                        }}
                    >
                        <img
                            src={giveUpIcon}
                            className={cn(styles.button, styles.circleImage)}
                        />
                        <div className={styles.textButton}>
                            {giveUp("Resign")}
                        </div>
                    </div>
                    <div
                        className={styles.circleButton}
                        onClick={handleToggleShowMenu}
                    >
                        <div className={cn(styles.button, styles.backButton)}>
                            <img src={closeIcon} className={styles.closeIcon} />
                        </div>
                        <div className={styles.textButton}>Back</div>
                    </div>
                </div>
            </FadeInOutVertical>
        );
    }

    if (x2ForTabletandMobile && changeScreenStyles) {
        return (
            <>
                {!gameStore.gameState.hasSpecialActivity() && (
                    <FadeInOutVertical
                        key="x2button-animation"
                        delaynumber={x2Duration ? 750 : 350}
                        isPlayerInfo={isPlayerInfo}
                        className={cn(styles.wrapperX2AndDiceAnimation, {
                            x2ForTabletandMobile: x2ForTabletandMobile,
                        })}
                    >
                        <>
                            {(!x2ButtonHide || !isSudoX2Disable) &&
                                isDoublingEnabled &&
                                !isTournamentGame && (
                                    <X2Button
                                        hide={isSudoX2Disable || x2ButtonHide}
                                        xFactor={2}
                                        onClick={() => {
                                            if (isSudoX2Disable || x2ButtonHide)
                                                return;
                                            if (userBet && userBet > balance) {
                                                errorService.sendError(
                                                    youHaveNotEnoughMoney(
                                                        "У вас не хватает денег для удвоения ставки!"
                                                    )
                                                );
                                                return;
                                            }
                                            //DONT DELETE! ITs logical if we have little cash and going to make
                                            // if (balance - userBet < userBet * 2) {
                                            //     gameStore.dialogs.showX2Warning();
                                            // }
                                            else if (!x2Disable) {
                                                gameStore.dialogs.openThinkingDialog();
                                                gameStore.dialogs.sendDouble();
                                                setX2Disable(true);
                                            }
                                        }}
                                    />
                                )}
                        </>
                    </FadeInOutVertical>
                )}
            </>
        );
    }

    return (
        <>
            {!gameStore.gameState.hasSpecialActivity() && (
                <FadeInOutVertical
                    key="x2button-animation"
                    delaynumber={x2Duration ? 750 : 350}
                    isPlayerInfo={isPlayerInfo}
                    className={styles.wrapperX2AndDiceAnimation}
                >
                    <div className={cn(styles.wrapperX2AndDice)}>
                        {!changeScreenStyles && (
                            <div
                                className={cn(styles.buttonHolder, "x2", {
                                    x2ButtonMobile: changeScreenStyles,
                                })}
                            >
                                {(!x2ButtonHide || !isSudoX2Disable) &&
                                    !isTournamentGame &&
                                    isDoublingEnabled && (
                                        <X2Button
                                            hide={
                                                isSudoX2Disable || x2ButtonHide
                                            }
                                            xFactor={2}
                                            onClick={() => {
                                                if (
                                                    isSudoX2Disable ||
                                                    x2ButtonHide
                                                )
                                                    return;
                                                if (
                                                    userBet &&
                                                    userBet > balance
                                                ) {
                                                    errorService.sendError(
                                                        youHaveNotEnoughMoney(
                                                            "У вас не хватает денег для удвоения ставки!"
                                                        )
                                                    );
                                                    return;
                                                }
                                                //DONT DELETE! ITs logical if we have little cash and going to make
                                                // if (balance - userBet < userBet * 2) {
                                                //     gameStore.dialogs.showX2Warning();
                                                // }
                                                else if (!x2Disable) {
                                                    gameStore.dialogs.openThinkingDialog();
                                                    gameStore.dialogs.sendDouble();
                                                    setX2Disable(true);
                                                }
                                            }}
                                            isMultiTable={isMultiTable}
                                        />
                                    )}
                            </div>
                        )}
                        <div
                            className={cn(
                                styles.buttonHolder,
                                styles.diceButtonHolder
                                // {
                                //     hideOnTournament:
                                //         gameType === GameModeType.TOURNAMENT &&
                                //         toRoll,
                                // }
                            )}
                        >
                            {!isViewMode && (
                                <DiceButtonWithSpinner
                                    showSpinner={showSpinner}
                                    enabled={
                                        toRoll &&
                                        !showX2Bet &&
                                        !lastMyMoveIsUnAvailable
                                    }
                                    onClick={() => {
                                        if (!toRoll || showX2Bet) return;
                                        //toRoll && iOSDetect() && player('tripleDice');
                                        if (toRoll && !diceDisable) {
                                            gameStore.roll();
                                            setDiceDisable(true);
                                        }
                                        setSudoX2Disable(true);
                                    }}
                                    isMultiTable={isMultiTable}
                                />
                            )}
                        </div>
                    </div>
                </FadeInOutVertical>
            )}
        </>
    );
};

export default observer(X2AndDice);
