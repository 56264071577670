import axios from "axios";

import endpoints from "src/core/endpoints";
import { IFriend } from "src/store/models";

import {
    IAcceptFriendRequest,
    ICancelFriendRequest,
    IFriendActionRequest,
    IFriendDeleteRequest,
    IRejectFriendRequest,
    IFriendAdd,
    IFriendRemove,
    IFriendActionResponse,
} from "./requestResponses";

//export const getFriends = async () => {
//    return await axios.get<IFriend[]>(endpoints.friends);
//};

//export const addFriend = async (params: IFriendActionRequest) => {
//    return await axios.post(endpoints.friends, params);
//};

//export const removeFriend = async (params: IFriendDeleteRequest) => {
//    return await axios.post(endpoints.deleteFriend, params);
//};

//export const acceptFriendRequest = async (params: IAcceptFriendRequest) => {
//    return await axios.post(endpoints.acceptFriendRequest, params);
//};

//export const rejectFriendRequest = async (params: IRejectFriendRequest) => {
//    return await axios.post(endpoints.rejectFriendRequest, params);
//};

//export const cancelFriendRequest = async (params: ICancelFriendRequest) => {
//    return await axios.post(endpoints.cancelFriendRequest, params);
//};

////=======

//export const addFriendNew = async (params: IFriendAdd) => {
//    return await axios.post<IFriendActionResponse>(endpoints.addFriend, params);
//};

//export const removeFriendNew = async (params: IFriendRemove) => {
//    return await axios.post<IFriendActionResponse>(
//        endpoints.removeFriend,
//        params
//    );
//};
//export const acceptFriendRequestNew = async (params: IAcceptFriendRequest) => {
//    return await axios.post(endpoints.acceptFriendRequest, params);
//};

//export const rejectFriendRequestNew = async (params: IRejectFriendRequest) => {
//    return await axios.post(endpoints.rejectFriendRequest, params);
//};

//export const cancelFriendRequestNew = async (params: ICancelFriendRequest) => {
//    return await axios.post(endpoints.cancelFriendRequest, params);
//};

export const getFriends = async () => {
    return await axios.get<IFriend[]>(endpoints.friends);
};

export const addFriend = async (params: IFriendAdd) => {
    return await axios.post<IFriendActionResponse>(endpoints.addFriend, params);
};

export const removeFriend = async (params: IFriendRemove) => {
    return await axios.post<IFriendActionResponse>(endpoints.removeFriend, params);
};

export const approveFriend = async (params: IFriendAdd) => {
    return await axios.post<IFriendActionResponse>(endpoints.approveFriend, params);
};

export const acceptFriendRequest = async (params: IAcceptFriendRequest) => {
    return await axios.post<IFriendActionResponse>(endpoints.acceptFriendRequest, params);
};

export const rejectFriendRequest = async (params: IRejectFriendRequest) => {
    return await axios.post<IFriendActionResponse>(endpoints.rejectFriendRequest, params);
};

export const cancelFriendRequest = async (params: ICancelFriendRequest) => {
    return await axios.post<IFriendActionResponse>(endpoints.cancelFriendRequest, params);
};
