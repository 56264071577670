import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { textAlign } from "@mui/system";

import { ColorVariant } from "src/store/models";
import Countdown, { zeroPad } from "react-countdown";
import { usePlayer } from "src/sounds/use-player";
import shortColor from "src/utils/shortColor";
import useChangeTheme from "src/hooks/useChangeTheme";

import { useStyles } from "./styles";
import { Grid } from "@mui/material";

type RedesignClockProps = {
    warningLimit: number;
    active: boolean;
    activeClock?: { color: ColorVariant };
    initTime: number;
    noTimerSound: boolean;
    color: ColorVariant;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
};

export const RedesignClock = memo(
    ({
        warningLimit,
        initTime,
        activeClock,
        active,
        noTimerSound,
        color,
        isMultiTable,
        isTwoGameTable,
        ...props
    }: RedesignClockProps) => {
        const [gameTime, setGameTime] = useState(initTime);
        const currentWidth = window.innerWidth;
        const appearanceTheme = useChangeTheme();

        const seconds = gameTime / 1000;
        const classes = useStyles({
            warning: warningLimit >= seconds,
            appearanceTheme,
            ...props,
        });

        useEffect(() => {
            setGameTime(initTime);
        }, [initTime]);

        useEffect(() => {
            if (gameTime && gameTime / 1000 < 20) {
                // if (!noTimerSound) soundPlayer('timeoutSoundPlay');
            }
        }, [gameTime]);

        useEffect(() => {
            if (activeClock && activeClock.color === shortColor(color)) {
                const timer = setInterval(() => {
                    setGameTime((gameTime) =>
                        gameTime - 1000 <= 0 ? 0 : gameTime - 1000
                    );
                }, 1000);

                return () => {
                    clearInterval(timer);
                };
            }
        }, [activeClock]);

        const desktopStyleProps = {
            // TODO: maybe we should set this values in useStyles?
            paddingLeft: !isMultiTable
                ? currentWidth > 1250
                    ? "0%"
                    : "0px"
                : undefined,
        };

        const timeText = moment()
            .startOf("day")
            .seconds(seconds)
            .format("mm:ss");

        return (
            <div
                style={{ ...desktopStyleProps }}
                className={cn(classes.text, "redesign-clock-text", {
                    isMultiTable,
                    isTwoGameTable,
                })}
            >
                <div
                    className={cn(classes.slicedTimerBox, {
                        isMultiTable,
                    })}
                >
                    <span
                        className={cn(classes.slicedTimerPart, "minutes", {
                            isMultiTable,
                        })}
                    >
                        {timeText.slice(0, 2)}
                    </span>
                    <span
                        className={cn(classes.slicedTimerPart, "colon", {
                            isMultiTable,
                        })}
                    >
                        {timeText.slice(2, 3)}
                    </span>
                    <span
                        className={cn(classes.slicedTimerPart, "seconds", {
                            isMultiTable,
                        })}
                    >
                        {timeText.slice(3)}
                    </span>
                </div>
            </div>
        );
    }
);
