// Кнопки запуска анимации

import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { useWinOrLostStatus } from "src/pages/game-new2/hooks/useWinOrLostStatus";
import useChangeTheme from "src/hooks/useChangeTheme";
import useWindowSize from "src/hooks/useWindowSize";

import mainGoldIconRedesign from "src/assets/icons/chessboardPage/redesignCoinGold.png";
import chipsStack from "src/assets/icons/gameChips/chipsStack.svg";
// import coin from "src/assets/icons/coin.svg";
import coin from "src/assets/icons/chip.svg";

import mainFreeIconRedesign from "src/assets/icons/chessboardPage/redesignCoinSilver.png";
import freeCoin from "src/assets/icons/freeCoin.png";
import useStores from "src/hooks/useStores";
import {
    UserBalanceType,
    UserBalanceTypeNew,
    ChessboardType,
} from "src/store/models";

import "./styles.css";
import useStyles from "./styles";

interface ICoinAnimationContainer {
    balanceType?: UserBalanceType | UserBalanceTypeNew;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    chessboardType: string;
    memoRise?: boolean;
}

const CoinAnimationContainer = ({
    balanceType,
    isMultiTable,
    isTwoGameTable,
    chessboardType,
    memoRise,
}: ICoinAnimationContainer) => {
    const { resultData, isWon } = useWinOrLostStatus();
    const { gameStore } = useStores();
    const windowSize = useWindowSize();
    const stackArray = new Array(5).fill(1);
    // const player1 = document.getElementById(`b-${gameStore?.currentGame?.id}`);
    // const player2 = document.getElementById(`w-${gameStore?.currentGame?.id}`);
    //    const coinStartPosition = document.getElementById(`coinBoxMain-${gameStore?.currentGame?.id}`);
    //=====

    const player1 = useMemo(() => {
        return document.getElementById(`b-${gameStore?.currentGameNew?._id}`);
    }, [gameStore?.currentGameNew?._id, resultData?.winner, windowSize]);

    const player2 = useMemo(() => {
        return document.getElementById(`w-${gameStore?.currentGameNew?._id}`);
    }, [gameStore?.currentGameNew?._id, resultData?.winner, windowSize]);

    const coinStartPosition = document.getElementById(
        `coinBoxMain-${gameStore?.currentGameNew?._id}`
    );

    const appearanceTheme = useChangeTheme();

    const [animatePosition, setPosition] = useState<{
        [key: string]: number | boolean;
    }>({
        vertical: false,
        horizontal: false,
    });
    const vertical = animatePosition?.vertical;
    const horizontal = animatePosition?.horizontal;
    const animationLatency = 0.2;
    const transitionLatency = 0.2;
    const multitableMultipler = 1.4;
    const jumpPotToMobileMidDelay = 520;

    const animateCoin = (player) => {
        if (!player)
            return setPosition({
                vertical: false,
                horizontal: false,
            });
        if (coinStartPosition && player) {
            const coinCoords = coinStartPosition.getBoundingClientRect();
            const winner = player.getBoundingClientRect();
            const subtractionVertival = (winner?.top - coinCoords.top).toFixed(
                2
            );
            const subtractionHorizontal = (
                winner?.left - coinCoords.left
            ).toFixed(2);

            return !isMultiTable
                ? setPosition({
                      vertical: +subtractionVertival,
                      horizontal: +subtractionHorizontal,
                  })
                : setPosition({
                      vertical: +subtractionVertival * multitableMultipler,
                      horizontal: +subtractionHorizontal * multitableMultipler,
                  });
        }
    };

    const styles = useStyles({
        vertical,
        horizontal,
        animationLatency,
        transitionLatency,
    });

    useEffect(() => {
        if (resultData?.winner) {
            // const winnerId = `${resultData?.winner}-${gameStore?.currentGame?.id}`;
            const winnerId = `${resultData?.winner}-${gameStore?.currentGameNew?._id}`;
            const isVerticalBoard =
                chessboardType === ChessboardType.mobileGame ||
                chessboardType === ChessboardType.mobileView ||
                chessboardType === ChessboardType.desktopView;

            if (player1?.id === winnerId) {
                isVerticalBoard
                    ? setTimeout(() => {
                          animateCoin(player1);
                      }, jumpPotToMobileMidDelay)
                    : animateCoin(player1);
                return;
            }
            if (player2?.id === winnerId) {
                isVerticalBoard
                    ? setTimeout(() => {
                          animateCoin(player2);
                      }, jumpPotToMobileMidDelay)
                    : animateCoin(player2);
                return;
            }
        }
        if (!resultData?.winner) {
            return setPosition({
                vertical: false,
                horizontal: false,
            });
        }
    }, [resultData?.winner]);

    return (
        <>
            <div
                className={cn(styles.coinBoxMain, appearanceTheme, {
                    isMultiTable: isMultiTable,
                })}
                id={`coinBoxMain-${gameStore?.currentGameNew?._id}`}
            >
                <div className={cn(styles.coinBox, "mainIcon")}>
                    <img
                        id="mainCoin"
                        className={cn(styles.coinImage)}
                        src={
                            balanceType === UserBalanceTypeNew.play
                                ? chipsStack
                                : mainFreeIconRedesign
                        }
                        alt="mainCoin"
                    />
                </div>
                {stackArray &&
                    stackArray.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={cn(
                                    styles.coinBox,
                                    "stack",
                                    `stack_${index}`
                                )}
                            >
                                <img
                                    id="coin"
                                    className={cn(
                                        styles.coinImage,
                                        `coin_${index}`
                                    )}
                                    key={`coin_${index}`}
                                    src={
                                        balanceType === UserBalanceTypeNew.play
                                            ? coin
                                            : freeCoin
                                    }
                                    alt="coin"
                                />
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default observer(CoinAnimationContainer);
