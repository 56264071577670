import React, { useEffect, useState, memo, useMemo } from "react";
import moment from "moment";
import cn from "classnames";

import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import useChangeTheme from "src/hooks/useChangeTheme";

import { IGameChatBody } from "../types/IChat";
import { fileHelper } from "../../../../../shared/helpers/FileHelper/index";

import { useStyles } from "./styles";

export const GameChatHistoryMessage = memo(
    ({
        children,
        time,
        position,
        getStickerMessage,
        isLastElem,
    }: IGameChatBody) => {

        const classes = useStyles({ position });
        const appearanceTheme = useChangeTheme();
        const CURRENT_FORMAT = "HH:mm:ss";
        const [isLoaded, setLoaded] = useState<boolean>(false);

        const momentTime = useMemo(() => {
            return moment(time).format(CURRENT_FORMAT);
        }, [time]);

        const formatMessage = (text) => {
            if (typeof text !== 'string') {
                console.error('Expected a string in formatMessage, received:', text);
                return null;
            }

            const regex = /\[img sticker="(.*?)"\]/g;
            const parts = text.split(regex);

            return parts.map((part, index) => {
                if (index % 2 === 1) {
                    return (
                        <img
                            key={index}
                            className={cn(classes.stickerImageWrapper, appearanceTheme, { isLoaded: true })}
                            src={part}
                            alt="sticker"
                        />
                    );
                }
                return <span key={index}>{part}</span>;
            });
        };

        // Проверка на содержание стикера
        const isStickerMessage = typeof children === 'string' && children.includes('[img sticker=');

        return (
            <div className={cn(classes.messageRow, appearanceTheme)}>
                <div
                    className={cn(classes.messageBlock, appearanceTheme, {
                        [classes.isSticker]: isStickerMessage,
                        isLastElem: isLastElem,
                    })}
                >
                    <div
                        className={
                            position === "center"
                                ? cn(classes.infoMessageText, appearanceTheme)
                                : cn(classes.playerMessageText, appearanceTheme)
                        }
                        style={{
                            textAlign: isStickerMessage ? "right" : "left",
                        }}
                    >
                        {getStickerMessage ? (
                            <>
                                {!isLoaded && (
                                    <div
                                        className={cn(
                                            classes.stckerSkeletonImageWrapper,
                                            appearanceTheme,
                                            {
                                                loadingSkeleton: !isLoaded,
                                            }
                                        )}
                                    ></div>
                                )}
                                <LazyImages
                                    className={cn(
                                        classes.stickerImageWrapper,
                                        appearanceTheme,
                                        {
                                            isLoaded: isLoaded,
                                        }
                                    )}
                                    placeholderImg={avatarPlaceholder}
                                    src={fileHelper.getFileURL(
                                        getStickerMessage
                                    )}
                                    errorImg={avatarPlaceholder}
                                    setLoaded={(status: boolean) =>
                                        setLoaded(status)
                                    }
                                />
                            </>
                        ) : (
                            formatMessage(children)
                        )}
                    </div>
                </div>
            </div>
        );
    }
);
