import loadable from "@loadable/component";
import { useMediaQuery, Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import React, {
    useEffect,
    useRef,
    useState,
    useMemo,
    useCallback,
} from "react";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

import RatingCrown from "src/components/RaitingCrownBox";
import paths from "src/consts/paths";
import useChangeTheme from "src/hooks/useChangeTheme";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import { GameType } from "src/store/models";

import { ModalTypes, ModalProvider } from "../../../pages/landing/utils/context";
import LoginForm from "../../../dialogs/login/LoginForm";
import { RegisterForm } from "../../../pages/register/RegisterForm";

import BurgerMenu from "./components/burger-menu";
import Info from "./components/info/Info";
import Logo from "./components/logo/Logo";
import DesktopNav from "./components/desktopNav";
import LanguagePicker from "./components/LanguagePicker";
import { PhoneAuth } from "../../../dialogs/phone-auth";
import { useStyles } from "./styles";
import { MainActionButton } from "../../buttons/newDesignButtons/mainActionButton";
import TopPlayers from "src/pages/lobby/components/TopPlayers/TopPlayers";
import JackpotIcon from 'src/assets/icons/Jackpot.svg';
import CrownIcon from 'src/assets/icons/CrownAppBar.svg';


const JackPotComponent = loadable(() => import("src/components/JackPot"));

const AppBar = (props: { isGamePage: boolean; sideMenuIsOpen: boolean }) => {
    const location = useLocation();
    const {
        mainLanding: { registeR, logIn },
    } = useLocale();
    const { authStore, generalStore, lobbyStore, achievementsStatStore } =
        useStores();
    const {
        lobby,
        login,
        register,
        recoveryPassword,
        alg1xBet,
        algaltente,
        liveDealer: liveDealerPath,
        jackpoker,
        sports,
        logout,
        games,
    } = paths;
    const isPoker = generalStore.gameType === GameType.jackpoker;
    const topPlayers = achievementsStatStore?.topPlayers;

    const styles = useStyles({
        isGamePage: props.isGamePage,
        isPoker: isPoker,
    });
    const appearanceTheme = useChangeTheme();
    const [increaseRakeBar, calcIncreaseRakeBar] = useState<Array<number>>([]);
    const user = authStore.currentUser;

    const isGameScreen = useRouteMatch("/game/:id");
    const isPurchaseDiceCoins = lobbyStore.isPurchaseDiceCoins;

    const userRakeBack = user?.balance.rakeBack;
    const isDesktop = useMediaQuery("(min-width: 1025px)");
    const isTablet = useMediaQuery("(max-width: 1024px)");
    const isMobile = useMediaQuery("(max-width: 560px)");
    const isLanding = useRouteMatch(paths.lobby);

    const isFirstPages =
        location.pathname === login ||
        location.pathname === register ||
        location.pathname === recoveryPassword ||
        location.pathname === logout || 
        location.pathname === lobby




    const hideJackPot =
        //location.pathname === alg1xBet ||
        //location.pathname === algaltente ||
        //location.pathname === liveDealerPath ||
        location.pathname === jackpoker;
    //location.pathname.includes("slot") ||
    //location.pathname.includes("live-dealer") ||
    //location.pathname === sports;
    const diceChessPath = paths.diceChessLobby;
    const isDiceChessPage = location.pathname === diceChessPath;
    const showRatingCrownAndJackpot = isDiceChessPage && (isMobile || isTablet);

    const isMultitablePage = location.pathname === games;
    const isSlot = location.pathname.indexOf("/slots/") >= 0;

    const refTypePopup = useRef();
    const refTypeButton = useRef();

    const refBetPopup = useRef();
    const refBetButton = useRef();

    const refTimePopup = useRef();
    const refTimeButton = useRef();

    useOnClickOutside([refTypePopup, refTypeButton], () => {});

    useOnClickOutside([refBetPopup, refBetButton], () => {
        lobbyStore.toggleBetFilterForm(false);
    });

    useOnClickOutside([refTimePopup, refTimeButton], () => {
        lobbyStore.toggleTimeFilterForm(false);
    });

    const baseLimit = 20;
    useEffect(() => {
        if (
            !achievementsStatStore.isLoadedRating &&
            (!topPlayers || topPlayers.length < baseLimit)
        ) {
            achievementsStatStore.getTopPlayers({
                skip: 0,
                limit: baseLimit,
            });
        }
    }, []);

    useEffect(() => {
        if (typeof userRakeBack === "number") {
            userRakeBack &&
                calcIncreaseRakeBar([...increaseRakeBar, userRakeBack]);
        }
    }, [userRakeBack]);

    const toggleOnClick = useCallback(() => {
        generalStore.toggleSideMenu();
    }, []);

    const backButton = useCallback(() => {
        history.back();
    }, []);

    const [modalState, setModalState] = useState({
        register: false,
        login: false,
        "phone-auth": false,
        businessMsg: false,
        notifications: false,
    });

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };
    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    return (
        <>
            <ModalProvider.Provider value={{ openModal, closeModal }}>
                {modalState.register && <RegisterForm />}
                {modalState["phone-auth"] && <PhoneAuth />}
                {modalState.login && <LoginForm />}
                <div
                    className={cn(styles.containerNav, appearanceTheme, {
                        isPoker: isPoker,
                        isLanding: isLanding,
                        isPurchaseDiceCoins: isPurchaseDiceCoins,
                    })}
                >
                    {isSlot && (
                        <div
                            className={cn(styles.backPage)}
                            onClick={backButton}
                        >
                            ⇐
                        </div>
                    )}
                    <Box
                        component="div"
                        className={cn(styles.appBar, appearanceTheme, {
                            isGamePage: props.isGamePage,
                            isPoker: isPoker,
                            isMultitablePage: isMultitablePage,
                            slotPage: isSlot,
                        })}
                    >
                        {/* Левая секция */}
                        <div className={styles.leftSection}>
                            {authStore.isAuthorized || generalStore.isHalyk ? (
                                <>
                                    {(isMobile || isTablet) && !isPoker && (
                                        <Box
                                            component="div"
                                            className={cn(
                                                styles.burgerRatingWrapper,
                                                {}
                                            )}
                                        >
                                            <BurgerMenu
                                                onClick={toggleOnClick}
                                                sideMenuIsOpen={
                                                    props.sideMenuIsOpen
                                                }
                                            />
                                        </Box>
                                    )}
                                    <Box
                                        component="div"
                                        className={cn(styles.logoWithNav, {
                                            isPoker: isPoker,
                                            isMultitablePage: isMultitablePage,
                                        })}
                                    >
                                        {(!isMobile || generalStore.isHalyk) && (
                                            <Logo
                                                onClickToggler={() =>
                                                    generalStore.closeSideMenu()
                                                }
                                                isMultitablePage
                                            />
                                        )}
                                        {isDesktop && !isMultitablePage && !generalStore.isHalyk && (
                                            <DesktopNav styles={styles} isLanding={isLanding} />
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <div
                                    className={styles.logoContainerUnauthorized}
                                >
                                    {(!isMobile || !authStore.isAuthorized) && (
                                        <Logo />
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Центральная секция */}
                        <div className={styles.centerSection}>
                            {authStore.isAuthorized && !generalStore.isHalyk && showRatingCrownAndJackpot && (
                                <TopPlayers componentPlace={"appBar"} />
                            )}
                            {authStore.isAuthorized && !hideJackPot && !isMultitablePage && !generalStore.isHalyk && (
                                isDesktop ? (
                                    <JackPotComponent isFullAnimation={false} />
                                ) : (
                                    showRatingCrownAndJackpot && (
                                        <div className={styles.jackpotIconWrapper}>
                                            <img src={JackpotIcon} alt="Jackpot" className={styles.jackpotIcon} />
                                        </div>
                                    )
                                )
                            )}
                        </div>

                        {/* Правая секция */}
                        <div className={styles.rightSection}>
                            {authStore.isAuthorized ? (
                                <>
                                    <Box
                                        component="div"
                                        className={cn(styles.infoGrid, {
                                            isMultitablePage: isMultitablePage,
                                        })}
                                    ></Box>
                                    <Info />
                                </>
                            ) : (
                                <div className={styles.authWrapper}>
                                    <div className={styles.registration}>
                                        <MainActionButton
                                            appearanceTheme="primary"
                                            text={registeR("Sign Up")}
                                            className={styles.signInBtn}
                                            onClick={() =>
                                                openModal("register")
                                            }
                                        />
                                        <MainActionButton
                                            appearanceTheme="primary"
                                            text={logIn("Log In")}
                                            className={styles.logInBtn}
                                            onClick={() => openModal("login")}
                                        />
                                    </div>
                                    <div className={styles.languageWrapper}>
                                        <LanguagePicker showInDropper={true} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </div>
            </ModalProvider.Provider>
        </>
    );
};

export default observer(AppBar);
