import { Box, useMediaQuery } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { ToggleHidden } from "src/components/balance/components/ToggleHidden";
import { BonusDescriptionModal } from "src/components/modals/BonusDescriptionModal";
import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";
import { UserBalanceType } from "src/store/models";

import Balance from "../../../../balance";

import { useStyles } from "./styles";
import { IInfoEdition } from "./type";

const InfoDesktopEdition = ({
    onClickBonus,
    isBonusModalOpen,
}: IInfoEdition) => {
    const { authStore, generalStore, lobbyStore } = useStores();
    const isDesktop = useMediaQuery("(min-width: 1025px)");
    const isMobile = useMediaQuery("(min-width: 300px)");

    const user = authStore.currentUser;
    const styles = useStyles();
    const history = useHistory();

    const isHalyk = generalStore.isHalyk;

    const onHiddenChange = (hidden: boolean) => {
        generalStore.setBalanceHidden(hidden);
    };

    return (
        <>
            {(isDesktop || isMobile) && (
                <>
                    <div className={styles.appBarElement}>
                        <Box
                            className={cn(styles.unitedAppbarBalance)}
                            sx={{ cursor: "pointer" }}
                        >
                            <div className={styles.unitedAppbarEye}>
                                <ToggleHidden
                                    onChange={onHiddenChange}
                                    hidden={generalStore.isBalanceHidden}
                                    backGroundColor={authStore.currentUser?.settings.background || "blue"}
                                />
                            </div>
                            <span className={styles.separator1}>|</span>
                            <div
                                className={cn(styles.unitedAppbarCount, {
                                    gold:
                                        lobbyStore.lobbyMode ===
                                        UserBalanceType.coins,
                                    silver:
                                        lobbyStore.lobbyMode ===
                                        UserBalanceType.play,
                                    noBackground:
                                        lobbyStore.lobbyMode ===
                                        UserBalanceType.referralBonus,
                                    isAggregatorMode:
                                        generalStore.isAggregatorMode,
                                })}
                                onClick={() => {
                                    if (authStore.isAuthorized) {
                                        history.push(paths.cashbox);
                                    }
                                }}
                            >
                                {authStore.isAuthorized && (
                                    <Balance
                                        amount={
                                            user?.balance[
                                            lobbyStore.lobbyMode
                                            ] || 0
                                        }
                                        hidden={authStore.balanceHidden}
                                        backGroundColor={
                                            authStore.currentUser?.settings
                                                .background || "blue"
                                        }
                                        onChange={onHiddenChange}
                                        type={lobbyStore.lobbyMode}
                                        onClick={() => { }} // Provide a no-op onClick handler if required by the Balance component
                                    />
                                )}
                                {(
                                    <span className={styles.separator2}>|</span>
                                )}
                                {
                                    generalStore.isDicechessMode &&
                                    authStore.isAuthorized && (
                                        <div
                                            className={cn(
                                                styles.bonusBalanceWrapper,
                                                "desktopBonusBalance"
                                            )}
                                        >
                                            <Balance
                                                className={cn(
                                                    styles.bonusBalance,
                                                    "realCoin"
                                                )}
                                                amount={
                                                    user?.balance?.bonus || 0
                                                }
                                                hidden={authStore.balanceHidden}
                                                backGroundColor={
                                                    authStore.currentUser
                                                        ?.settings.background ||
                                                    "blue"
                                                }
                                                onChange={onHiddenChange}
                                                type={
                                                    UserBalanceType.referralBonus
                                                }
                                                onClick={() => { }} // No-op click handler for bonus balance
                                            />
                                            <BonusDescriptionModal
                                                className={
                                                    styles.bonusDescriptionModal
                                                }
                                            />
                                        </div>
                                    )}
                            </div>
                        </Box>
                    </div>
                </>
            )}
        </>
    );
};

export default observer(InfoDesktopEdition);
