import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import { Grid, Box, Container } from "@mui/material";
import defaultAvatar from "src/assets/images/defaultPlaceholder.png";

import {
    WatchersStatusType,
    WatchingType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import watchingUsers from "src/assets/icons/watching-users.svg";
import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import endpoints from "src/core/endpoints";
import calcNameLength from "src/utils/calcNameLength";
import useChangeTheme from "src/hooks/useChangeTheme";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";
import { ChatExistPosition, PlayerInfoExistPosition } from "src/store/models";

import { useStyles } from "./styles";
import WatchingToggler from "../../../../components/WatchingPlayers/components/WatchingToggler";

interface IDesktopWatchers {
    componentPosition: keyof typeof ChatExistPosition;
}

const DesktopWatchers = ({ componentPosition }: IDesktopWatchers) => {
    const { gameStore, authStore } = useStores();
    const me = authStore.currentUser?._id;
    const [isOpen, setOpen] = useState<boolean | WatchersStatusType>(false);
    const watchers = gameStore.guests;
    const [uniqWatchers, setUniqWatchers] = useState<WatchingType[]>([]);
    const classes = useStyles();
    const appearanceTheme = useChangeTheme();
    const chessBoardProps = useChessBoardProps();
    const {
        lobby: {
            playerMiniStats: { statsRank, tournamentRp },
        },
    } = useLocale();

    useEffect(() => {
        if (Array.isArray(watchers) && watchers.length > 0) {
            const uniqWatchingObject = {
                //@ts-ignore
                ...[...new Set(watchers.map((guest) => guest._id))],
            };
            const uniqWatchingArray = watchers.filter(
                (guest, index) =>
                    guest._id === uniqWatchingObject[index] && guest._id !== me
            );
            setUniqWatchers(uniqWatchingArray);
        }
        if (Array.isArray(watchers) && watchers.length === 0) {
            setUniqWatchers([]);
        }
    }, [watchers.length]);

    if (componentPosition === ChatExistPosition.inGame) {
        return (
            <Box
                component="div"
                className={cn(classes.watchingMainContainer, appearanceTheme)}
            >
                {uniqWatchers.length === 0 && (
                    <p className={cn(classes.noWatchersTitle, appearanceTheme)}>
                        No spectators
                    </p>
                )}
                {uniqWatchers &&
                    uniqWatchers.map((user, index) => (
                        <Box
                            key={`${user._id}_box`}
                            className={cn(
                                classes.watchingUserBox,
                                appearanceTheme
                            )}
                        >
                            {/* <img
                            src={`${endpoints.avatarPath}${user?.avatar}`}
                            className={cn(
                                classes.watchingUserAvatar,
                                appearanceTheme
                            )}
                        /> */}
                            <img
                                src={`${defaultAvatar}`}
                                className={cn(
                                    classes.watchingUserAvatar,
                                    appearanceTheme
                                )}
                            />
                            <div
                                className={cn(
                                    classes.watchingUserTextBox,
                                    appearanceTheme
                                )}
                            >
                                <p
                                    className={cn(
                                        classes.watchingUserNick,
                                        appearanceTheme
                                    )}
                                >
                                    {user.nickname}
                                </p>
                                <span
                                    className={cn(
                                        classes.watchingUserSpanRank,
                                        appearanceTheme
                                    )}
                                >
                                    <p
                                        className={cn(
                                            classes.watchingUserRank,
                                            "rank",
                                            appearanceTheme
                                        )}
                                    >
                                        {statsRank("Rank")}:
                                    </p>
                                    <img src={watchingUsers} alt="rank" />
                                </span>
                                <span
                                    className={cn(
                                        classes.watchingUserSpanRank,
                                        appearanceTheme
                                    )}
                                >
                                    <p
                                        className={cn(
                                            classes.watchingUserRank,
                                            "rankPoint",
                                            appearanceTheme
                                        )}
                                    >
                                        {tournamentRp("Rank Points")}:
                                    </p>
                                    <p
                                        className={cn(
                                            classes.watchingUserRank,
                                            "rankPointNumber",
                                            appearanceTheme
                                        )}
                                    >
                                        1500
                                    </p>
                                </span>
                            </div>
                        </Box>
                    ))}
            </Box>
        );
    }

    return (
        <>
            {!chessBoardProps?.isMultiTable && (
                <WatchingToggler
                    setOpen={(properties) => setOpen(properties)}
                    openStatus={WatchersStatusType.show}
                    isOpen={isOpen}
                    watchingArray={uniqWatchers}
                    isMobileGamePage={false}
                    togglerClassName={cn({
                        [classes.watchingToggler]:
                            chessBoardProps?.isMultiTable,
                    })}
                />
            )}
            <OutsideClickHandler
                onOutsideClick={() => {
                    isOpen === WatchersStatusType.show &&
                        setOpen(WatchersStatusType.hide);
                }}
            >
                <Box
                    className={
                        isOpen === WatchersStatusType.show
                            ? cn(classes.watchingListShow, appearanceTheme)
                            : isOpen === WatchersStatusType.hide
                            ? cn(classes.watchingListHide, appearanceTheme)
                            : cn(classes.watchingListReload, appearanceTheme)
                    }
                >
                    <Container
                        className={cn(
                            classes.watchingListContainerDesktop,
                            appearanceTheme
                        )}
                    >
                        <WatchingToggler
                            setOpen={(properties) => setOpen(properties)}
                            openStatus={WatchersStatusType.hide}
                            isOpen={isOpen}
                            watchingArray={uniqWatchers}
                            dryStyle={true}
                            togglerClassName={classes.watchingToggler}
                            elementPlace={
                                PlayerInfoExistPosition.inProgressPreview
                            }
                        />
                        <Grid
                            container
                            spacing={1.5}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.watchingMainGrid}
                        >
                            {uniqWatchers.map((user, index) => (
                                <Grid
                                    key={user._id}
                                    item
                                    xs={4}
                                    style={{
                                        minWidth: "50px",
                                        maxWidth: "80px",
                                    }}
                                >
                                    <Box
                                        key={`${user._id}_box`}
                                        className={classes.watchingUserBox}
                                    >
                                        {/* <img
                                            src={`${endpoints.avatarPath}${user.avatar}`}
                                            className={
                                                classes.watchingUserAvatar
                                            }
                                        /> */}
                                        <img
                                            src={`${defaultAvatar}`}
                                            className={cn(
                                                classes.watchingUserAvatar,
                                                appearanceTheme
                                            )}
                                        />
                                        <p className={classes.watchingUserNick}>
                                            {calcNameLength(
                                                user.nickname,
                                                450,
                                                9
                                            )}
                                        </p>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>
            </OutsideClickHandler>
        </>
    );
};

export default observer(DesktopWatchers);
