import React, { memo, useCallback, useMemo } from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import Badge from "@mui/material/Badge";

import { EGameChatTab, PlayerInfoExistPosition } from "src/store/models";

import { useLocale } from "src/providers/LocaleProvider";
import { appColors } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import useChangeTheme from "src/hooks/useChangeTheme";
import WatchingPlayers from "src/components/WatchingPlayers";
import WatchingToggler from "src/components/WatchingPlayers/components/WatchingToggler";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import {
    IWatchingList,
    WatchersStatusType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import paths from "src/consts/paths";

import chatIconAction from "src/assets/icons/gameChatIcons/redesignIcons/chatNoActive.svg";
import historyIconAction from "src/assets/icons/gameChatIcons/redesignIcons/history.svg";
import watchersIconAction from "src/assets/icons/gameChatIcons/redesignIcons/watchersNoActive.svg";
import multiTabsIcon from "src/assets/icons/gameChatIcons/redesignIcons/multiTabsIcon.svg";

import { EColor } from "src/components/types";

import { useStyles } from "./styles";

interface ILineOfActiveIcons {
    solidMultiGames?: any;
    isMobileGamePage?: boolean;
    elementPlace?: keyof typeof PlayerInfoExistPosition;
}

const LineOfActiveIcons = observer(
    ({
        isMobileGamePage,
        solidMultiGames,
        elementPlace,
    }: ILineOfActiveIcons) => {
        const styles = useStyles();
        const { gameStore, authStore } = useStores();
        const { onChatToggler, onChatTabsToggler, currentTab } =
            useLeftLayoutContext();

        const appearanceTheme = useChangeTheme();
        const chatMessagesCount = gameStore?.newChatMessage?.length;

        const onChangeGameTab = useCallback(
            (tab: keyof typeof EGameChatTab) => {
                authStore.setGameChatTab(tab);
                onChatTabsToggler && onChatTabsToggler(tab);
            },
            []
        );

        const boxOfIcons = {
            inProgressPreview: () => {
                return (
                    <>
                        <div
                            className={cn(
                                styles.actionButtonWrapper,
                                appearanceTheme,
                                "watchersIcon",
                                {}
                            )}
                            onClick={() => {
                                if (onChatToggler) {
                                    onChatToggler();
                                }
                                onChangeGameTab(EGameChatTab.watchers);
                            }}
                        >
                            <img
                                className={cn(
                                    styles.actionIcon,
                                    appearanceTheme,
                                    "watchersIcon"
                                )}
                                src={watchersIconAction}
                                alt="watchersIcon"
                            />
                        </div>
                    </>
                );
            },

            solidMultiGames: () => {
                return (
                    <>
                        <div
                            className={cn(
                                styles.actionButtonWrapper,
                                appearanceTheme,
                                "chat",
                                {}
                            )}
                            onClick={() => {
                                if (onChatToggler) {
                                    onChatToggler();
                                }
                                onChangeGameTab(EGameChatTab.chat);
                            }}
                        >
                            <Badge
                                className={cn(styles.badgeNotifications, {
                                    emptyNote: chatMessagesCount === 0,

                                    moreThan99: chatMessagesCount > 99,
                                })}
                                badgeContent={chatMessagesCount}
                                color="primary"
                            >
                                <img
                                    className={cn(
                                        styles.actionIcon,
                                        appearanceTheme,
                                        "multitabsIcon"
                                    )}
                                    src={multiTabsIcon}
                                    alt="multitabsIcon"
                                />
                            </Badge>
                        </div>
                    </>
                );
            },
            soloGames: () => {
                return (
                    <>
                        <div
                            className={cn(
                                styles.actionButtonWrapper,
                                appearanceTheme,
                                "chat",
                                {}
                            )}
                            onClick={() => {
                                if (onChatToggler) {
                                    onChatToggler();
                                }
                                onChangeGameTab(EGameChatTab.chat);
                            }}
                        >
                            <Badge
                                className={cn(styles.badgeNotifications, {
                                    emptyNote: chatMessagesCount === 0,

                                    moreThan99: chatMessagesCount > 99,
                                })}
                                badgeContent={chatMessagesCount}
                                color="primary"
                            >
                                <img
                                    className={cn(
                                        styles.actionIcon,
                                        appearanceTheme,
                                        "chat"
                                    )}
                                    src={chatIconAction}
                                    alt="chatIcon"
                                />
                            </Badge>
                        </div>

                        {/* 
                        Turn off temporary
                        <div
                            className={cn(
                                styles.actionButtonWrapper,
                                appearanceTheme,
                                "historyIcon",
                                {}
                            )}
                            onClick={() => {
                                if (onChatToggler) {
                                    onChatToggler();
                                }
                                onChangeGameTab(EGameChatTab.history);
                            }}
                        >
                            <img
                                className={cn(
                                    styles.actionIcon,
                                    appearanceTheme,
                                    "historyIcon"
                                )}
                                src={historyIconAction}
                                alt="historyIcon"
                            />
                        </div> */}
                        <div
                            className={cn(
                                styles.actionButtonWrapper,
                                appearanceTheme,
                                "watchersIcon",
                                {}
                            )}
                            onClick={() => {
                                if (onChatToggler) {
                                    onChatToggler();
                                }
                                onChangeGameTab(EGameChatTab.watchers);
                            }}
                        >
                            <img
                                className={cn(
                                    styles.actionIcon,
                                    appearanceTheme,
                                    "watchersIcon"
                                )}
                                src={watchersIconAction}
                                alt="watchersIcon"
                            />
                        </div>
                    </>
                );
            },
        };

        const getCurrentList = (
            element: keyof typeof PlayerInfoExistPosition | undefined,
            solidMultiGames: unknown[]
        ): string => {
            if (
                element &&
                element === PlayerInfoExistPosition.inProgressPreview
            ) {
                return PlayerInfoExistPosition.inProgressPreview;
            }
            return solidMultiGames && solidMultiGames.length > 0
                ? "solidMultiGames"
                : "soloGames";
        };

        const getCurrentJSXElement = useMemo(
            () => getCurrentList(elementPlace, solidMultiGames),
            [elementPlace, solidMultiGames]
        );

        return (
            <Box
                component="div"
                className={cn(styles.actionIconsBox, appearanceTheme, {
                    isMobileGamePage: isMobileGamePage,
                    isMultitableMobile:
                        solidMultiGames && solidMultiGames.length > 0,
                })}
            >
                {boxOfIcons[getCurrentJSXElement]()}
            </Box>
        );
    }
);

export default memo(LineOfActiveIcons);
