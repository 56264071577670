import cn from "classnames";
import React, {
    memo,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { ArrayHelper } from "src/shared/helpers/ArrayHelper";
import { NumberHelper } from "src/shared/helpers/NumberHelper";

import { useStyles } from "./styles";
import { getBlackFiguresIcons, getWhiteFiguresIcons } from "./textures";

function getPosition(index) {
    // ant equal values below will decide problem with missing dice on IOs 17+
    const offsets = [1079.9, 719.9, -720.1, -1080.1];
    const offsetAngle = ArrayHelper.pickRandom(offsets);
    const offsetAngleY = ArrayHelper.pickRandom(offsets);
    const offsetAngleX = ArrayHelper.pickRandom(offsets);
    const offsetAngleZ = ArrayHelper.pickRandom(offsets);
    const offsetAngle2D = 0; //Math.floor(Math.random() * 20 - 10);

    const positions = {
        1: {
            x: offsetAngle + offsetAngleX,
            y: 360,
            z: 360 + offsetAngleZ + offsetAngle2D,
        },
        2: {
            x: -90 + 360,
            y: offsetAngle + 360 + offsetAngleY + offsetAngle2D,
            z: offsetAngle + offsetAngleZ,
        },
        3: {
            x: -270 + 360 + offsetAngleX,
            y: offsetAngle + 360 + offsetAngleY + offsetAngle2D,
            z: offsetAngle + 360 + offsetAngleZ,
        },
        4: {
            x: 360 + offsetAngleX,
            y: -270 + 360 + offsetAngleY,
            z: offsetAngle + 360 + offsetAngleZ,
        },
        5: {
            x: offsetAngle + offsetAngleX,
            y: -90 + 360 + offsetAngleY,
            z: 360 + offsetAngleZ,
        },
        6: {
            x: -180 + 360 + offsetAngleX,
            y: offsetAngle + offsetAngleY,
            z: 180 + 360 + offsetAngleZ + offsetAngle2D,
        },
    };

    return index >= 1 ? positions[index] : positions[1];
}

function Dice3DTemplate({ textures, styles }) {
    const {
        _3dface,
        _3dface__front,
        _3dface__top,
        _3dface__front__inner,
        _3dface__top__inner,
        _3dface__bottom__inner,
        _3dface__bottom,
        _3dface__left,
        _3dface__right,
        _3dface__back,
        _3dface_left_inner,
        _3dface__right__inner,
        _3dface__back__inner,
        inner,
    } = styles;

    return (
        <React.Fragment>
            <div className={cn(inner, "classic", _3dface__front__inner)} />
            <div className={cn(_3dface, _3dface__front, "classic")}>
                {textures[0]}
            </div>
            <div className={cn(inner, "classic", _3dface__top__inner)} />
            <div className={cn(_3dface, _3dface__top, "classic")}>
                {textures[1]}
            </div>
            <div className={cn(inner, "classic", _3dface__bottom__inner)} />
            <div className={cn(_3dface, _3dface__bottom, "classic")}>
                {textures[2]}
            </div>
            <div className={cn(inner, "classic", _3dface_left_inner)} />
            <div className={cn(_3dface, _3dface__left, "classic")}>
                {textures[3]}
            </div>
            <div className={cn(inner, "classic", _3dface__right__inner)} />
            <div className={cn(_3dface, _3dface__right)}>{textures[4]}</div>
            <div className={cn(inner, "classic", _3dface__back__inner)} />
            <div className={cn(_3dface, "classic", _3dface__back)}>
                {textures[5]}
            </div>
        </React.Fragment>
    );
}

const angles = [1080, 720, -720, -1080];

// TODO: add props types
export const Dice = memo((props: any) => {
    const { player, size, initGame, winner, rollAwaiting } = props;
    // const prefix = 123;
    // const prevIndex = 0;

    // const whiteFiguresIcons = useMemo(() => getWhiteFiguresIcons(size), [size]);
    // const blackFiguresIcons = useMemo(() => getBlackFiguresIcons(size), [size]);
    const whiteFiguresIcons = useMemo(() => getWhiteFiguresIcons(), [size]);
    const blackFiguresIcons = useMemo(() => getBlackFiguresIcons(), [size]);

    const styles = useStyles({
        cubeWidth: size,
        cubeHeight: size,
        cubeDepth: size,
        innerOffset: 3,
    });
    // const { index, noanimation, color } = props;

    //LEGACY
    const { index, prevIndex, prefix, noanimation, color } = props;
    const { x: X = 0, y: Y = 0, z: Z = 0, p: P = 400 } = props;
    // const { x: X = 0, y: Y = 0, z: Z = 0, p: P = 1500 } = props;

    const isWhite = useMemo(() => color === "w", [color]);
    const refAnimation = useRef<HTMLDivElement>(null);

    const [jumpStyle, setJumpStyle] = useState<Record<string, unknown>>(
        () => ({})
    );
    const [jumpStyleClasses, setJumpStyleClasses] = useState<
        string | undefined
    >();
    const [rotateStyle, setRotateStyle] = useState<
        Record<string, unknown> | undefined
    >();

    useLayoutEffect(() => {
        if (!noanimation) {
            setJumpStyle({});
            setRotateStyle(undefined);
            setJumpStyleClasses(undefined);
        } else {
            const { x, y, z } = getPosition(
                !!index === false || index === -1 ? 1 : index
            );

            const styles = {
                init: {
                    transform: `rotateX(${x}deg) rotateY(${y}deg) rotateZ(${z}deg)`,
                    "-webkit-transform": `rotateX(${x}deg) rotateY(${y}deg) rotateZ(${z}deg)`,
                },
            };

            setRotateStyle(styles.init);
        }

        //eslint-disable-next-line
    }, [index, color, prefix]);

    useEffect(() => {
        const duration = NumberHelper.random(700, 1500);
        let timeoutId;
        if (jumpStyleClasses == null) {
            timeoutId = setTimeout(() => {
                setJumpStyle({
                    animationDuration: duration + "ms",
                });
                setJumpStyleClasses(styles.space3dAnimation);
            }, 100);
        }
        return clearTimeout(timeoutId);
    }, [jumpStyleClasses]);

    useLayoutEffect(() => {
        let initTimeout;
        let playerTimeout;
        if (initGame) return;
        if (rotateStyle == null) {
            const {
                x: prevX,
                y: prevY,
                z: prevZ,
            } = getPosition(prevIndex ? prevIndex : 1);
            const duration = NumberHelper.random(700, 1500);

            const { x, y, z } = getPosition(
                !!index === false || index === -1 ? 1 : index
            );
            const currentDuration = duration / 2 || 500;
            const offset = ArrayHelper.pickRandom(angles);

            const styles = {
                rotator: {
                    transform: `rotateX(${x + X + offset}deg) rotateY(${
                        y + Y + offset
                    }deg) rotateZ(${z + Z + offset}deg)`,
                    "-webkit-transform": `rotateX(${
                        x + X + offset
                    }deg) rotateY(${y + Y + offset}deg) rotateZ(${
                        z + Z + offset
                    }deg)`,
                    transition: `all ${currentDuration}ms`,
                },
                init: {
                    transform: `rotateX(${prevX}deg) rotateY(${prevY}deg) rotateZ(${prevZ}deg)`,
                    "-webkit-transform": `rotateX(${prevX}deg) rotateY(${prevY}deg) rotateZ(${prevZ}deg)`,
                },
            };

            if (winner || initGame) return;
            setRotateStyle(styles.init);
            initTimeout = setTimeout(() => {
                setRotateStyle(styles.rotator);
            }, 100);

            playerTimeout = setTimeout(() => {
                if (player && !noanimation) player();
            }, currentDuration);
        }

        //eslint-disable-next-line
    }, [rotateStyle, initGame]);

    return (
        <div className={cn(styles.diceContainer)}>
            <div
                ref={refAnimation}
                className={cn(styles.space3d, jumpStyleClasses, {
                    rollAwaiting: rollAwaiting,
                })}
                // its logical for perspective. Wen we use this our div make bigger than without
                // beacause properties perspective 400px make this item like we see inside him,
                // as result perspective distorts view and content seems more than main div
                // for fix this problem i added dynamic perspective from 400, when dices are rolling to 1600 when dices will have been stop
                // u can see/find its in space3dAnimation
                // style={{ perspective: P, ...jumpStyle }}
                style={{ ...jumpStyle }}
            >
                <div
                    className={cn(styles._3dbox, {
                        // THis is animation before first roll in Game
                        // initGame1: initGame && prevIndex === 0 && !winner,
                        // initGame2: initGame && prevIndex === 1 && !winner,
                        // initGame3: initGame && prevIndex === 2 && !winner,
                    })}
                    style={rotateStyle}
                >
                    <Dice3DTemplate
                        styles={styles}
                        textures={
                            isWhite ? whiteFiguresIcons : blackFiguresIcons
                        }
                    />
                </div>
            </div>
        </div>
    );
});
