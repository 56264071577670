import { makeStyles } from '@mui/styles';

import theme from 'src/theme';
import { MAX_CHANGE_SCREEN_WIDTH } from 'src/pages/game-new/constants';

export const useStyles = makeStyles({
    '@global': {
        '@keyframes skeletonGradient': {
            '0%': {
                backgroundPosition: '0% 50%',
            },
            '100%': {
                backgroundPosition: '100% 50%',
            },
        },
    },

    stickersListBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '80px',
        height: '80px',
        justifyContent: 'center',
        alignItems: 'center',
        '&.mobileType': {
            width: 'calc(100% - 55px)',
        },
    },

    stickersList: {
        display: 'flex',
        width: '100%',
        height: '100%',
        //gridGap: '5px',
        overflow: 'scroll',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '0!important',
        },
        [theme.breakpoints.down(340)]: {
            gridGap: '4px',
            width: 'inherit',
        },
    },

    stickersTitle: {
        margin: 0,
        padding: 0,
        color: 'white',
        textAlign: 'center',
        fontSize: 12,
        '&.pulseLoader': {
            display: 'block',
            height: '12px',
            width: '100%',
        },
    },

    stickersImg: {
        opacity: 0,
        width: '75px',
        height: '75px',
        transition: 'ease-in-out opacity 300ms',
        '&.isLoaded': {
            opacity: 1,
            cursor: 'pointer',
            width: '70px',
            height: '70px',
            transition: 'transform 0.3s ease-in-out',
            '@media (hover: hover)': {
                '&:hover': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.3s ease-in-out',
                },
            },
            [theme.breakpoints.between('lg', MAX_CHANGE_SCREEN_WIDTH)]: {
                maxHeight: 40,
                width: '40px',
                height: '40px',
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                maxHeight: 35,
                width: '35px',
                height: '35px',
            },
        },
    },

    stickersSkeleton: {
        opacity: 0,
        display: 'none',
        '&.loadingSkeleton': {
            opacity: 1,
            width: '45px',
            height: '45px',
            transform: 'scale(1)',
            border: 'white 1px solid',
            display: 'block',
            borderRadius: '50%',
            backgroundSize: '1000% 100%',
            background:
                'linear-gradient(60deg, rgba(160,160,160,1) 20%, rgba(255,255,255,1) 25%, rgba(160,160,160,1) 30%, rgba(160,160,160,1) 70%, rgba(255,255,255,1) 75%, rgba(160,160,160,1) 80%)',
            animationName: 'skeletonGradient',
            animationDelay: '0s',
            animationIterationCount: 'infinite',
            animationFillMode: 'forwards',
            animationTimingFunction: 'linear',
            animationDuration: '1500ms',
            [theme.breakpoints.between('lg', MAX_CHANGE_SCREEN_WIDTH)]: {
                width: '40px',
                height: '40px',
            },
        },
    },
});
