import { makeStyles } from "@mui/styles";

import theme from "src/theme";

import {
    MAX_XL_TABLET,
    deskGamePagePaddings,
} from "src/pages/game-new2/constants";
import {
    getDesktopGameScreenSize,
    EParamsRatio,
} from "src/utils/calcDeskTopScreenSize";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes gameContainer": {
            "0%": {
                opacity: 0,
                transform: "scale(4)",
            },
            "100%": {
                opacity: 1,
                transform: "scale(1)",
            },
        },
    },

    gameContainerAppearAnimate: {
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "scrollbar-width": "none",
        "@media screen and (min-width: 1024px)": {
            height: "calc(100dvh - 120px)",
            //===
            // paddingLeft: "72px",
            // paddingLeft: "24px",
            // paddingRight: "24px",
        },
        "@media screen and (min-width: 280px)": {
            height: "100%",
        },
    },

    gameContainer: {
        // maxWidth: "1584px",
        // maxWidth: "100%",
        // maxHeight: "100%",
        // margin: "10px auto 0",
        // animationDelay: "500ms",
        // animationIterationCount: 1,
        // animationFillMode: "forwards",
        // animationDuration: "400ms",
        // animationName: 'gameContainer',
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        // maxHeight: "100%",
        margin: 0,
        minWidth: MAX_XL_TABLET,
        //1. There is max height with our paddings for desktop 1920 and 912 - data from figma design
        maxHeight: `calc(${getDesktopGameScreenSize(
            EParamsRatio.maxHeight,
            1920,
            912
            //1.25% 24px design padding / 1920 and 1.71% - 12px height padding / 1080
        )} - ${(window.innerWidth * 1.25 * 2) / 100}px + ${
            (window.innerHeight * 1.71 * 2) / 100
        }px) `,
        padding: `${(window.innerHeight * 1.71) / 100}px 0px`,
        borderRadius: "24px",
        marginTop: "12px",

        "&.isMultiTable": {
            marginTop: "0px",
            minWidth: 0,
            height: "100%",
            margin: "0",
            display: "flex",
            //add this proper
            alignItems: "center",
            position: "relative",
        },
        "&.isTwoGameTable": {
            marginTop: "0px",
            minWidth: 0,
            margin: "30px auto 0",
        },

        [theme.breakpoints.between("sm", MAX_XL_TABLET)]: {
            minWidth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            padding: 0,
            margin: 0,
            maxHeight: `calc(100dvh - 48px)`,
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            minHeight: "100%",
            padding: 0,
            margin: 0,
            maxHeight: `calc(100dvh - 48px)`,
        },
    },
});
