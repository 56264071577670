// Кнопки запуска анимации

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { Box, useMediaQuery } from "@mui/material";

import { useWinOrLostStatus } from "src/pages/game-new2/hooks/useWinOrLostStatus";
import useChangeTheme from "src/hooks/useChangeTheme";
import { usePlayer } from "src/sounds/use-player";

import mainGoldIconRedesign from "src/assets/icons/chessboardPage/redesignCoinGold.png";
import chipsStack from "src/assets/icons/gameChips/chipsStack.svg";
// import coin from "src/assets/icons/coin.svg";
import coin from "src/assets/icons/chip.svg";

import mainFreeIconRedesign from "src/assets/icons/chessboardPage/redesignCoinSilver.png";
import freeCoin from "src/assets/icons/freeCoin.png";
import useStores from "src/hooks/useStores";
import {
    UserBalanceType,
    UserBalanceTypeNew,
    ChessboardType,
} from "src/store/models";

import "./styles.css";
import useStyles from "./styles";

interface ICoinAnimationContainer {
    balanceType?: UserBalanceType | UserBalanceTypeNew;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    chessboardType: string;
    amountProp: any;
    isGamePage?: any;
    type?: any;
}

const CoinRiseComponent = ({
    balanceType,
    isMultiTable,
    isTwoGameTable,
    isGamePage,
    amountProp,
    chessboardType,
    type,
}: ICoinAnimationContainer) => {
    const { resultData, isWon } = useWinOrLostStatus();
    const { gameStore } = useStores();
    const appearanceTheme = useChangeTheme();
    const soundPlayer = usePlayer();

    const vertical = false;
    const horizontal = false;
    const animationLatency = 0;
    const transitionLatency = 0;

    const styles = useStyles({
        vertical,
        horizontal,
        animationLatency,
        transitionLatency,
    });

    useEffect(() => {
        if (!!amountProp && !isWon) {
            soundPlayer("raise");
        }
    }, [amountProp]);

    return (
        <>
            <Box className={cn(styles.potToTopRise, {})}>
                <div
                    className={cn(styles.coinBoxMainRise, appearanceTheme, {
                        isMultiTable: isMultiTable,
                    })}
                >
                    <div className={cn(styles.coinBoxRise, "mainIcon")}>
                        <img
                            className={cn(styles.coinImageRise)}
                            src={chipsStack}
                            alt="mainCoinRise"
                        />
                    </div>
                </div>
                <span
                    // className={
                    //     balanceType === UserBalanceType.play
                    //         ? cn(styles.bankAmountRise, appearanceTheme)
                    //         : cn(styles.bankFreeAmountRise, appearanceTheme)
                    // }
                    className={cn(styles.bankFreeAmountRise, appearanceTheme)}
                >
                    {gameStore?.isReadyNew ? amountProp.toFixed(2) : "0"}
                </span>
            </Box>
        </>
    );
};

export default observer(CoinRiseComponent);
