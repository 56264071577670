import { useCallback, useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { usePlayer } from "src/sounds/use-player";
import useStores from "src/hooks/useStores";
import { ColorVariant } from "src/store/models";
import useWindowSize from "src/hooks/useWindowSize";

import DicePieces from "./dices/dicePieces";

import { useStyles } from "./styles";
export interface DiceAndX2ButtonsProps {
    rndPrefix: any;
    diceClickRnd: number;
    standOffColor?: any;
    myColor?: ColorVariant;
    opponentColor?: ColorVariant;
    viewMode?: boolean | undefined;
    isMultiTable?: boolean;
    isDesktop?: boolean;
    piecesSizeRef?: React.RefObject<HTMLDivElement>;
}
const DicesWrapper = ({
    rndPrefix,
    diceClickRnd,
    standOffColor,
    myColor,
    opponentColor,
    viewMode,
    isMultiTable,
    isDesktop,
    piecesSizeRef,
}: DiceAndX2ButtonsProps) => {
    const styles = useStyles();
    const { gameStore, authStore, viewHistoryGameStore } = useStores();
    const { width, height } = useWindowSize();
    const piecesDesktopSize = gameStore.piecesSize;
    const isMyMove = gameStore.isMyMove;

    const piecesSize = useMemo(() => {
        return viewMode ? viewHistoryGameStore.piecesSize : piecesDesktopSize;
    }, [viewMode, width, height]);

    const [dices, setDices] = useState<any>(null);

    useEffect(() => {
        setDices(gameStore.gameState.getCurrentDices());
    }, [gameStore?.currentGameNew?.fen]);

    const fen = gameStore?.currentGameNew?.fen;
    const actualWidth = window.innerWidth;

    const player = usePlayer();
    const diceSoundEnabled = authStore.generalGameSettings?.diceSound ?? false;
    // const playDiceSound = useCallback(() => {
    //     gameStore.moveSound &&
    //         isMyMove &&
    //         (() => {
    //             player("diceSoundPlay");
    //             gameStore.setMoveSound(false);
    //         })();
    //     !isMyMove && player("diceSoundPlay");
    // }, []);

    const playDiceSound = useCallback(() => {
        player("diceSoundPlay");
    }, []);
    // const diceColor = gameStore.gameState.getDiceColor();

    const diceColor = useMemo(() => {
        if (
            !gameStore.currentGameNew?.movesHistory &&
            myColor &&
            opponentColor
        ) {
            return isMyMove ? myColor : opponentColor;
        }
        if (!fen) return ColorVariant.white;
        const fenEndMove = fen.slice(-3);
        return gameStore.getRolledDiceColorNew(fenEndMove);
    }, [fen, gameStore.currentGameNew?.movesHistory, opponentColor, myColor]);

    return (
        <>
            {actualWidth > 450 ? (
                <div className={cn(styles.dicesWrapper, { isMultiTable })}>
                    {/* <DicePieces
                        size={piecesSize}
                        figures={true}
                        prefix={rndPrefix}
                        player={diceSoundEnabled && playDiceSound}
                        // А вот тут мы уже отдаем эти цифры на рендер и имеем фигуры
                        lastDices={gameStore.gameState.getLastDicesSet()}
                        legalPieces={gameStore.gameState.legalPieces}
                        currentPlayerDice={gameStore.gameState.getCurrentDices()}
                        activeColor={diceColor}
                        diceClickRnd={diceClickRnd}
                    /> */}
                    <DicePieces
                        size={piecesSize}
                        figures={true}
                        prefix={rndPrefix}
                        // player={diceSoundEnabled && playDiceSound}
                        player={playDiceSound}
                        // А вот тут мы уже отдаем эти цифры на рендер и имеем фигуры
                        lastDices={[-1, -1, -1]}
                        // lastDices={gameStore.gameState.getLastDicesSet()}
                        legalPieces={gameStore.stateNew.legalPieces}
                        currentPlayerDice={dices}
                        activeColor={diceColor}
                        diceClickRnd={diceClickRnd}
                    />
                </div>
            ) : (
                <div
                    className={styles.dicesWrapper}
                    // style={{
                    //     justifyContent: "space-evenly",
                    // }}
                >
                    <DicePieces
                        size={piecesSize}
                        figures={true}
                        prefix={rndPrefix}
                        // player={diceSoundEnabled && playDiceSound}
                        player={playDiceSound}
                        // А вот тут мы уже отдаем эти цифры на рендер и имеем фигуры
                        lastDices={[-1, -1, -1]}
                        // legalPieces={[]}
                        legalPieces={gameStore.stateNew.legalPieces}
                        currentPlayerDice={dices}
                        activeColor={diceColor}
                        diceClickRnd={diceClickRnd}
                    />
                </div>
            )}
        </>
    );
};
export default observer(DicesWrapper);
