import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { ColorVariant } from "src/store/models";
import useStores from "src/hooks/useStores";
import FenParser from "src/store/gameStore/utils/parser";
import FenHelper from "src/store/gameStore/utils/helper";
import getOppositeColor from "src/utils/oppositeColor";

import { Dice } from "./dice";

const numToChar: InumToChar = {
    1: "P", // пешка
    2: "N", // лошадь
    3: "B", // слон
    4: "R", // тура
    5: "Q", // королева
    6: "K", // король
};

const diceStateValues: IDiceStateValues = {
    activePiece: "activePiece",
    nonActivePiece: "nonActivePiece",
    zero: 0,
    three: 3,
};
const { activePiece, nonActivePiece, zero, three } = diceStateValues;

interface DicePiecesProps {
    size: number;
    player: (() => void) | false;
    legalPieces: string[];
    currentPlayerDice: { active: boolean; figure: number }[];
    activeColor: ColorVariant;
    prefix: string;
    diceClickRnd;
    lastDices;
    figures: boolean;
}

interface IDiceStateValues {
    activePiece: string | any;
    nonActivePiece: string | any;
    zero: number;
    three: number;
}

enum EDiceClassName {
    activePiece = "activePiece",
    nonActivePiece = "nonActivePiece",
}

interface IDiceRedyToRender {
    class: EDiceClassName;
    index: number;
    figure: number;
    active: boolean;
}

interface InumToChar {
    [key: number]: string;
}

const DicePieces = (props: DicePiecesProps) => {
    const {
        size,
        player,
        legalPieces,
        currentPlayerDice,
        activeColor,
        prefix,
        lastDices,
        figures,
    } = props;
    const { gameStore } = useStores();
    const winner = gameStore.currentGameNew?.winner;
    const initGame = !gameStore.currentGameNew?.movesHistory;
    const baseState = Array<IDiceRedyToRender>(3).fill({
        class: EDiceClassName.nonActivePiece,
        index: 1,
        figure: 1,
        active: false,
    }) as [IDiceRedyToRender, IDiceRedyToRender, IDiceRedyToRender];

    const [intermediateDice, setIntermediateDiseState] = useState<
        IDiceRedyToRender[]
    >([]);
    const [diceState, setDiceState] = useState<IDiceRedyToRender[]>(baseState);
    const [diceColorLocal, setDiceColorLocal] = useState<ColorVariant>(
        ColorVariant.white
    );
    const [noAnimation, setNoAnimation] = useState<boolean>(false);
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const rollAwaiting = gameStore.rollAwaiting;
    const parse = new FenParser(FenHelper);
    const moveFinished = !gameStore.gameState.legalPieces.length;
    const history = gameStore.gameState.history;
    const leftColor = getOppositeColor(gameStore.gameState.getMyColor());
    const moves = useMemo(() => {
        return parse.diceMessagesFromHistory(
            history as any,
            leftColor,
            [],
            moveFinished
        );
    }, [history.length, leftColor, moveFinished]);
    const rolledPieces = gameStore.stateNew.rolledFigures;

    const [lastRolledDiceColor, setLastRolledDiceColor] =
        useState<ColorVariant | null>(
            () =>
                parse.getWhoLastRollsColor(gameStore.gameState.history) || null
        );

    useLayoutEffect(() => {
        const color = parse.getWhoLastRollsColor(gameStore.gameState.history);
        setLastRolledDiceColor(color || null);
    }, [gameStore.gameState.history.length]);

    const lastMoveAreUnAvailable = useMemo(() => {
        const lastMove = moves[moves.length - 1];
        if (!lastMove) return;
        const allMoveAreUnAvailable = lastMove.pieces.every(
            (piece) => piece.moved && !piece.move
        );
        return allMoveAreUnAvailable;
    }, [moves]);

    // useEffect(() => {
    //     if (isFirstRender) {
    //         setIsFirstRender(false);
    //         return;
    //     }
    //     if (!lastMoveAreUnAvailable) return;
    //     const result = lastDices.map((figure, i) => {
    //         return {
    //             class: activePiece,
    //             index: figure,
    //             figure: figure,
    //             active: false,
    //         };
    //     });
    //     setDiceState(result);
    //     delayDiceState(currentPlayerDice, legalPieces, 2000);
    // }, [lastRolledDiceColor]);

    // const delayDiceState = (
    //     currentDice: { active: boolean; figure: number }[],
    //     legalPieces: any,
    //     duration: number = 1500
    // ): void => {
    //     setTimeout(() => {
    //         const result = currentDice.map((p, i) => {
    //             const activeClass: "activePiece" | "nonActivePiece" =
    //                 p.active &&
    //                 legalPieces.find(
    //                     (lp) => lp.toUpperCase() === numToChar[p.figure]
    //                 )
    //                     ? activePiece
    //                     : nonActivePiece;

    //             return {
    //                 class: activeClass,
    //                 index: i,
    //                 figure: p.figure,
    //                 active: p.active,
    //             };
    //         });
    //         setIntermediateDiseState(result);
    //     }, duration);
    // };

    // useEffect(() => {
    //     const activeDices = currentPlayerDice.filter((t) => t.active).length;
    //     const currentDiceRollColor = lastRolledDiceColor || activeColor;
    //     if (activeDices === three && diceColorLocal !== currentDiceRollColor) {
    //         setDiceColorLocal(currentDiceRollColor);
    //         setNoAnimation(false);
    //         const activeClass: "activePiece" = "activePiece";
    //         const result = currentPlayerDice.map((p, i) => {
    //             return {
    //                 class: activeClass,
    //                 index: i,
    //                 figure: p.figure,
    //                 active: p.active,
    //             };
    //         });
    //         setDiceState(result);
    //         delayDiceState(currentPlayerDice, legalPieces);
    //     }
    //     //2.3 ActColor DicePieces b diceColorLocal b lastRolledDiceColor: b currentDiceRollColor: b
    //     if (
    //         activeDices === three &&
    //         activeColor === lastRolledDiceColor &&
    //         activeColor === activeColor
    //     ) {
    //         setDiceColorLocal(currentDiceRollColor);
    //         setNoAnimation(false);
    //     }
    //     if (activeDices > zero && activeDices < three && diceState.length > 0) {
    //         const figureState = currentPlayerDice.reduce((memo, item) => {
    //             memo[item.figure] = (memo[item.figure] || zero) + 1;
    //             return memo;
    //         }, {});
    //         const newDiceState = diceState.map((dice, index) => {
    //             if (figureState[dice.figure]) {
    //                 const isActive =
    //                     !!(
    //                         currentPlayerDice[index].active &&
    //                         legalPieces.find(
    //                             (lp) =>
    //                                 lp.toUpperCase() === numToChar[dice.figure]
    //                         )
    //                     ) || false;
    //                 figureState[dice.figure]--;
    //                 return {
    //                     ...dice,
    //                     active: isActive,
    //                     class: isActive ? activePiece : nonActivePiece,
    //                 };
    //             }

    //             return {
    //                 ...dice,
    //                 active: false,
    //                 class: nonActivePiece,
    //             };
    //         });
    //         //@ts-ignore
    //         setDiceState(newDiceState);
    //         setNoAnimation(true);
    //     }
    //     if (activeDices === zero && !lastMoveAreUnAvailable) {
    //         const result = lastDices.map((figure, i) => {
    //             return {
    //                 class: nonActivePiece,
    //                 index: figure,
    //                 figure: figure,
    //                 active: false,
    //             };
    //         });
    //         setDiceState(result);
    //         setNoAnimation(false);
    //     }
    // }, [currentPlayerDice, lastRolledDiceColor, activeColor]);

    // useEffect(() => {
    //     if (intermediateDice.length !== zero && !isFirstRender) {
    //         setDiceState(intermediateDice);
    //     }
    // }, [intermediateDice]);

    useEffect(() => {
        if (!currentPlayerDice || !rolledPieces) return;
        const isAcitve = [false, false, false].slice(0, 4).reverse();
        for (const fig of currentPlayerDice) {
            const id = rolledPieces.findIndex(
                (item, id) =>
                    item.figure === fig.figure && isAcitve[id] === false
            );
            isAcitve[id] = true;
        }
        const unitedRolls = rolledPieces.map((rolledDice) => {
            const rolledFigure = rolledDice.figure;
            for (let index = 0; index < currentPlayerDice.length; index++) {
                const element = currentPlayerDice[index];
                if (rolledFigure === element.figure) {
                    return element;
                }
            }
            return {
                figure: rolledDice.figure,
                active: rolledDice.active,
                status: false,
            };
        });
        if (!unitedRolls) return;
        const result = unitedRolls.map((p, i) => {
            const activeClass:
                | EDiceClassName.activePiece
                | EDiceClassName.nonActivePiece =
                isAcitve[i] &&
                !winner &&
                legalPieces.find(
                    (lp) => lp.toUpperCase() === numToChar[p.figure]
                )
                    ? activePiece
                    : nonActivePiece;

            return {
                class: activeClass,
                index: i,
                figure: p.figure,
                active: isAcitve[i],
            };
        });
        setDiceColorLocal(activeColor);
        setDiceState(result);
    }, [currentPlayerDice, winner]);

    return (
        <React.Fragment>
            {diceState.map((dice, i) => {
                return (
                    <div
                        key={i}
                        className={cn("dicePiece", diceState[i].class)}
                        style={{
                            // margin: "0 5px"
                            height: "100%",
                            aspectRatio: "1 / 1",
                        }}
                    >
                        <Dice
                            size={size}
                            prefix={prefix}
                            figures={figures}
                            index={dice.figure}
                            prevIndex={dice.index}
                            color={diceColorLocal}
                            noanimation={noAnimation}
                            player={player}
                            winner={winner}
                            initGame={initGame}
                            rollAwaiting={rollAwaiting}
                        />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default observer(DicePieces);
