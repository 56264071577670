import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import cn from "classnames";

import { infoService } from "src/service/services";
import { CountdownCircular } from "src/components/countDownCircular";
import { useLocale } from "src/providers/LocaleProvider";
import {
    IPlayer,
    GameFinishReason,
    UserBalanceType,
    EGameRematchStatus,
} from "src/store/models";
import paths from "src/consts/paths";
import { Button } from "src/shared/ui/Button";
import { ColorVariant, RankType } from "src/store/models";

import { useWinOrLostStatus } from "src/pages/game-new2/hooks/useWinOrLostStatus";
import useStores from "../../../../hooks/useStores";
import { useChessBoardProps } from "../../hooks/useChessBoardProps";
import useStyles from "./style";

import WinDialog from "./components/win";
import LostDialog from "./components/lost";
import DrawDialog from "./components/draw";

interface IWinOrLostAlert {
    isTabletMobile?: boolean | undefined;
    // opponent?: IPlayer | undefined | null;
    opponent?: any;
}

const WinOrLostAlert = ({
    isTabletMobile,
    opponent: opponentProps,
}: IWinOrLostAlert) => {
    const history = useHistory();
    const styles = useStyles();
    const { gameStore, achievementsStatStore, authStore } = useStores();
    const me = authStore?.currentUser;
    const { resultData, winCauses, lossCauses, setting, isWon } =
        useWinOrLostStatus();
    const rematchInfo = gameStore.dialogs.rematchData;
    const winner = gameStore.currentGameNew?.winner;

    const { isMultiTable, onLobbyClose } = useChessBoardProps();
    const {
        game: {
            chat: { unlockedUserMessage },
        },
        header: { lobby },
        game: {
            dialogs: {
                lost: { approve },
            },
        },
    } = useLocale();

    const opponent = {
        avatar: "string",
        color: ColorVariant.black,
        id: "string",
        name: "string",
        rating: 1,
        score: 1,
        winInGames: 1,
        isSystemAvatar: false,
        rank: { title: RankType.pawn3 },
        status: "string",
        pathCompressed: "string",
        ...opponentProps,
    };

    const AmIblockedFriend = achievementsStatStore.getMyCurrentOpponent(
        opponent?.id
    )?.isGamePlayBlocked;

    const unLockPlayerInfo = () => {
        infoService.sendInfo(unlockedUserMessage("Opponent Unlocked"));
    };

    const shouldUnlockOpponent = async (
        opponent: IPlayer | undefined | null
    ) => {
        if (AmIblockedFriend && opponent?.id) {
            await achievementsStatStore.setOpponentSettings({
                user: opponent?.id,
                isGamePlayBlocked: false,
            });
            !achievementsStatStore.isLoadedOpponent && unLockPlayerInfo();
        }
        gameStore.dialogs.requestRematch();
    };

    const currentTimeInMilliseconds = Date.now();
    const rematchEndInMilliseconds = moment(rematchInfo.rematchEnd).valueOf();
    const gameDieTimeInMilliseconds = moment(rematchInfo.gameDieTime).valueOf();

    useEffect(() => {
        if (
            rematchInfo.rematchStatus === EGameRematchStatus.ACCEPTED &&
            rematchInfo.nextMatch
        ) {
            history.push(`/game/${rematchInfo.nextMatch}`);
        }
    }, [rematchInfo.rematchStatus]);

    const [rematchOff, setRematchOff] = useState(false);

    useMemo(() => {
        const rematchOffTime = 10000;
        const timer = setTimeout(() => {
            setRematchOff(true);
        }, rematchOffTime);
        return () => clearTimeout(timer);
    }, [rematchEndInMilliseconds]);

    // Temporary turn off
    // if (!setting) return null;
    // if (resultData.cause === GameFinishReason.STANDOFF) {
    //     return <DrawDialog />;
    // }

    //Return Temporary

    if (rematchInfo.rematchStatus !== EGameRematchStatus.SHOW_MODAL)
        return null;

    return (
        <Box
            component="div"
            className={cn(styles.rematchAlertContainer, "winOrLostAlert")}
        >
            {currentTimeInMilliseconds < rematchEndInMilliseconds ? (
                <>
                    <Button
                        text={approve("Rematch")}
                        color="green"
                        // disabled={rematchOff}
                        variant="contained"
                        onClick={() =>
                            gameStore.rematchPropose(
                                gameStore?.currentGameNew?._id
                            )
                        }
                        className={cn(styles.buttonRequest)}
                    />
                    <Button
                        text={lobby("Lobby")}
                        color="red"
                        variant="outlined"
                        onClick={() => {
                            history.push(paths.diceChessLobby);
                        }}
                        className={cn(styles.buttonRequest)}
                    />
                </>
            ) : (
                <>
                    <p style={{ color: "white" }}>Time is Over</p>
                </>
            )}
        </Box>
    );

    if (isWon) {
        return (
            <WinDialog
                // Turn off temporary
                //   amount={(
                //     gameStore.gameState.bank *
                //     (1 - 2 * setting.rake)
                // ).toFixed(2)}
                //cause={resultData.cause}
                // message={winCauses[resultData.cause]}
                message={"You Win!"}
                cause={GameFinishReason.STANDOFF}
                amount={gameStore.currentGameNew?.bank.toString() || "2"}
                onApprove={() => {
                    shouldUnlockOpponent(opponent);
                }}
                onCancel={() => {
                    if (isMultiTable) {
                        onLobbyClose?.();
                        return;
                    }
                    gameStore.dialogs.rejectRematch();
                    gameStore.currentGame?.balanceType === UserBalanceType.play
                        ? history.push(paths.diceChessLobby)
                        : history.push(paths.lobbyFreePlay);
                }}
                isXlTablet={isTabletMobile}
                isMultiTable={isMultiTable}
            />
        );
    }

    return (
        <LostDialog
            amount={gameStore.gameState.userBet.toFixed(2)}
            // Turn off temporary
            // cause={resultData.cause}
            // message={lossCauses[resultData.cause]}
            message={"You Lose!"}
            cause={GameFinishReason.STANDOFF}
            onApprove={() => {
                shouldUnlockOpponent(opponent);
            }}
            onCancel={() => {
                if (isMultiTable) {
                    onLobbyClose?.();
                    return;
                }
                gameStore.dialogs.rejectRematch();
                gameStore.currentGame?.balanceType === UserBalanceType.play
                    ? history.push(paths.diceChessLobby)
                    : history.push(paths.lobbyFreePlay);
            }}
            isXlTablet={isTabletMobile}
            isMultiTable={isMultiTable}
        />
    );
};

export default observer(WinOrLostAlert);
