import { action, makeAutoObservable, computed, reaction } from "mobx";
import isEqual from "lodash/isEqual";

import {
    ITopPlayers,
    ITopPlayersNew,
    IPlayer,
    IBlockedSettings,
    IFriend,
    ISearchedUser,
    IPlayerOpponent,
} from "src/store/models";
import { rootStore } from "src/hooks/useStores";
import { infoService } from "src/service/services";
import { getTopPlayers } from "src/service/api/tournaments";
import {
    setOpponentSettings,
    myOpponentsSettings,
    meOpponentsSettings,
} from "src/service/api/opponentSettings";
import { IResponseTopPlayers } from "src/service/api/tournaments/requestResponses";

import { IOpponentSettingsAxios } from "./../service/api/opponentSettings/requestResponses";

import { RootStore } from "./index";

export enum SortOrder {
    desc = 0,
    asc = 1,
}

export interface ILocaleError {
    defaultValue: string;
    label: string;
}

export interface ICurrentMyOpponentsSettings {
    notes: string;
    color: string;
    isChatBlocked: boolean;
    isGamePlayBlocked: boolean;
    user: string;
}

export interface ICurrentMeOpponentsSettings {
    isChatBlocked: boolean;
    isGamePlayBlocked: boolean;
    user: string;
}

export interface IMyOpponentsSettings {
    [userId: string]: ICurrentMyOpponentsSettings;
}

export interface IMeOpponentsSettings {
    [userId: string]: ICurrentMeOpponentsSettings;
}

export class AchievementsStatStore {
    rootStore: RootStore;
    topPlayers: ITopPlayers[] | null;
    myPlace: number | null;
    isLoaded: boolean = false;
    isLoadedOpponent: boolean = false;
    isLoadedRating: boolean = false;
    topPlayersToggle: boolean | null = null;
    errorOccured: boolean = false;
    myOpponentsSettingsCollection: IMyOpponentsSettings;
    meOpponentsSettingsCollection: IMeOpponentsSettings;
    playerSettingsInfo:
        | IPlayer
        | IFriend
        | ISearchedUser
        | IPlayerOpponent
        | null = null;
    //===
    topPlayersNew: ITopPlayersNew[] | null = null;
    topPlayersToggleNew: boolean | null = null;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        reaction(
            () => this.rootStore.authStore.isVisibleWebApp,
            (now, prev) => {
                // const isTournamentPage = location.pathname.includes(
                //     `${paths.tournaments}`
                // );
                // if (
                //     !prev &&
                //     now &&
                //     this.currentTournamentGame &&
                //     isTournamentPage
                // ) {
                //     window.location.reload();
                // }
            }
        );
    }

    @action
    setIsErrorOccured(isLoaded: boolean) {
        this.errorOccured = isLoaded;
    }

    @action
    setLoadedState(isLoaded: boolean) {
        this.isLoaded = isLoaded;
    }

    @action
    setLoadedStateRating(isLoadedRating: boolean) {
        this.isLoadedRating = isLoadedRating;
    }

    async getTopPlayers(params: IResponseTopPlayers) {
        try {
            this.setLoadedStateRating(false);
            const { data } = await getTopPlayers(params);
            this.setTopPlayers(data);
        } catch (e) {
            this.setIsErrorOccured(true);
        }
    }
    2;

    @action
    setTopPlayers(data: { users: ITopPlayers[]; place: number }) {
        if (!this.topPlayers) {
            this.topPlayers = data.users;
            this.myPlace = data.place;
            this.setLoadedStateRating(true);
            return;
        } else {
            this.topPlayers = [...this.topPlayers, ...data.users].slice(0, 100);
            this.setLoadedStateRating(true);
        }
    }

    @action
    setTopPlayersNew(topPlayersNew: ITopPlayersNew[]) {
        const _ = require("lodash");
        const existTopPlayersNew = this.topPlayersNew;
        const requiredUpdate = !_.isEqual(topPlayersNew, existTopPlayersNew);
        if (requiredUpdate) {
            this.topPlayersNew = topPlayersNew;
            this.setLoadedStateRating(true);
            return;
        }
    }

    @action
    resetTopPlayers() {
        this.topPlayers = null;
    }

    @action
    toggleTopPlayersInfo(status: null | boolean) {
        this.topPlayersToggle = status;
    }

    //
    @action
    toggleTopPlayersInfoNew(status: null | boolean) {
        this.topPlayersToggleNew = status;
    }

    @action
    resetTournaments() {
        this.topPlayers && (this.topPlayers = null);
        this.myPlace && (this.myPlace = null);
    }

    // =============== OPPONENT SETTINGS BLOCK ================

    @action
    setLoadedStateOpponent(isLoadedOpponent: boolean) {
        this.isLoadedOpponent = isLoadedOpponent;
    }

    async setOpponentSettings(params: IOpponentSettingsAxios) {
        if (!this.rootStore.authStore.currentUser || !params) return;
        this.setLoadedStateOpponent(true);
        const defaultValues = {
            isChatBlocked: false,
            isGamePlayBlocked: false,
            notes: "",
            color: "",
            user: "",
        };
        try {
            const res = await setOpponentSettings(params);

            //spread 3 times because we will could have : - not exist user without each values, some params exist but its not enought and finnaly updated params
            if (res.status === 200) {
                this.myOpponentsSettingsCollection[params.user] = {
                    ...defaultValues,
                    ...this.myOpponentsSettingsCollection[params.user],
                    ...params,
                };
            }
        } catch (error) {
            console.log("setOpponentSettings error:", error);
            this.myOpponentsSettingsCollection[params.user] = {
                ...defaultValues,
            };
            this.setLoadedStateOpponent(false);
        } finally {
            this.setLoadedStateOpponent(false);
        }
    }

    async myOpponentsSettings() {
        this.setLoadedStateOpponent(true);
        try {
            const response = await myOpponentsSettings();
            if (response.status === 200) {
                this.myOpponentsSettingsCollection = response.data;
            }
        } catch (e) {
            console.log("myOpponentsSettings Error:", e);
            this.setLoadedStateOpponent(false);
        } finally {
            this.setLoadedStateOpponent(false);
        }
    }

    async meOpponentsSettings() {
        this.setLoadedStateOpponent(true);
        try {
            const response = await meOpponentsSettings();
            if (response.status === 200) {
                this.meOpponentsSettingsCollection = response.data;
            }
        } catch (e) {
            console.log("meOpponentsSettings Error:", e);
            this.setLoadedStateOpponent(false);
        } finally {
            this.setLoadedStateOpponent(false);
        }
    }

    @computed
    getMyCurrentOpponent(id: string | undefined) {
        if (!id) {
            return {
                isChatBlocked: false,
                isGamePlayBlocked: false,
                notes: "",
                color: "",
                user: "",
            };
        }
        if (
            this.myOpponentsSettingsCollection &&
            this.myOpponentsSettingsCollection[id]
        ) {
            return this.myOpponentsSettingsCollection[id];
        } else {
            return {
                isChatBlocked: false,
                isGamePlayBlocked: false,
                notes: "",
                color: "",
                user: "",
            };
        }
    }

    @computed
    getMeSettingsCollections(id: string | undefined) {
        if (!id) {
            return {
                isChatBlocked: false,
                isGamePlayBlocked: false,
                user: "",
            };
        }
        if (
            this.meOpponentsSettingsCollection &&
            this.meOpponentsSettingsCollection[id]
        ) {
            return this.meOpponentsSettingsCollection[id];
        } else {
            return {
                isChatBlocked: false,
                isGamePlayBlocked: false,
                user: "",
            };
        }
    }

    @computed
    get showMeOpponentSettings() {
        return this.meOpponentsSettingsCollection;
    }

    @action
    putMeSettingCollections(meSettings: IBlockedSettings) {
        const defaultValues = {
            isChatBlocked: false,
            isGamePlayBlocked: false,
        };
        if (
            this.meOpponentsSettingsCollection &&
            this.meOpponentsSettingsCollection[meSettings.user]
        ) {
            this.meOpponentsSettingsCollection[meSettings.user] = {
                ...this.meOpponentsSettingsCollection[meSettings.user],
                ...meSettings,
            };
            return;
        } else {
            this.meOpponentsSettingsCollection = {
                [meSettings.user]: {
                    ...defaultValues,
                    ...meSettings,
                },
            };
        }
    }

    @action
    getPlayerSettingsModal(
        player: IPlayer | IFriend | ISearchedUser | IPlayerOpponent | null
    ) {
        if (this.rootStore.authStore?.currentUser?._id === player?.id) return;
        this.playerSettingsInfo = player;
    }
}

export default AchievementsStatStore;
