import { makeStyles } from "@mui/styles";
import theme from "src/theme";
import { appColors } from "src/consts/app-config";

interface IPosition {
    position: string;
}

interface IPositionTypes extends IPosition {
    left: string;
    right: string;
    leftTextColor: number;
    rightTextColor: number;
    leftBackground: string;
    rigthBackground: string;
    centerBackground: string;
}

const messagePosition: IPositionTypes = {
    position: "rtl",
    left: "left",
    right: "right",
    leftTextColor: 0.8,
    rightTextColor: 0.8,
    leftBackground: "rgba(255,255,255,0.07)",
    rigthBackground: "rgba(255,255,255,0.07)",
    centerBackground: "rgba(255,255,255,0.03)",
};
/*
 leftBackground: 'rgba(20,24,46,0.8)',
    rigthBackground: 'rgba(20,24,46,0.8)'
 */

const {
    left,
    right,
    leftTextColor,
    rightTextColor,
    leftBackground,
    rigthBackground,
    centerBackground,
} = messagePosition;

const ch = (
    position: string,
    left: string | number,
    center: string | number,
    right?: string | number
) => {
    let result;

    switch (position) {
        case "center":
            result = center;
            break;
        case "rtl":
            result = right;
            break;
    }

    return result === undefined ? left : result;
};

export const useStyles = makeStyles({
    "@global": {
        "@keyframes skeletonGradient": {
            "0%": {
                backgroundPosition: "75% 50%",
            },
            "100%": {
                backgroundPosition: "100% 50%",
            },
        },
    },

    messageRow: ({ position }: IPosition) => ({
        margin: 8,
        display: "flex",
        justifyContent: ch(position, "flex-start", "space-around", "flex-end"),
    }),
    messageBlock: ({ position }: IPosition) => ({
        minWidth: ch(position, "60%", "0"),
        maxWidth: "90%",
        padding: ch(position, "6px 10px", "6px 10px"),
        borderRadius: 8,
        backgroundColor: ch(
            position,
            leftBackground,
            centerBackground,
            rigthBackground, 
        ),
        borderBottomLeftRadius: ch(position, 0, 8, 8),
        borderBottomRightRadius: ch(position, 8, 8, 0),
        "&.deepBlack": {
            borderBottomLeftRadius: ch(position, 0, 4, 4),
            borderBottomRightRadius: ch(position, 4, 4, 0),

            background:
                position === "rtl"
                    ? appColors.bg200
                    : "rgba(245, 245, 245, 0.1)",
            "&.isLastElem": {
                border: "2px solid",
                borderColor:
                    position === "rtl"
                        ? "rgba(0, 186, 108, 0.2)"
                        : "rgba(186, 186, 108, 0.2)",
                boxShadow:
                    position === "rtl"
                        ? "0px 0px 10px 5px rgba(0, 186, 108, 0.2)"
                        : "0px 0px 10px 5px rgba(186, 186, 108, 0.2)",
            },

        },
        '&.isSticker': {
            background: 'none',
            boxShadow: 'none',
            padding: 0,
        },
    }),
    infoMessageText: ({ position }: IPosition) => ({
        margin: "0px 5px",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "14px",
        lineHeight: "21px",
        color: `rgb(255, 255, 255, ${ch(
            position,
            leftTextColor,
            rightTextColor
        )})`,
        wordWrap: "break-word",
        textAlign: ch(position, left, right),
        opacity: 0.4,
        [theme.breakpoints.up(1800)]: {
            fontSize: "18px",
            lineHeight: "21px",
        },
        "&.deepBlack": {},
    }),

    playerMessageText: ({ position }: IPosition) => ({
        margin: "0px 5px",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "21px",
        lineHeight: "21px",
        color: `rgb(255, 255, 255, ${ch(
            position,
            leftTextColor,
            rightTextColor
        )})`,
        minHeight: "24px",
        wordWrap: "break-word",
        textAlign: ch(position, left, right),
        [theme.breakpoints.up(1800)]: {
            fontSize: "28px",
            lineHeight: "28px",
        },
        "&.deepBlack": {
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "39px",
            color:
                position === "rtl"
                    ? "rgba(245, 245, 245, 0.9)"
                    : "rgba(245, 245, 245, 0.6)",
        },
        "&.isSticker": { 
            textAlign: "right",
        },
    }),

    messageTime: ({ position }: IPosition) => ({
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "15px",
        color: "rgb(255, 255, 255, 0.2)",
        margin: 0,
        marginTop: 4,
        display: ch(position, "flex", "none"),
        justifyContent: `flex-end`,
    }),

    stickerImageWrapper: {
        opacity: 0,
        transition: "ease-in-out opacity 300ms",
        maxHeight: 0,
        "&.isLoaded": {
            width: "150px",   
            height: "150px",  
            maxHeight: 150,
            opacity: 1,
            transition: "ease-in-out opacity 300ms",
        },
        "&.isSticker": {
            width: "150px",  
            height: "150px", 
        },
    },


    stckerSkeletonImageWrapper: {
        opacity: 0,
        display: "none",
        "&.loadingSkeleton": {
            opacity: 0.8,
            width: "100%",
            maxHeight: 50,
            height: 50,
            overflow: "hidden",
            border: "white 0.5px solid",
            display: "block",
            borderRadius: "5px",
            backgroundSize: "1000% 100%",
            background:
                "linear-gradient(60deg, rgba(160,160,160,1) 20%, rgba(255,255,255,1) 25%, rgba(160,160,160,1) 30%, rgba(160,160,160,1) 70%, rgba(255,255,255,1) 75%, rgba(160,160,160,1) 80%)",
            animationName: "skeletonGradient",
            animationDelay: "0s",
            animationIterationCount: "infinite",
            animationFillMode: "forwards",
            animationTimingFunction: "linear",
            animationDuration: "1500ms",
        },
    },

    standaloneStickerImage: {
        width: '100px',
        height: '100px',
        objectFit: 'contain',
    },

    isSticker: {
        background: 'none !important',
        padding: 0,
        textAlign: 'center',
    },


});
