import React, {
    useMemo,
    useCallback,
    useRef,
    useEffect,
    useState,
    useLayoutEffect,
} from "react";
import uuid from "react-uuid";
import cx from "classnames";
import { observer } from "mobx-react";
import { isEqual } from "lodash";

import useChangeTheme from "src/hooks/useChangeTheme";
import useStores from "src/hooks/useStores";
import { useStyles } from "src/components/fastChessboard/styles";
import useWindowSize from "src/hooks/useWindowSize";

import Square from "./components/square";
import SquareWithFigureWrapper from "./components/squareWithPiece";
import SVGDefs from "./piece-memos/svgDefs";
import PieceDragLayer from "./pieceDragLayer";
import {
    WPIcon,
    WRIcon,
    WNIcon,
    WBIcon,
    WQIcon,
    WKIcon,
    BPIcon,
    BRIcon,
    BNIcon,
    BBIcon,
    BQIcon,
    BKIcon,
} from "./piece-memos-deepBlack";
import { Notation } from "./Notation";
import { IPiecesMap } from "./types";
import chessboard from "src/pages/lobby/components/GamePreview/GameInProgressPreview/components/chessboard";
import { PiecesAnimMoveAction } from "src/store/models";

interface FastChessboardProps {
    mobileHover: boolean;
    width: number;
    pieceWidth: number;
    orientation: "white" | "black";
    fen: string;
    border: boolean;
    showNotationInBorder: boolean;
    borderWidth: number;
    borderRadius: number;
    marginTop: number;
    marginBottom: number;
    isMultiTable: boolean;
}

const FastChessboard = (props: Partial<FastChessboardProps>) => {
    //@ts-ignore
    const {
        mobileHover = true,
        orientation = "white",
        fen = "8/8/8/8/8/8/8/8",
        border = false,
        showNotationInBorder = false,
        isMultiTable,
        borderWidth = isMultiTable ? 8 : 12,
        borderRadius = 6,
        marginTop = 4,
        marginBottom = 4,
        pieceWidth,
    }: FastChessboardProps = props;
    const appearanceTheme = useChangeTheme();
    const { gameStore, authStore } = useStores();
    const windowSize = useWindowSize();
    const classes = useStyles({
        borderWidth,
        appearanceTheme,
        isMultiTable,
    });
    // Принимаем фен
    const localFen = fen;
    const chessboardRef = useRef<HTMLDivElement>(null);
    const squareStyles = gameStore?.gameState.squareStyles;
    const isMyMove = gameStore.isMyMove;
    const movesHistory = gameStore?.currentGameNew?.movesHistory || [
        { move: "none" },
    ];
    const dropMoveResponse = gameStore.gameState.dropResult;
    const animationTime = 200;
    const actionDropMove = gameStore.gameState.actionDropMove;
    const ddStartData = gameStore.gameState.ddStartData;
    const actionClickMove = gameStore.gameState.actionClickMove;
    const killedPieceChesboardIndex =
        gameStore.gameState.killedPieceChesboardIndex;
    const _ = require("lodash");
    const [localFenState, setFen] = useState<(string | null)[] | null>(null);

    const [isDragging, setState] = useState<boolean>(false);
    const [isDropMode, setDropMode] = useState<boolean>(false);
    const defsParams = useMemo(
        () => ({
            wPid: uuid(),
            wRid: uuid(),
            wNid: uuid(),
            wBid: uuid(),
            wQid: uuid(),
            wKid: uuid(),
            bPid: uuid(),
            bRid: uuid(),
            bNid: uuid(),
            bBid: uuid(),
            bQid: uuid(),
            bKid: uuid(),
        }),
        []
    );

    const pieces = useMemo((): IPiecesMap => {
        return {
            wp: (
                <WPIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wr: (
                <WRIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wn: (
                <WNIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wb: (
                <WBIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wq: (
                <WQIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wk: (
                <WKIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bp: (
                <BPIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            br: (
                <BRIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bn: (
                <BNIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bb: (
                <BBIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bq: (
                <BQIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bk: (
                <BKIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
        };
    }, [pieceWidth, isMultiTable]);

    const dragPieces = useMemo((): IPiecesMap => {
        return {
            wp: (
                <WPIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wr: (
                <WRIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wn: (
                <WNIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wb: (
                <WBIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wq: (
                <WQIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wk: (
                <WKIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bp: (
                <BPIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            br: (
                <BRIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bn: (
                <BNIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bb: (
                <BBIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bq: (
                <BQIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bk: (
                <BKIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
        };
    }, [pieceWidth, isMultiTable]);

    const isWhiteOrientation = useMemo(
        () => orientation[0] === "w",
        [orientation]
    );

    const notationVertical = useMemo(
        () =>
            isWhiteOrientation
                ? [8, 7, 6, 5, 4, 3, 2, 1]
                : [1, 2, 3, 4, 5, 6, 7, 8],
        [isWhiteOrientation]
    );

    const notationHorizontal = useMemo(
        () =>
            isWhiteOrientation
                ? ["a", "b", "c", "d", "e", "f", "g", "h"]
                : ["h", "g", "f", "e", "d", "c", "b", "a"],
        [isWhiteOrientation]
    );

    const flatFen = useMemo(() => {
        // Преобразуем фен
        const parts = localFen.split("");

        const flat = parts.reduce((acc, item) => {
            if (item === "/") return acc;
            if (Number(item)) {
                return [...acc, ...new Array(Number(item)).fill(null)];
            }
            return [...acc, item];
        }, []);

        return isWhiteOrientation ? flat : flat.reverse();
    }, [localFen, isWhiteOrientation, window.innerWidth]);

    const onSquareClick = useCallback(
        gameStore?.gameState.onSquareClick.bind(gameStore.gameState),
        []
    );

    const onDrop = useCallback(
        gameStore?.gameState.onDrop.bind(gameStore?.gameState),
        []
    );

    useEffect(() => {
        if (!chessboardRef?.current?.offsetHeight) return;
        gameStore.setChessboardSize(chessboardRef?.current?.offsetHeight);
    }, [
        chessboardRef?.current?.offsetHeight,
        windowSize,
        chessboardRef?.current?.offsetWidth,
    ]);

    const lastMove = useMemo(() => {
        //  Если хожу не я , то для анимации это условие
        if (!isMyMove) return movesHistory[movesHistory.length - 1]?.move;
        // если хожу дропами
        if (actionDropMove && actionDropMove.length) {
            return actionDropMove[0];
        }
        // Если я хожу кликами
        if (actionClickMove && actionClickMove.length) {
            return actionClickMove[0];
        }
        // Если ошибка
        return null;
    }, [
        gameStore?.currentGameNew?.movesHistory,
        actionDropMove,
        actionClickMove,
        isMyMove,
    ]);

    const startFigure = !!lastMove && !isDropMode ? lastMove.slice(0, 2) : null;
    const finishFigure = !!lastMove && !isDropMode ? lastMove.slice(-2) : null;

    const unitedStartFinishFigure = useMemo(() => {
        if (startFigure && finishFigure) return [startFigure, finishFigure];
        return null;
    }, [lastMove]);

    const startDiceElement = useMemo(
        () => document.getElementById(`gamePiece-square-${startFigure}`),
        [startFigure]
    );

    const finalDiceElement = useMemo(
        () => document.getElementById(`gamePiece-square-${finishFigure}`),
        [finishFigure]
    );

    // 1. Jстается старый фен
    // 2. Новй фен проверяется на предмет различий со старым
    // 3. Делается проверка чей это ход (для игр режима не вью)
    // 3.1 Если это не view, то
    // 4 я пройдусь по старому массиву и например увижу, что на  49 клетке (48 элеме) [.., 48:p, 49,p ]
    // стоит конь. Затем я делаю ход пешкой и прилетает новый фен который [39: p, ..., 48:null, 49,p ]
    // 5. делаю проверку, какие элементы и на каких индексах поменялись. ТАк я найду, индексы и координаты для движения
    // если в новом фене на месте старого стоит null , а не фигура то это Начальный ход и ему задается Start
    // если в новом фене по сравнению со старым поменялась фигура - это конечный ход и ему задается Finish,
    // Пусть конь походил н

    useLayoutEffect(() => {
        if (!localFenState && flatFen) {
            setFen(flatFen);
        }
    }, [flatFen]);

    const updateFenState = (indexParams, drop) => {
        if (drop === "drop") {
            const semi = [...flatFen];
            semi[indexParams] = null;
            setFen(semi);
        }
        let updateFenStateTimeOut;
        if (typeof indexParams === "number" && localFenState) {
            updateFenStateTimeOut = setTimeout(() => {
                const semi = [...flatFen];
                semi[indexParams] = null;
                setFen(semi);
            }, animationTime * 0.75);
        }
        return () => {
            if (updateFenStateTimeOut) {
                clearTimeout(updateFenStateTimeOut);
            }
        };
    };

    useLayoutEffect(() => {
        updateFenState(killedPieceChesboardIndex, null);
    }, [killedPieceChesboardIndex]);

    useLayoutEffect(() => {
        if (
            ddStartData.indexStart &&
            typeof ddStartData.indexStart === "number" &&
            ddStartData.indexFinish &&
            typeof ddStartData.indexFinish === "number"
        ) {
            updateFenState(ddStartData.indexFinish, "drop");
        }
    }, [ddStartData.indexFinish, ddStartData.indexStart]);

    useLayoutEffect(() => {
        if (isDragging || ddStartData.indexFinish) {
            if (dropMoveResponse && !_.isEqual(localFenState, flatFen)) {
                setDropMode(true);
                setFen(flatFen);
                gameStore.gameState.resetDD();
            }
            if (!dropMoveResponse && !_.isEqual(localFenState, flatFen)) {
                setDropMode(false);
                gameStore.gameState.resetDD();
            }
        }
    }, [
        isDragging,
        localFenState,
        flatFen,
        dropMoveResponse,
        ddStartData.indexFinish,
    ]);

    useEffect(() => {
        if (isDragging || ddStartData.indexFinish) {
            return;
        }
        let myTimeout;
        let actionResetTimeout;
        const addAndReset = () => {
            setFen(flatFen);
            actionResetTimeout = setTimeout(() => {
                gameStore.gameState.onResetActionMove(
                    PiecesAnimMoveAction.each
                );
            }, animationTime * 0.15);
        };
        if (localFenState && !_.isEqual(localFenState, flatFen)) {
            const calcAnimTime = isMyMove ? 0.5 : 1;
            myTimeout = setTimeout(() => {
                addAndReset();
            }, animationTime * calcAnimTime);
        }
        return () => {
            if (myTimeout) {
                clearTimeout(myTimeout);
            }
            if (actionResetTimeout) {
                clearTimeout(actionResetTimeout);
            }
        };
    }, [flatFen, isDragging, ddStartData.indexFinish]);

    return (
        <>
            <div
                className={cx(classes.fastGameMainComponent, appearanceTheme, {
                    isMultiTable: isMultiTable,
                })}
                style={{
                    marginTop,
                    marginBottom,
                }}
            >
                <SVGDefs {...defsParams} />

                <PieceDragLayer
                    pieces={dragPieces}
                    isMultiTable={isMultiTable}
                    isDraggingFunc={(params: boolean) => {
                        setState(params);
                    }}
                    equalizeDrag={
                        gameStore?.chessboardSize
                            ? gameStore?.chessboardSize / 8
                            : 0
                    }
                />

                <div className={cx(classes.chessboardWrapper, appearanceTheme)}>
                    <div
                        ref={chessboardRef}
                        className={cx(classes.chessboard, appearanceTheme, {
                            [classes.border]: !!border,
                            isMultiTable,
                        })}
                        style={{
                            lineHeight: "normal",
                            width: "100%",
                            height: "100%",
                            borderRadius: borderRadius,
                        }}
                    >
                        <Notation
                            borderWidth={borderWidth}
                            orientation={orientation}
                            showNotationInBorder={false}
                            isMultiTable={isMultiTable}
                            cellSize={
                                gameStore?.chessboardSize
                                    ? gameStore?.chessboardSize / 8
                                    : 0
                            }
                        />
                        {localFenState &&
                            localFenState.map((piece, i) => {
                                // Отдаем фен на рендер самой доски, конечная
                                const h = i % 8;
                                const v = Math.trunc(i / 8);
                                const square =
                                    notationHorizontal[h] + notationVertical[v];

                                if (piece) {
                                    return (
                                        <SquareWithFigureWrapper
                                            key={i}
                                            i={i}
                                            square={square}
                                            pieces={pieces}
                                            piece={piece}
                                            mobileHover={mobileHover}
                                            appearanceTheme={appearanceTheme}
                                            orientation={orientation}
                                            highlight={
                                                isMyMove
                                                    ? squareStyles?.[square]
                                                    : false
                                            }
                                            onSquareClick={onSquareClick}
                                            onDrop={onDrop}
                                            startDiceElement={startDiceElement}
                                            finalDiceElement={finalDiceElement}
                                            unitedStartFinishFigure={
                                                unitedStartFinishFigure
                                            }
                                            animationTime={animationTime}
                                        />
                                    );
                                } else {
                                    return (
                                        <Square
                                            key={i}
                                            i={i}
                                            square={square}
                                            mobileHover={mobileHover}
                                            appearanceTheme={appearanceTheme}
                                            highlight={
                                                isMyMove
                                                    ? squareStyles?.[square]
                                                    : false
                                            }
                                            onSquareClick={onSquareClick}
                                            onDrop={onDrop}
                                            startDiceElement={startDiceElement}
                                            finalDiceElement={finalDiceElement}
                                            unitedStartFinishFigure={
                                                unitedStartFinishFigure
                                            }
                                        />
                                    );
                                }
                            })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(FastChessboard);
