import { action, computed, makeAutoObservable } from "mobx";

import { InfoMessage } from "src/pages/internet-speed/types";
import { getBanners } from "src/service/api/banner";
import { getPaymentMethods } from "src/service/api/cashbox";
import { getJackpot } from "src/service/api/jackPot";
import { getVariables } from "src/service/api/variables";
import { sounds } from "src/sounds";
import { URLHelper } from "src/shared/helpers/URLHelper";
import { getCountries } from "src/service/api/auth";
import { Nullable } from "src/shared/types";

import {
    BannerTypes,
    GameMode,
    GameType,
    IBanner,
    ICountry,
    IJackpot,
    IPaymentMethod,
    IPaymentMethods,
    IVariable,
    SoundFilesType,
    VariableName,
} from "./models";

export class GeneralStore {
    showActiveGamePopup: boolean = false;
    mode: GameMode =
        URLHelper.getSearchParam("mode") === GameMode.AGGREGATOR
            ? GameMode.AGGREGATOR
            : GameMode.DICECHESS;
    sounds: SoundFilesType = sounds;
    onLogoClick?: () => void;
    onMenuClick?: () => void;
    paymentMethods: IPaymentMethods = { topUp: [], withdrawal: [] };
    jackpot: IJackpot = { amount: 0, rate: 0, createdAt: new Date() };
    gameType: GameType = GameType.dicechess;
    isOpenApplication: boolean = true;
    internetStatus: InfoMessage | null = null;
    isMultiTableAvailable: boolean =
        window.innerWidth >= 1350 && window.innerHeight >= 800;
    banners: IBanner[] = [];
    variables: {
        isLoaded: boolean;
        data: IVariable[];
    } = {
        isLoaded: false,
        data: [],
    };

    isSideMenuOpen: boolean;

    isHalyk: boolean;

    selectedCountry: Nullable<string> = localStorage.getItem("country");
    countries: ICountry[] = [];
    isBalanceHidden: boolean = localStorage.getItem('isBalanceHidden') === 'true' || false;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setBalanceHidden(hidden: boolean) {
        this.isBalanceHidden = hidden;
        localStorage.setItem('isBalanceHidden', hidden.toString());
    }

    @action
    closeSideMenu() {
        this.isSideMenuOpen = false;
    }

    @action
    toggleSideMenu() {
        this.isSideMenuOpen = !this.isSideMenuOpen;
    }
    async getPaymentMethods() {
        try {
            const { data } = await getPaymentMethods();
            this.paymentMethods = data;
        } catch (e) {}
    }

    async getJackpot() {
        try {
            const { data } = await getJackpot();
            this.jackpot = data;
        } catch (e) {}
    }

    async getBanners() {
        try {
            const { data } = await getBanners();
            this.banners = data;
        } catch (e) {}
    }

    async getVariables() {
        try {
            const { data } = await getVariables();
            this.variables = {
                data,
                isLoaded: true,
            };
        } catch (e) {
            this.variables = {
                data: [],
                isLoaded: true,
            };
        }
    }

    async getCountries() {
        try {
            const { data } = await getCountries();
            this.countries = data.countries;
        } catch (e) {}
    }

    async setSelectedCountry(country: Nullable<string>) {
        this.selectedCountry = country;

        if (country) {
            localStorage.setItem("country", country);
        } else {
            localStorage.removeItem("country");
        }
    }

    getPaymentMethodByID(id: string): IPaymentMethod {
        return (this.paymentMethods.topUp.find((p) => p.id === id) ||
            this.paymentMethods.withdrawal.find(
                (p) => p.id === id
            )) as IPaymentMethod;
    }

    @action
    setShowActiveGamePopup(value: boolean) {
        this.showActiveGamePopup = value;
    }

    @action
    setOnLogoClick(handler: () => void | undefined) {
        this.onLogoClick = handler;
    }

    @action
    setOnMenuClick(handler: () => void | undefined) {
        this.onMenuClick = handler;
    }

    @action
    setMode(mode: GameMode) {
        this.mode = mode;
    }

    @action
    setSounds(sounds: SoundFilesType) {
        this.sounds = sounds;
    }

    @action
    updateJackpot(params: Partial<IJackpot>) {
        this.jackpot = { ...this.jackpot, ...params };
    }

    @computed
    get isDicechessMode() {
        return this.mode === GameMode.DICECHESS;
    }

    @computed
    get isAggregatorMode() {
        return this.mode === GameMode.AGGREGATOR;
    }

    @computed
    get streamBanners() {
        return this.banners.filter((b) => b.type === BannerTypes.stream);
    }

    @computed
    get newGameBanners() {
        return this.banners.filter((b) => b.type === BannerTypes.new_game);
    }

    @computed
    get pokerAvailability() {
        return (
            this.variables.data.find(
                (v) => v.name === VariableName.pokerAvailability
            )?.value ?? true
        );
    }

    @action
    setGameType(type: GameType) {
        this.gameType = type;
    }

    @action
    setInternetStatus(status: InfoMessage | null) {
        this.internetStatus = status;
    }

    @action
    setApplicationStatus(isOpenApp: boolean) {
        this.isOpenApplication = isOpenApp;
    }

    @action
    setIsMultiTableAvailable(isMultiTableAvailable: boolean) {
        this.isMultiTableAvailable = isMultiTableAvailable;
    }

    @action
    setIsHalyk(value: boolean) {
        this.isHalyk = value;
    }
}

export default GeneralStore;
