import React, { useEffect, useState } from "react";
import cn from "classnames";
import { observer } from "mobx-react";

import { useStyles } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import coinIcon from "src/assets/icons/coin.svg";
import arrowIcon from "src/assets/icons/CloseArrowIcon.svg";
import arrowRIcon from "src/assets/icons/rightArrow.svg";

interface LocationState {
    backgroundImage?: string;
}

interface Player {
    name: string;
    score?: number;
    avatar?: string;
}

interface Match {
    player1: Player;
    player2: Player;
}

interface Round {
    title: string;
    matches: Match[];
}

interface MatchPairProps {
    player1: Player;
    player2: Player;
}

interface BracketColumnProps {
    title: string;
    children: React.ReactNode;
}
const initialPlayers: Player[] = [
    { name: "ShadowHunter", score: 1, avatar: "https://example.com/shadowhunter-avatar.jpg" },
    { name: "MysticWizard", score: 0, avatar: "https://example.com/mysticwizard-avatar.jpg" },
    { name: "CrimsonArrow", score: 1, avatar: "https://example.com/crimsonarrow-avatar.jpg" },
    { name: "LunarPhantom", score: 0, avatar: "https://example.com/lunarphantom-avatar.jpg" },
    { name: "QuantumBeast", score: 0, avatar: "https://example.com/quantumbeast-avatar.jpg" },
    { name: "NebulaPirate", score: 1, avatar: "https://example.com/nebulapirate-avatar.jpg" },
    { name: "EmberWarlock", score: 1, avatar: "https://example.com/emberwarlock-avatar.jpg" },
    { name: "CelestialAce", score: 0, avatar: "https://example.com/celestialace-avatar.jpg" },
    { name: "VoidWalker", score: 1, avatar: "https://example.com/voidwalker-avatar.jpg" },
    { name: "StormBringer", score: 0, avatar: "https://example.com/stormbringer-avatar.jpg" },
    { name: "FrostNova", score: 1, avatar: "https://example.com/frostnova-avatar.jpg" },
    { name: "InfernoKnight", score: 0, avatar: "https://example.com/infernoknight-avatar.jpg" },
    { name: "EchoSiren", score: 1, avatar: "https://example.com/echosiren-avatar.jpg" },
    { name: "PhantomRogue", score: 0, avatar: "https://example.com/phantomrogue-avatar.jpg" },
    { name: "CyberNinja", score: 1, avatar: "https://example.com/cyberninja-avatar.jpg" },
    { name: "GalacticSamurai", score: 0, avatar: "https://example.com/galacticsamurai-avatar.jpg" },
    { name: "AetherDruid", score: 1, avatar: "https://example.com/aetherdruid-avatar.jpg" },
    { name: "TechnoMancer", score: 0, avatar: "https://example.com/technomancer-avatar.jpg" },
    { name: "AstralValkyrie", score: 1, avatar: "https://example.com/astralvalkyrie-avatar.jpg" },
    { name: "ChronoShifter", score: 0, avatar: "https://example.com/chronoshifter-avatar.jpg" },
    { name: "NeonSpectre", score: 1, avatar: "https://example.com/neonspectre-avatar.jpg" },
    { name: "PulsarGuardian", score: 0, avatar: "https://example.com/pulsarguardian-avatar.jpg" },
    { name: "ZephyrScout", score: 1, avatar: "https://example.com/zephyrscout-avatar.jpg" },
    { name: "OmegaSentinel", score: 0, avatar: "https://example.com/omegasentinel-avatar.jpg" },
    { name: "NeonSpectre", score: 1, avatar: "https://example.com/neonspectre-avatar.jpg" },
    { name: "PulsarGuardian", score: 0, avatar: "https://example.com/pulsarguardian-avatar.jpg" },
    { name: "ZephyrScout", score: 1, avatar: "https://example.com/zephyrscout-avatar.jpg" },
    { name: "OmegaSentinel", score: 0, avatar: "https://example.com/omegasentinel-avatar.jpg" },
    { name: "PulsarGuardian", score: 0, avatar: "https://example.com/pulsarguardian-avatar.jpg" },
    { name: "ZephyrScout", score: 1, avatar: "https://example.com/zephyrscout-avatar.jpg" },
    { name: "OmegaSentinel", score: 0, avatar: "https://example.com/omegasentinel-avatar.jpg" },
    { name: "NeonSpectre", score: 1, avatar: "https://example.com/neonspectre-avatar.jpg" },
    { name: "PulsarGuardian", score: 0, avatar: "https://example.com/pulsarguardian-avatar.jpg" },
];

function generateTournamentBracket(players: Player[]): Round[] {
    const getWinner = (player1: Player, player2: Player): Player => {
        // Случайно определяем победителя
        const winner = Math.random() < 0.5 ? player1 : player2;
        const loser = winner === player1 ? player2 : player1;

        // Обновляем счет победителя
        winner.score = 1;
        loser.score = 0;

        return winner;
    };

    const createMatch = (player1: Player, player2: Player): Match => ({
        player1: { ...player1 },
        player2: { ...player2 }
    });

    const tbdPlayer: Player = { name: "TBD", score: 0 };

    // Функция для создания следующего раунда
    const createNextRound = (previousRound: Round, title: string): Round => {
        const matches: Match[] = [];
        for (let i = 0; i < previousRound.matches.length; i += 2) {
            const match1 = previousRound.matches[i];
            const match2 = previousRound.matches[i + 1] || { player1: tbdPlayer, player2: tbdPlayer };
            const winner1 = getWinner(match1.player1, match1.player2);
            const winner2 = getWinner(match2.player1, match2.player2);
            matches.push(createMatch(winner1, winner2));
        }
        return { title, matches };
    };

    // Создаем первый раунд на основе имеющихся игроков
    const firstRound: Round = {
        title: "1/32",
        matches: []
    };

    for (let i = 0; i < players.length; i += 2) {
        firstRound.matches.push(createMatch(
            players[i] || tbdPlayer,
            players[i + 1] || tbdPlayer
        ));
    }

    // Если количество матчей нечетное, добавляем еще один матч с TBD
    if (firstRound.matches.length % 2 !== 0) {
        firstRound.matches.push(createMatch(tbdPlayer, tbdPlayer));
    }

    // Создаем последующие раунды
    const rounds: Round[] = [firstRound];
    let currentRound = firstRound;
    const roundTitles = ["1/16", "1/8", "1/4", "Semi Final", "Final"];

    for (const title of roundTitles) {
        if (currentRound.matches.length > 1) {
            currentRound = createNextRound(currentRound, title);
            rounds.push(currentRound);
        } else {
            break; // Прекращаем, если достигли финала
        }
    }

    return rounds;
}




const BracketsContainerNew: React.FC = () => {
    const classes = useStyles();
    const location = useLocation<LocationState>();
    const history = useHistory();
    const backgroundImage = location.state?.backgroundImage;

    const bracket = generateTournamentBracket(initialPlayers);

    const handleBackClick = () => {
        history.push('/tournaments');
    };

    return (
        <div className={classes.root}>
            <div
                className={classes.backgroundImage}
                style={{
                    backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
                }}
            />
            <div className={classes.gradientOverlay} />
            <div className={classes.content}>
                <div className={classes.header}>
                    <button className={classes.backButton} onClick={handleBackClick}>
                        <img className={classes.arrowRight} src={arrowRIcon}></img>
                        Back
                    </button>
                    <div className={classes.titleContainer}>
                        <div className={classes.title}>
                            SQUID GAME
                            <img className={classes.coin} src={coinIcon}></img>
                            <span className={classes.gradientSpan}>700</span>
                            <img className={classes.arrowDown} src={arrowIcon}></img>
                        </div>
                        <div className={classes.dateInfo}>Up to
                            <span>2</span>
                            wins
                            <span>•</span>
                            <span>01.02.2024, 18:00</span>
                        </div>
                    </div>
                    <div style={{ width: '44px' }}></div>
                </div>
                <div className={classes.bracket}>
                    {bracket.map((round, roundIndex) => (
                        <BracketColumn
                            key={round.title}
                            title={round.title}
                            columnIndex={roundIndex}
                        >
                            {round.matches.map((match, matchIndex) => (
                                <MatchPair
                                    key={matchIndex}
                                    player1={match.player1}
                                    player2={match.player2}
                                    columnIndex={roundIndex}
                                    matchIndex={matchIndex}
                                />
                            ))}
                        </BracketColumn>
                    ))}
                </div>
            </div>
        </div>
    );
};


const MatchPair: React.FC<MatchPairProps & { columnIndex: number; matchIndex: number }> = ({ player1, player2, columnIndex, matchIndex }) => {
    const classes = useStyles();

    //Временное решение
    const getMarginTop = () => {
        if (columnIndex === 0) {
            return 0; // Для первой колонки отступ сверху отсутствует
        } else if (columnIndex === 1) {
            return matchIndex === 0 ? 40 : 75; 
        } else if (columnIndex === 2) {
            return matchIndex === 0 ? 110 : 230; 
        } else if (columnIndex === 3) {
            return matchIndex === 0 ? 250 : 545; 
        } else if (columnIndex === 4) {
            return matchIndex === 0 ? 470 : 545; 
        } else if (columnIndex === 5) {
            return matchIndex === 790; 
        } else {
            // Для остальных колонок оставляем текущую логику
            const baseGap = 75;
            const gap = baseGap * Math.pow(2, columnIndex - 3);
            return gap;
        }
    };

    return (
        <div
            className={classes.matchPair}
            style={{
                marginTop: `${getMarginTop()}px`,
            }}
        >
            <PlayerBox player={player1} />
            <PlayerBox player={player2} />
        </div>
    );
};

const BracketColumn: React.FC<BracketColumnProps & { columnIndex: number }> = ({ title, children, columnIndex }) => {
    const classes = useStyles();

    return (
        <div className={classes.column}>
            <div className={classes.columnHeader}>{title}</div>
            <div className={classes.matchesContainer}>
                {React.Children.map(children, (child, index) =>
                    React.cloneElement(child as React.ReactElement<any>, { columnIndex, matchIndex: index })
                )}
            </div>
        </div>
    );
};

const PlayerBox: React.FC<{ player: Player }> = ({ player }) => {
    const classes = useStyles();
    return (
        <div className={classes.player}>
            <div className={classes.playerInfo}>
                {player.avatar && (
                    <img
                        src={player.avatar}
                        alt={`${player.name}'s avatar`}
                        className={classes.avatar}
                    />
                )}
                <span>{player.name}</span>
            </div>
            {player.score !== undefined && <span className={classes.score}>{player.score}</span>}
        </div>
    );
};

export default observer(BracketsContainerNew);