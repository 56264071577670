import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { useLocale } from "src/providers/LocaleProvider";
import { Button } from "src/shared/ui/Button";
import paths from "src/consts/paths";
import useInitRematchTimer from "src/hooks/useInitRematchTimer";
import useWindowSize from "src/hooks/useWindowSize";
import { usePlayer } from "src/sounds/use-player";
import UserAvatar from "src/components/UserAvatar";
import FadeInOutVertical from "src/components/Animated";
import useChangeTheme from "src/hooks/useChangeTheme";
import { ColorVariant, UserBalanceType } from "src/store/models";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from "src/pages/game-new2/constants";
import { updateSearchParams } from "src/utils/update-search-params";
import { useChessBoardProps } from "src/pages/game-new/hooks/useChessBoardProps";

import useStores from "../../../../../../hooks/useStores";
import useStyles from "../../../common/rematchGeneralDialog/style";
import { endpointsRoot } from "../../../../../../core/endpoints";
import { CountdownCircular } from "../../../../../../components/countDownCircular";
import {
    ButtonType,
    GreenButton,
} from "../../../../../../components/buttons/greenButton";
import { EGameRematchStatus } from "src/store/models";

const AcceptOrRejectAlert = ({ isXlTablet, isMultiTable = false }) => {
    const { gameStore, gamesStore } = useStores();
    const { width, height } = useWindowSize();
    const history = useHistory();
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const soundPlayer = usePlayer();
    const { onLobbyClose } = useChessBoardProps();
    const MAX_XL_TABLET_SIZE =
        window.innerWidth <= MAX_CHANGE_SCREEN_WIDTH &&
        window.innerWidth >= MAX_L_TABLET;
    const isMobileSize = width < MAX_XL_TABLET;

    useEffect(() => {
        const isRematchAccepted = gameStore.gameState.isRematchAccepted();
        if (isRematchAccepted && !isMultiTable) {
            history.push(`${paths.game}/${gameStore.rematch.gameId}`);
        } else if (isRematchAccepted && isMultiTable) {
            updateSearchParams({ id: undefined });
            const newGameTables = gamesStore.gameTables.map((table) => {
                if (table && table?.id === gameStore.currentGame?.id) {
                    return { ...table, id: gameStore.rematch.gameId as string };
                }
                return table;
            });
            gamesStore.setGameTables(newGameTables);
        }
    }, [
        gameStore?.rematch?.gameId,
        gameStore?.rematch[ColorVariant.black],
        gameStore?.rematch[ColorVariant.white],
    ]);

    const {
        game: {
            rematch: {
                acceptOrReject: { title, accept, reject },
            },
        },
    } = useLocale();

    const opponentColor = gameStore.gameState.getOpponentColor();

    // const actualTimer = useInitRematchTimer(
    //     gameStore?.rematch?.gameCreatedTime,
    //     10000
    // );

    const actualTimer = useInitRematchTimer(
        gameStore.dialogs.rematchData.rematchEnd,
        15000
    );

    // if (!opponentColor) return null;

    const player = gameStore.gameState.getPlayerByColor(opponentColor);
    // if (!player) return null;

    const onCancel = useCallback(() => {
        gameStore.rematchDecline(gameStore?.currentGameNew?._id);
        if (isMultiTable) {
            onLobbyClose?.();
            return;
        }
        history.push(paths.diceChessLobby);
        gameStore.currentGameNew?.free
            ? history.push(paths.lobbyFreePlay)
            : history.push(paths.diceChessLobby);
    }, [gameStore?.currentGameNew?._id]);

    if (isMobileSize) {
        return (
            <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
                <Box
                    component="div"
                    className={cn(
                        styles.rematchAlertContainer,
                        "acceptOrReject"
                    )}
                >
                    <Button
                        text="Rematch"
                        color="green"
                        variant="contained"
                        onClick={() =>
                            gameStore.rematchAccept(
                                gameStore?.currentGameNew?._id
                            )
                        }
                        className={cn(
                            styles.buttonRequest,
                            "acceptOrReject",
                            "rematch"
                        )}
                    />
                    <Button
                        text="Reject"
                        color="red"
                        variant="outlined"
                        onClick={() => {
                            gameStore.rematchDecline(
                                gameStore?.currentGameNew?._id
                            );
                            history.push(paths.diceChessLobby);
                        }}
                        className={cn(
                            styles.buttonRequest,
                            "acceptOrReject",
                            "reject"
                        )}
                    />
                </Box>
            </FadeInOutVertical>
        );
    }

    return (
        <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
            <Box
                component="div"
                className={cn(styles.rematchAlertContainer, "acceptOrReject")}
            >
                <CountdownCircular
                    seconds={actualTimer}
                    size={50}
                    reset={0}
                    pause={false}
                    onEnd={() => {
                        onCancel();
                    }}
                    modalType={EGameRematchStatus.WAITING_FOR_ANSWER}
                    className={cn(styles.circularRequest, "acceptOrReject")}
                />
                <Button
                    text="Rematch"
                    color="green"
                    variant="contained"
                    onClick={() =>
                        gameStore.rematchAccept(gameStore?.currentGameNew?._id)
                    }
                    className={cn(
                        styles.buttonRequest,
                        "acceptOrReject",
                        "rematch"
                    )}
                />
                <Button
                    text="Reject"
                    color="red"
                    variant="outlined"
                    onClick={() => {
                        gameStore.rematchDecline(
                            gameStore?.currentGameNew?._id
                        );
                        history.push(paths.diceChessLobby);
                    }}
                    className={cn(
                        styles.buttonRequest,
                        "acceptOrReject",
                        "reject"
                    )}
                />
            </Box>
        </FadeInOutVertical>
    );

    // return (
    //     <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
    //         <div
    //             className={cn(
    //                 styles.rematchActionContainer,
    //                 appearanceTheme,
    //                 "acceptOrRejectAlert",
    //                 {
    //                     isMultiTable,
    //                 }
    //             )}
    //         >
    //             <div
    //                 className={cn(styles.flexContainer, {
    //                     isXlTablet: isXlTablet,
    //                     isMultiTable,
    //                 })}
    //             >
    //                 <div className={styles.avatarBlockWrapper}>
    //                     <UserAvatar
    //                         avatarUrl={
    //                             (player.avatar &&
    //                                 `${endpointsRoot}/avatars/${player.avatar}`) ||
    //                             ""
    //                         }
    //                         nickname={""}
    //                         className={cn(styles.avatarBlock, { isMultiTable })}
    //                         isSystemAvatar={player.isSystemAvatar}
    //                     />
    //                 </div>
    //                 <div>
    //                     <p className={cn(styles.title, { isMultiTable })}>
    //                         <span>{title("Let's play one more time")}</span>
    //                     </p>
    //                 </div>
    //             </div>
    //             <div className={styles.buttonContainer}>
    //                 <div className={cn(styles.buttonBox, styles.rejectButton)}>
    //                     <GreenButton
    //                         type={ButtonType.purple}
    //                         fontSize={isMultiTable ? 13 : 16}
    //                         text={reject("Lobby")}
    //                         onClick={onCancel}
    //                         isWidthFixed={!MAX_XL_TABLET_SIZE}
    //                         width={MAX_XL_TABLET_SIZE ? "100px" : undefined}
    //                     />
    //                 </div>

    //                 <div className={styles.divider} />
    //                 <div className={cn(styles.buttonBox, styles.countdown)}>
    //                     <CountdownCircular
    //                         seconds={actualTimer}
    //                         reset={0}
    //                         size={45}
    //                         pause={false}
    //                         onEnd={() => {
    //                             gameStore.dialogs.rejectRematch();

    //                             if (isMultiTable) return;

    //                             gameStore.currentGame?.balanceType ===
    //                             UserBalanceType.play
    //                                 ? history.push(paths.diceChessLobby)
    //                                 : history.push(paths.lobbyFreePlay);
    //                         }}
    //                     />
    //                 </div>
    //                 <div className={styles.divider} />
    //                 <div className={cn(styles.buttonBox, styles.playButton)}>
    //                     <GreenButton
    //                         type={ButtonType.primary}
    //                         fontSize={isMultiTable ? 13 : 16}
    //                         text={accept("Play")}
    //                         onClick={() => {
    //                             soundPlayer("bidConfirmSoundPlay");
    //                             gameStore.dialogs.requestRematch();
    //                         }}
    //                         isWidthFixed={!MAX_XL_TABLET_SIZE}
    //                         width={MAX_XL_TABLET_SIZE ? "100px" : undefined}
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //     </FadeInOutVertical>
    // );
};

export default observer(AcceptOrRejectAlert);
