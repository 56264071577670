import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from "react";
import _get from "lodash/get";
import cn from "classnames";
import { observer } from "mobx-react";
import { Box, useMediaQuery } from "@mui/material";
import OutsideClickHandler from "react-outside-click-handler";

import MobileWatchingList from "src/components/WatchingPlayers/components/WatchingList/MobileList/mobileList";
import useStores from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";
import useChangeTheme from "src/hooks/useChangeTheme";

import {
    WatchingType,
    WatchersStatusType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import {
    GameModeType,
    ColorVariant,
    ChessboardType,
    ScreenType,
} from "src/store/models";

import PlayerInfo from "src/components/PlayerInfoNew";
import PlayerInfoRedesign from "src/components/PlayerInfoRedesign";
import Chessboard from "src/pages/game-new2/layouts/components/center/chessboard-custom";
import shortColor from "src/utils/shortColor";
import MobilePotView from "src/pages/lobby/components/GamePreview/GameInProgressPreview/components/betAndTime";
import CoinAnimationContainer from "src/components/controls/bank/coinAnimation/coinAnimationContainer";
import { useStyles } from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/styles";
import { PlayerInfoExistPosition } from "src/store/models";
import DicesWrapper from "src/pages/game-new2/components/dicesWrapper";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import {
    MIN_CHANGE_SCREEN_WIDTH,
    MAX_XL_TABLET,
} from "src/pages/game-new2/constants";
import ActionAndReturn from "src/pages/game-new2/components/actionAndReturn";
import ViewModeModals from "src/pages/game-new2/components/modals/index";

import { EColor } from "src/components/types";

interface IViewChessboardProps {
    players: any;
    playersOnline: any;
    rndPrefix: number;
    diceClickRnd: number;
    elemHeight: number;
    piecesSize: number;
    mobileTabletLayoutContainer: React.RefObject<HTMLDivElement>;
}

const ViewChessTableMobile = (props: any) => {
    const {
        rndPrefix,
        diceClickRnd,
        mobileTabletLayoutContainer,
    }: IViewChessboardProps = props;
    const { gameStore } = useStores();

    const gameContainerWidth =
        mobileTabletLayoutContainer?.current?.offsetWidth;
    const gameContainerHeight =
        mobileTabletLayoutContainer?.current?.offsetHeight;

    const refBoardView = useRef<HTMLInputElement>(null);

    const resign = gameStore?.gameState?.gameResultData?.cause;
    const winner = gameStore?.gameState?.gameResultData?.winner;
    const watchers = gameStore.guests;
    const showWatchersBlock = gameStore.getShowWatchersBlock;
    const gameType = gameStore.currentGame?.type;
    const isMatchGame = gameStore.currentGame?.settings?.rounds! > 1;
    const isTournamentGame = gameType === GameModeType.TOURNAMENT;
    const diceWrapperRef = useRef<HTMLDivElement>(null);
    const { chessBoardWidth, chessBoardRef, isMultiTable } =
        useChessBoardProps();
    const windowSize = useWindowSize();
    const [elemHeight, setHeight] = useState<any>();
    // const [piecesSize, setPiecesSize] = useState<any>();
    const [elemHeightType, setHeightType] = useState<string | undefined>();
    const [uniqWatchers, setUniqWatchers] = useState<WatchingType[]>(watchers);
    const [isOpen, setOpen] = useState<boolean | WatchersStatusType>(false);
    const actualWidth = window.innerWidth;
    const appearanceTheme = useChangeTheme();
    const changeScreenStyles = useMediaQuery(`(max-width: ${MAX_XL_TABLET}px)`);

    const classes = useStyles({ elemHeight, resign });

    // useViewPiecesAndBoardSize(
    //     gameType,
    //     currentChessBorardHeight,
    //     setHeight,
    //     chessBoardRef,
    //     chessBoardWidth,
    //     windowSize,
    //     orientation
    // );

    const isActivePlayer = (color, clock) =>
        shortColor(color) === _get(clock, "color", "unknown");

    const viewMode = gameStore.isViewMode();
    const activeClock = gameStore.gameState.activeClock;
    const yourColor = viewMode
        ? ColorVariant.black
        : gameStore.gameState.getOpponentColor();
    const myColor = viewMode
        ? ColorVariant.white
        : gameStore.gameState.getMyColor();
    // const killedPieces = gameStore.gameState.killedPieces;
    const killedPieces = gameStore?.gameState?.killedPieces;

    const fen = gameStore.gameState.fen;

    const yourTime = gameStore.gameState.playerTimeLeft(yourColor);
    const myTime = gameStore.gameState.playerTimeLeft(myColor);
    const meOrWhitePlayer = gameStore.gameState.getPlayerByColor(
        ColorVariant.white
    );
    const opponent = gameStore.gameState.getPlayerByColor(ColorVariant.black);

    const setOpenUseCallback = useCallback(
        (properties) => setOpen(properties),
        []
    );

    useEffect(() => {
        if (Array.isArray(watchers) && watchers.length > 0) {
            const uniqWatchingObject = {
                //@ts-ignore
                ...[...new Set(watchers.map((guest) => guest._id))],
            };
            const uniqWatchingArray = watchers.filter(
                (guest, index) => guest._id === uniqWatchingObject[index]
            );
            setUniqWatchers(uniqWatchingArray);
        }
        if (Array.isArray(watchers) && watchers.length === 0) {
            setUniqWatchers([]);
        }
    }, [watchers.length]);

    useEffect(() => {
        if (isOpen === WatchersStatusType.hide) {
            setTimeout(() => {
                setOpen(false);
            }, 500);
        }
    }, [isOpen]);

    useEffect(() => {
        if (actualWidth < MIN_CHANGE_SCREEN_WIDTH && elemHeight) {
            if (gameType === GameModeType.REGULAR)
                return setHeightType(`${elemHeight + 20}px`);
            if (gameType === GameModeType.TOURNAMENT)
                return setHeightType(`${elemHeight - 17}px`);
        }
        return setHeightType("inherit");
    }, [elemHeight, actualWidth]);

    const getCenterSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        gameContainerWidth: T,
        gameContainerHeight: U
    ): string => {
        if (gameContainerWidth && gameContainerHeight) {
            let calcSize = Math.min(gameContainerWidth, gameContainerHeight);
            if (gameContainerHeight - gameContainerWidth < 290) {
                return `${gameContainerHeight - 290}px`;
            }

            return `${calcSize}px`;
        }
        return "calc((100dvh - 80px) / 3)";
    };

    const getTopBottomSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        gameContainerWidth: T,
        gameContainerHeight: U
    ): string | undefined => {
        if (gameContainerWidth && gameContainerHeight) {
            if (gameContainerHeight / 3 >= gameContainerWidth) {
                return `${gameContainerWidth}px`;
            }
        }
        return;
    };

    const calcDiceSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        diceWrapperWidthParams: T,
        diceWrapperHeightParams: U
    ): number => {
        if (diceWrapperWidthParams && diceWrapperHeightParams) {
            return (
                Math.min(diceWrapperWidthParams / 3, diceWrapperHeightParams) *
                0.7
            );
        }
        return 50;
    };

    const centerSize = useMemo(
        () => getCenterSize(gameContainerWidth, gameContainerHeight),
        [gameContainerWidth, gameContainerHeight]
    );

    const topBottomSize = useMemo(
        () => getTopBottomSize(gameContainerWidth, gameContainerHeight),
        [gameContainerWidth, gameContainerHeight]
    );

    useEffect(() => {
        let actualSize = calcDiceSize(
            gameContainerWidth,
            diceWrapperRef?.current?.offsetHeight
        );
        gameStore.setPiecesSize(actualSize, ScreenType.mobile);
    }, [
        gameContainerWidth,
        gameContainerHeight,
        diceWrapperRef?.current?.offsetWidth,
        diceWrapperRef?.current?.offsetHeight,
    ]);

    if (!meOrWhitePlayer || !opponent) return null;
    if (appearanceTheme === EColor.deepBlack) {
        return (
            <>
                <div
                    className={classes.mainMobileGameContainerNew}
                    style={{ gridTemplateRows: `1fr ${centerSize} 1fr` }}
                >
                    <Box
                        component="div"
                        className={classes.mobileTabletTopBox}
                        style={{ height: topBottomSize }}
                    >
                        <div
                            className={cn(
                                classes.playerInfoWrapperNew,
                                classes.opponentInfoWrapperNew
                            )}
                        >
                            <div
                                className={cn(
                                    classes.playerInfoInnerWrapperNew,
                                    classes.playerInfoInnerTopWrapper,
                                    { viewMode }
                                )}
                            >
                                <div
                                    className={cn(
                                        classes.presentAndNavigationBox,
                                        "top",
                                        {
                                            isTournamentGame: isTournamentGame,
                                            isMatchGame: isMatchGame,
                                        }
                                    )}
                                >
                                    <div
                                        className={
                                            resign
                                                ? cn(
                                                      classes.viewMobilePotWrapperAnimate,
                                                      {
                                                          isTournamentGame:
                                                              gameType ===
                                                              GameModeType.TOURNAMENT,
                                                      }
                                                  )
                                                : cn(
                                                      classes.viewMobilePotWrapper,
                                                      {
                                                          isTournamentGame:
                                                              gameType ===
                                                              GameModeType.TOURNAMENT,
                                                      }
                                                  )
                                        }
                                    >
                                        {gameType === GameModeType.REGULAR && (
                                            <MobilePotView
                                                amount={
                                                    gameStore.gameState.bank
                                                }
                                            >
                                                <CoinAnimationContainer
                                                    balanceType={
                                                        gameStore.currentGame
                                                            ?.balanceType
                                                    }
                                                    isMultiTable={false}
                                                    isTwoGameTable={false}
                                                    chessboardType={
                                                        ChessboardType.mobileView
                                                    }
                                                />
                                            </MobilePotView>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={cn(
                                        classes.playerInfoInnerWrapperNew,
                                        { viewMode, isMultiTable }
                                    )}
                                >
                                    {changeScreenStyles && viewMode && (
                                        <div
                                            className={cn(
                                                classes.dicesAlertsBoxNew,
                                                {
                                                    viewMode,
                                                }
                                            )}
                                        >
                                            <div
                                                ref={diceWrapperRef}
                                                className={
                                                    classes.dicesWrapperNew
                                                }
                                            >
                                                <DicesWrapper
                                                    rndPrefix={rndPrefix}
                                                    diceClickRnd={diceClickRnd}
                                                    viewMode={viewMode}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className={cn(
                                            classes.playerInfoBoxNew,
                                            {
                                                [classes.viewModePlayerInfoBoxNew]:
                                                    viewMode,
                                                isMultiTable,
                                            }
                                        )}
                                        style={{
                                            width: centerSize,
                                            minWidth: 320,
                                        }}
                                    >
                                        {opponent && (
                                            <PlayerInfoRedesign
                                                resign={resign}
                                                winner={winner}
                                                noTimerSound
                                                avatar={opponent.avatar}
                                                name={
                                                    opponent.name || "Unknown"
                                                }
                                                initTime={yourTime}
                                                activeClock={activeClock}
                                                active={isActivePlayer(
                                                    yourColor,
                                                    activeClock
                                                )}
                                                killedPieces={
                                                    killedPieces[myColor]
                                                }
                                                color={yourColor}
                                                isSystemAvatar={
                                                    opponent.isSystemAvatar
                                                }
                                                elementPlace={
                                                    PlayerInfoExistPosition.inProgressPreview
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>

                    <Box
                        component="div"
                        className={classes.mobileTabletCenterBox}
                    >
                        {changeScreenStyles && viewMode && (
                            <div className={classes.viewModeModalsWrapper}>
                                <ViewModeModals />
                            </div>
                        )}
                        <Chessboard
                            marginTop={0}
                            marginBottom={0}
                            border
                            fen={fen.split(" ")[0]}
                            myColor={myColor}
                            viewMode={viewMode}
                            isMultiTable={false}
                        />
                    </Box>

                    <Box
                        className={classes.mobileTabletBottomBox}
                        style={{ height: topBottomSize }}
                    >
                        <div
                            className={classes.playerInfoWrapperNew}
                            style={{
                                zIndex: 6,
                            }}
                        >
                            <div
                                className={cn(
                                    classes.playerInfoInnerWrapperNew,
                                    classes.playerInfoInnerBottomWrapper,
                                    { viewMode }
                                )}
                            >
                                <div
                                    className={cn(classes.playerInfoBoxNew, {
                                        [classes.viewModePlayerInfoBoxNew]:
                                            viewMode,
                                        isMultiTable,
                                    })}
                                >
                                    {meOrWhitePlayer && (
                                        <PlayerInfoRedesign
                                            resign={resign}
                                            winner={winner}
                                            avatar={meOrWhitePlayer.avatar}
                                            name={
                                                meOrWhitePlayer.name ||
                                                "Unknown"
                                            }
                                            initTime={myTime}
                                            activeClock={activeClock}
                                            active={isActivePlayer(
                                                myColor,
                                                activeClock
                                            )}
                                            killedPieces={
                                                killedPieces[yourColor]
                                            }
                                            color={myColor}
                                            reverse
                                            userCashBox={undefined}
                                            isSystemAvatar={
                                                meOrWhitePlayer.isSystemAvatar
                                            }
                                            elementPlace={
                                                PlayerInfoExistPosition.inProgressPreview
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    className={cn(classes.alertHolder, {
                                        showWatchersBlock: showWatchersBlock,
                                    })}
                                ></div>
                                <Box
                                    component="div"
                                    className={cn(
                                        classes.presentAndNavigationBox,
                                        "bottom"
                                    )}
                                >
                                    {actualWidth < MIN_CHANGE_SCREEN_WIDTH &&
                                        uniqWatchers && (
                                            <ActionAndReturn
                                                setOpen={setOpenUseCallback}
                                                openStatus={
                                                    WatchersStatusType.show
                                                }
                                                isOpen={isOpen}
                                                watchingArray={uniqWatchers}
                                                isMobileView={true}
                                                elementPlace={
                                                    PlayerInfoExistPosition.inProgressPreview
                                                }
                                            />
                                        )}
                                </Box>
                            </div>
                        </div>
                    </Box>
                </div>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        isOpen === WatchersStatusType.show &&
                            setOpen(WatchersStatusType.hide);
                    }}
                >
                    <MobileWatchingList
                        setOpen={setOpenUseCallback}
                        isOpen={isOpen}
                        watchingArray={uniqWatchers}
                        openStatus={WatchersStatusType.show}
                        isMobileGamePage={true}
                        elementPlace={PlayerInfoExistPosition.inProgressPreview}
                    />
                </OutsideClickHandler>
            </>
        );
    }

    return (
        <>
            <div className={classes.mainMobileGameContainer}>
                <div
                    style={{
                        height: elemHeightType && elemHeightType,
                    }}
                    className={cn(
                        classes.playerInfoWrapper,
                        classes.opponentInfoWrapper
                    )}
                >
                    <div
                        className={cn(
                            classes.playerInfoInnerWrapper,
                            classes.playerInfoInnerTopWrapper,
                            { viewMode }
                        )}
                    >
                        <div
                            className={
                                resign
                                    ? cn(classes.viewMobilePotWrapperAnimate, {
                                          isTournamentGame:
                                              gameType ===
                                              GameModeType.TOURNAMENT,
                                      })
                                    : cn(classes.viewMobilePotWrapper, {
                                          isTournamentGame:
                                              gameType ===
                                              GameModeType.TOURNAMENT,
                                      })
                            }
                        >
                            {gameType === GameModeType.REGULAR && (
                                <MobilePotView
                                    amount={gameStore.gameState.bank}
                                >
                                    <CoinAnimationContainer
                                        balanceType={
                                            gameStore.currentGame?.balanceType
                                        }
                                        isMultiTable={false}
                                        isTwoGameTable={false}
                                        chessboardType={
                                            ChessboardType.mobileView
                                        }
                                    />
                                </MobilePotView>
                            )}
                        </div>
                        {changeScreenStyles && viewMode && (
                            <div
                                className={cn(classes.dicesAndAlertHolder, {
                                    viewMode,
                                })}
                            >
                                <div className={classes.dicesWrapper}>
                                    <DicesWrapper
                                        rndPrefix={rndPrefix}
                                        diceClickRnd={diceClickRnd}
                                        viewMode={viewMode}
                                    />
                                </div>
                            </div>
                        )}
                        <div
                            className={cn(classes.playerInfoBox, {
                                [classes.viewModePlayerInfoBox]: viewMode,
                            })}
                        >
                            {opponent && (
                                <PlayerInfo
                                    resign={resign}
                                    winner={winner}
                                    noTimerSound
                                    avatar={opponent.avatar}
                                    name={opponent.name || "Unknown"}
                                    initTime={yourTime}
                                    activeClock={activeClock}
                                    active={isActivePlayer(
                                        yourColor,
                                        activeClock
                                    )}
                                    killedPieces={killedPieces[myColor]}
                                    color={yourColor}
                                    className={classes.userInfoBoxChessBoard}
                                    isSystemAvatar={opponent.isSystemAvatar}
                                    appearanceTheme={appearanceTheme}
                                    elementPlace={
                                        PlayerInfoExistPosition.inProgressPreview
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className={cn(classes.chessBoardBorder, appearanceTheme)}
                    ref={chessBoardRef}
                >
                    {changeScreenStyles && viewMode && (
                        <div className={classes.viewModeModalsWrapper}>
                            <ViewModeModals />
                        </div>
                    )}
                    <div
                        className={cn(
                            classes.chessboardLinearGradient,
                            appearanceTheme
                        )}
                    />
                    <div
                        className={cn(
                            classes.chessBoardInnerBorder,
                            appearanceTheme
                        )}
                        ref={refBoardView}
                    >
                        <Chessboard
                            marginTop={0}
                            marginBottom={0}
                            border
                            fen={fen.split(" ")[0]}
                            myColor={myColor}
                            viewMode={viewMode}
                        />
                    </div>
                </div>
                <div
                    className={classes.playerInfoWrapper}
                    style={{
                        zIndex: 6,
                        height: `${elemHeight - 18}px`,
                    }}
                >
                    <div
                        className={cn(
                            classes.playerInfoInnerWrapper,
                            classes.playerInfoInnerBottomWrapper,
                            { viewMode }
                        )}
                    >
                        <div
                            className={cn(
                                classes.playerInfoBox,
                                classes.myInfoWrapper,
                                classes.playerInfoBoxBottomView
                            )}
                        >
                            {meOrWhitePlayer && (
                                <PlayerInfo
                                    resign={resign}
                                    winner={winner}
                                    avatar={meOrWhitePlayer.avatar}
                                    name={meOrWhitePlayer.name || "Unknown"}
                                    initTime={myTime}
                                    activeClock={activeClock}
                                    active={isActivePlayer(
                                        myColor,
                                        activeClock
                                    )}
                                    killedPieces={killedPieces[yourColor]}
                                    color={myColor}
                                    reverse
                                    className={classes.userInfoBoxChessBoard}
                                    userCashBox={undefined}
                                    isSystemAvatar={
                                        meOrWhitePlayer.isSystemAvatar
                                    }
                                    appearanceTheme={appearanceTheme}
                                    elementPlace={
                                        PlayerInfoExistPosition.inProgressPreview
                                    }
                                />
                            )}
                        </div>
                        {actualWidth < MIN_CHANGE_SCREEN_WIDTH &&
                            uniqWatchers && (
                                <ActionAndReturn
                                    setOpen={setOpenUseCallback}
                                    openStatus={WatchersStatusType.show}
                                    isOpen={isOpen}
                                    watchingArray={uniqWatchers}
                                    isMobileView={true}
                                    elementPlace={
                                        PlayerInfoExistPosition.inProgressPreview
                                    }
                                />
                            )}
                    </div>
                </div>
            </div>
            <OutsideClickHandler
                onOutsideClick={() => {
                    isOpen === WatchersStatusType.show &&
                        setOpen(WatchersStatusType.hide);
                }}
            >
                <MobileWatchingList
                    setOpen={setOpenUseCallback}
                    isOpen={isOpen}
                    watchingArray={uniqWatchers}
                    openStatus={WatchersStatusType.show}
                    isMobileGamePage={true}
                    elementPlace={PlayerInfoExistPosition.inProgressPreview}
                />
            </OutsideClickHandler>
        </>
    );
};

export default observer(ViewChessTableMobile);
