import { makeStyles } from "@mui/styles";
import { appColors } from "src/consts/app-config";

import theme from "src/theme";

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_CHANGE_SCREEN_WIDTH,
    OPPONENT_INFO_HEIGHT,
    OPPONENT_INFO_PADDING,
    MAX_L_TABLET,
    MAX_XL_TABLET,
    MIN_L_TABLET,
    MIN_XL_TABLET,
} from "src/pages/game-new2/constants";

type IWrapper = {
    elemHeight?: any;
    resign?: string;
};
// TODO: remove
const showHideStyle = (elemHeight: number): any => {
    return elemHeight;
};

export const useStyles = makeStyles({
    "@global": {
        "@keyframes alertHolderAnimate": {
            "0%": {
                opacity: 0,
            },
            "40%": {
                opacity: 0,
            },
            "100%": {
                opacity: 1,
            },
        },
        "@keyframes viewMobilePotWrapperAnimate": {
            "0%": {
                minHeight: "40px",
            },
            "100%": {
                minHeight: "0px",
                padiing: 0,
                margin: 0,
            },
        },
    },

    mainMobileGameContainerNew: {
        display: "grid",
        height: "100%",
        width: "100%",
        justifyItems: "center",
        alignItems: "start",
        alignContent: "start",
        gridGap: 4,
    },

    mobileTabletTopBox: {
        width: "100%",
        height: "100%",
        minHeight: "145px",
        alignSelf: "end",
    },

    playerInfoWrapperNew: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "&.bottom": {
            [theme.breakpoints.between("xs", MAX_CHANGE_SCREEN_WIDTH)]: {
                "@media (orientation: portrait)": {
                    alignItems: "center",
                },
            },
        },
    },

    opponentInfoWrapperNew: ({ elemHeight }: IWrapper) => ({
        // minHeight: OPPONENT_INFO_HEIGHT,
        marginTop: OPPONENT_INFO_PADDING,
        // '@media screen and (min-width: 1251px) and (max-height: 1100px)': {
        //     minHeight: 75,
        // },
        "@media screen and (min-width: 1024px) and (max-height: 700px)": {
            minHeight: "auto!important",
        },
        [`@media screen and (min-width: ${MAX_L_TABLET}px) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-height: ${MAX_XL_TABLET}px)`]:
            {
                marginTop: "0px",
            },
        "@media screen and (max-width: 768px) and (max-height: 800px)": {
            marginTop: 7,
        },
        "@media screen and (max-width: 768px) and (max-height: 740px)": {
            marginTop: 3,
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            maxWidth: "100%",
            width: "100%",
            height: `${showHideStyle(elemHeight)}px`,
        },
        [`@media screen and (orientation: portrait) and (min-width: ${320}px) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]:
            {
                zIndex: 1,
            },
    }),

    presentAndNavigationBox: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridColumnGap: "40px",
        // overflow: "hidden",
        [theme.breakpoints.down("lg")]: {
            gridColumnGap: "0px",
            gridTemplateColumns: "auto",
            justifyContent: "center",
            alignContent: "center",
            height: 40,
            minHeight: 40,
            maxHeight: 40,
        },
        "&.isXlTablet": {
            height: 40,
        },
        "&.isTournamentGame": {
            height: 40,
        },
        "&.isMatchGame": {
            height: 40,
        },
        "&.bottom": {
            gridTemplateColumns: "1fr",
        },
    },

    bankWrapperNew: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },

    playerInfoInnerWrapperNew: {
        width: "100%",
        height: "100%",
        padding: "0",
        margin: "0",
        [theme.breakpoints.down("lg")]: {
            "@media (orientation: portrait)": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gridGap: 5,
            },
        },
        "&.viewMode": {},
        "&.isMultiTable": {},
    },

    dicesAndAlertHolderNew: {
        position: "relative",
        [theme.breakpoints.down("lg")]: {
            margin: "0",
            padding: "0",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
    },

    dicesAlertsBoxNew: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        zIndex: 4,
        "&.viewMode": {
            position: "static",
            transform: "none",
        },
    },

    dicesWrapperNew: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 0,
        minHeight: 65,
        position: "relative",
        width: "100%",
        "& > div": {
            width: "auto",
            display: "grid",
            // gridTemplateColumns: 'auto auto auto',
            gridTemplateColumns: "1fr auto 1fr",
            gridColumnGap: "20px",
        },
        [theme.breakpoints.down("lg")]: {
            minHeight: "100%",
            margin: "0",
            padding: "0",
        },
        "&.isTournamentGame": {
            height: "100%",
        },
    },

    viewModePlayerInfoBoxNew: {
        margin: "15px 0",
        "@media (orientation: landscape) and (max-height: 1920px)": {
            margin: "5px 0",
        },
        "@media (orientation: landscape) and (max-height: 770px)": {
            margin: "5px 0",
        },
        "@media (orientation: landscape) and (max-height: 690px)": {
            margin: "0",
        },
        [theme.breakpoints.down("sm")]: {
            margin: 0,
            marginBottom: "5px",
            marginTop: "0px",
        },
    },

    mobileTabletCenterBox: {
        height: "100%",
        aspectRatio: "1 / 1",
    },

    mobileTabletBottomBox: {
        width: "100%",
        height: "100%",
        minHeight: "145px",
    },

    playerInfoBoxNew: {
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
        [theme.breakpoints.down("lg")]: {
            height: "50%",
            minHeight: 64,
            maxHeight: 92,
            flexGrow: 1,
            margin: "0 auto",
        },
        [theme.breakpoints.down("md")]: {
            minHeight: 60,
            maxHeight: 84,
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: 52,
            maxHeight: 72,
        },
    },

    //==============//

    gameChatHistoryBox: {
        textAlign: "center",
        height: "fit-content",
        width: "100%",
        "@media  (orientation: landscape) and (min-width: 1024px)": {
            height: "fit-content",
            position: "relative",
            width: "100%",
        },
        "&.mobileType": {
            "@media  (orientation: portrait) ": {
                [theme.breakpoints.between("xs", "lg")]: {
                    height: "100%",
                    width: "100%",
                    "&.open": {},
                    "&.hide": {},
                },
            },
        },
    },

    root: {
        border: "0px solid orange",
        height: "calc(100vh - 64px)",
        margin: "0 auto",
    },
    chessboard: {
        padding: 0,
        alignSelf: "center",
    },

    bank: {
        height: 100,
        paddingTop: 16,
    },

    leftActionButtonWrapper: {
        width: "100%",
        maxWidth: 300,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 19,
        marginLeft: "auto",
        marginRight: "auto",
    },

    mainMobileGameContainer: {
        minHeight: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        "&.isMultiTable": {
            display: "grid",
            gridTemplateRows: "auto 1fr auto",
            gridTemplateColumns: "1fr",
        },
        "&.isXlTablet": {
            justifyContent: "center",
        },
        "@media screen and (min-width: 1024px) and (max-height: 550px)": {
            minHeight: "auto",
            height: "auto",
        },
    },

    mainMobileGameContainerAnimate: {
        minHeight: "100%",
        height: "100%",
        // minHeight: '100%',
        // height: '100%',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        // Turn off animation on container
        [`@media screen and (max-width: 450px) and (min-height: 680px)`]: {
            // animationName: '$mainMobileLight',
            animationDelay: "2.3s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "1000ms",
        },
        [`@media screen and (max-width: 450px) and (min-height: 730px)`]: {
            // animationName: '$mainMobileMid',
            animationDelay: "2.3s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "900ms",
        },
        [`@media screen and (max-width: 450px) and (min-height: 760px)`]: {
            // animationName: '$mainMobileHard',
            animationDelay: "2.3s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "850ms",
        },
        "&.isXlTablet": {
            justifyContent: "center",
        },
    },

    "@keyframes mainMobileLight": {
        "0%": {
            padding: "0px 0px 0px 0px",
        },
        "100%": {
            padding: "20px 0px 15px 0px",
        },
    },

    "@keyframes mainMobileMid": {
        "0%": {
            padding: "0px 0px 0px 0px",
        },
        "100%": {
            padding: "35px 0px 30px 0px",
        },
    },
    "@keyframes mainMobileHard": {
        "0%": {
            padding: "0px 0px 0px 0px",
        },
        "100%": {
            padding: "45px 0px 35px 0px",
        },
    },

    // Chess Box Size Calc
    chessBoardBorder: {
        borderRadius: 10,
        background: "#191d32",
        maxWidth: 555,
        width: "100%",
        margin: "0 auto",
        position: "relative",
        padding: 5,
        zIndex: 0,
        "&.isMultiTable": {
            width: "auto",
            height: "100%",
            padding: 2,
            aspectRatio: "1 / 1",
            display: "flex",
            alignItems: "center",
        },
        "&.darkBlue": {
            background: "#191d32",
        },
        "&.darkGreen": {
            background: "#001312",
        },
        "&.deepBlack": {
            background: "#191d32",
        },
        "@media screen and (max-width: 768px)": {
            maxWidth: 470,
            padding: 0,
        },
        [theme.breakpoints.up(1800)]: {
            "@media screen and (min-height: 950px)": {
                width: "100%",
                maxWidth: "calc(100vh - 350px)",
            },
        },
        [`@media screen and (max-height: 890px) and (min-width: ${MIN_CHANGE_SCREEN_WIDTH}px)`]:
            { maxWidth: 520 },
        [`@media screen and (max-height: 840px) and (min-width: ${MIN_CHANGE_SCREEN_WIDTH}px)`]:
            {
                maxWidth: 500,
            },
        [`@media screen and (max-height: 820px) and (min-width: ${MIN_CHANGE_SCREEN_WIDTH}px)`]:
            {
                maxWidth: 480,
            },
        [`@media screen and (max-height: 800px) and (min-width: ${MIN_CHANGE_SCREEN_WIDTH}px)`]:
            {
                maxWidth: 430,
            },
        "@media screen (max-width: 1400px)": {
            maxWidth: 450,
        },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            maxWidth: 500,
        },
        [`@media screen and (max-width: 1024px) and (max-height: 1366px)`]: {
            maxWidth: 710,
        },
        [`@media screen and (max-width: 1024px) and (max-height: 1440px)`]: {
            maxWidth: 720,
        },
        [`@media screen and (max-width: 900px) and (max-height: 1366px)`]: {
            maxWidth: 620,
        },
        [`@media screen and (max-width: 1024px) and (max-height: 1280px)`]: {
            maxWidth: 600,
        },
        [`@media screen and (max-width: 900px) and (max-height: 1280px)`]: {
            maxWidth: 590,
        },
        [`@media screen and (min-width: 600px) and (max-width: 900px) and (min-height: 1150px)`]:
            {
                "@media (orientation: portrait) ": {
                    maxWidth: 630,
                },
            },
        [`@media screen and (min-width: 600px) and (max-width: 900px) and (min-height: 1280px)`]:
            {
                "@media (orientation: portrait) ": {
                    maxWidth: 650,
                },
            },
        [`@media screen and (max-width: 1024px) and (max-height: 1080px)`]: {
            maxWidth: 530,
        },
        [`@media screen and (max-width: 900px) and (max-height: 1080px)`]: {
            maxWidth: 500,
        },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 1024px)`]:
            {
                maxWidth: 515,
            },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 980px)`]:
            {
                maxWidth: 490,
            },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 950px)`]:
            {
                maxWidth: 470,
            },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 940px)`]:
            {
                maxWidth: 420,
            },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 850px)`]:
            {
                maxWidth: 400,
            },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 830px)`]:
            {
                maxWidth: 380,
            },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 800px)`]:
            {
                maxWidth: 370,

                // maxWidth: 350,
            },
        [`@media screen and (max-width: ${MIN_L_TABLET}px) and (min-width: ${450}px) and (max-height: 800px)`]:
            {
                maxWidth: 370,
            },
        [`@media screen and (max-width: ${MIN_L_TABLET}px) and (min-width: ${450}px) and (max-height: 780px)`]:
            {
                maxWidth: 350,
            },
        [`@media screen and (max-width: ${MIN_L_TABLET}px) and (min-width: ${450}px) and (max-height: 760px)`]:
            {
                maxWidth: 330,
            },
        [`@media screen and (max-width: ${MIN_L_TABLET}px) and (min-width: ${450}px) and (max-height: 700px)`]:
            {
                maxWidth: 310,
            },
        "@media (orientation: landscape) and (max-height: 740px)": {
            // maxWidth: 410,
            maxWidth: 395,
        },
        "@media (orientation: landscape) and (max-height: 700px)": {
            // maxWidth: 400,
            maxWidth: 385,
        },
        "@media (orientation: landscape) and (max-height: 690px)": {
            // maxWidth: 380,
            maxWidth: 365,
        },
        "@media (orientation: landscape) and (max-height: 600px)": {
            maxWidth: 360,
        },
        "@media (orientation: landscape) and (max-height: 570px)": {
            maxWidth: 330,
        },
        "@media (orientation: landscape) and (max-height: 550px)": {
            maxWidth: 300,
        },
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            width: "100%",
            padding: "0px 5px",
        },
        [`@media screen and (max-width: 430px) and (max-height: 800px)`]: {
            maxWidth: 400,
        },
        [`@media screen and (max-width: 430px) and (max-height: 780px)`]: {
            maxWidth: 385,
        },
        [`@media screen and (max-width: 430px) and (max-height: 730px)`]: {
            maxWidth: 365,
        },
        [`@media screen and (max-width: 430px) and (max-height: 690px)`]: {
            maxWidth: 355,
        },
        [`@media screen and (max-width: 430px) and (max-height: 670px)`]: {
            maxWidth: 340,
        },
        [`@media screen and (max-width: 430px) and (max-height: 650px)`]: {
            maxWidth: 330,
        },
        [`@media screen and (max-width: 430px) and (max-height: 640px)`]: {
            maxWidth: 320,
        },
        [`@media screen and (max-width: 430px) and (max-height: 630px)`]: {
            maxWidth: 315,
        },
        [`@media screen and (max-width: 430px) and (max-height: 620px)`]: {
            maxWidth: 310,
        },

        [`@media screen and (max-width: 420px) and (max-height: 740px)`]: {
            maxWidth: 375,
        },
        [`@media screen and (max-width: 420px) and (max-height: 720px)`]: {
            maxWidth: 365,
        },
        [`@media screen and (max-width: 420px) and (max-height: 700px)`]: {
            maxWidth: 355,
        },
        [`@media screen and (max-width: 420px) and (max-height: 680px)`]: {
            maxWidth: 340,
        },
        [`@media screen and (max-width: 420px) and (max-height: 660px)`]: {
            maxWidth: 325,
        },
        [`@media screen and (max-width: 420px) and (max-height: 620px)`]: {
            maxWidth: 315,
        },

        [`@media screen and (max-width: 390px) and (max-height: 740px)`]: {
            maxWidth: 370,
        },
        [`@media screen and (max-width: 390px) and (max-height: 700px)`]: {
            maxWidth: 350,
        },
        [`@media screen and (max-width: 390px) and (max-height: 680px)`]: {
            maxWidth: 340,
        },
        [`@media screen and (max-width: 390px) and (max-height: 665px)`]: {
            maxWidth: 330,
        },
        [`@media screen and (max-width: 390px) and (max-height: 650px)`]: {
            maxWidth: 325,
        },
        [`@media screen and (max-width: 390px) and (max-height: 640px)`]: {
            maxWidth: 315,
        },
        [`@media screen and (max-width: 390px) and (max-height: 630px)`]: {
            maxWidth: 310,
        },

        [`@media screen and (max-width: 375px) and (max-height: 680px)`]: {
            maxWidth: 330,
        },
        [`@media screen and (max-width: 375px) and (max-height: 660px)`]: {
            maxWidth: 325,
        },
        [`@media screen and (max-width: 375px) and (max-height: 650px)`]: {
            maxWidth: 320,
        },
        [`@media screen and (max-width: 375px) and (max-height: 640px)`]: {
            maxWidth: 315,
        },
        [`@media screen and (max-width: 375px) and (max-height: 630px)`]: {
            maxWidth: 310,
        },
        [`@media screen and (max-width: 375px) and (max-height: 600px)`]: {
            maxWidth: 290,
        },
        [`@media screen and (max-width: 375px) and (max-height: 570px)`]: {
            maxWidth: 270,
        },
        [`@media screen and (max-width: 375px) and (max-height: 550px)`]: {
            maxWidth: 250,
        },
        [`@media screen and (max-width: 360px) and (max-height: 680px)`]: {
            maxWidth: 330,
        },
        [`@media screen and (max-width: 360px) and (max-height: 660px)`]: {
            maxWidth: 325,
        },
        [`@media screen and (max-width: 360px) and (max-height: 630px)`]: {
            maxWidth: 315,
        },
        [`@media screen and (max-width: 360px) and (max-height: 620px)`]: {
            maxWidth: 305,
        },
        [`@media screen and (max-width: 330px) and (max-height: 600px)`]: {
            maxWidth: 260,
        },
        [`@media screen and (max-width: 330px) and (max-height: 570px)`]: {
            maxWidth: 240,
        },
    },

    chessBoardInnerBorder: {
        borderRadius: 10,
        background: "#3c4469",
        width: "100%",
        padding: 5,
        "&.isMultiTable": {
            padding: 0,
            position: "relative",
        },
        "&.darkBlue": {
            background: "#3c4469",
        },
        "&.darkGreen": {
            background: "#103C45;",
        },
        "&.deepBlack": {
            background: "#3c4469",
        },
        "@media screen and (max-width: 768px)": {
            padding: 0,
        },
    },

    chat: {
        height: 574,
        display: "block",
        "@media screen and (max-width: 1200px)": {
            display: "none",
        },
    },

    chessboardLinearGradient: {
        position: "absolute",
        top: -10,
        bottom: -10,
        left: -30,
        right: -30,
        zIndex: -10,
        opacity: 0.7,
        background:
            "radial-gradient(49.98% 49.98% at 49.98% 49.98%, #73f2ff 0%, rgba(82, 163, 255, 0.38) 100%)",
        filter: "blur(20px)",
        borderRadius: 400,
        "&.darkBlue": {
            background:
                "radial-gradient(49.98% 49.98% at 49.98% 49.98%, #73f2ff 0%, rgba(82, 163, 255, 0.38) 100%)",
        },
        "&.darkGreen": {
            background:
                "radial-gradient(49.98% 49.98% at 49.98% 49.98%, #FFFFFF 0%, rgba(16, 18, 27, 0) 100%)",
            mixBlendMode: "color-dodge",
            opacity: 0.7,
        },
        "&.deepBlack": {
            background:
                "radial-gradient(49.98% 49.98% at 49.98% 49.98%, #73f2ff 0%, rgba(82, 163, 255, 0.38) 100%)",
        },
        "&.isMultiTable": {
            zIndex: 0,
        },
    },
    chessboardBgLight: {},

    playerInfoWrapper: ({ elemHeight }: IWrapper) => ({
        position: "relative",
        display: "grid",
        placeItems: "center",
        width: "100%",
        "@media screen and (max-width: 1920.5px)": {
            backfaceVisibility: "hidden",
        },
        [`@media screen and (min-width: ${MAX_L_TABLET}px) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-height: ${MAX_XL_TABLET}px)`]:
            {
                marginTop: "10px",
                alignItems: "start",
            },
        [`@media screen and (max-width: ${MIN_L_TABLET}px) and (min-width: ${450}px) and (min-height: 1023px)`]:
            {
                alignItems: "start",
            },
        "@media screen and (max-width: 1024px) and (max-height: 775px)": {
            transform: "scale(0.9)",
        },
        "@media screen and (max-width: 555px)": {
            marginLeft: "unset",
            marginRight: "unset",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            maxWidth: "100%",
            width: "100%",
            height: `${showHideStyle(elemHeight)}px`,
        },
        "&.bottom": {
            [theme.breakpoints.between("xs", MAX_CHANGE_SCREEN_WIDTH)]: {
                "@media (orientation: portrait)": {
                    alignItems: "flex-start",
                },
            },
        },
        "@media screen and (min-width: 768px) and (max-width: 1024px) and (max-height: 770px)":
            {
                height: "auto!important",
                minHeight: "auto!important",
            },
        "&.isMultiTable": {
            minHeight: "auto",
        },
    }),

    opponentInfoWrapper: ({ elemHeight }: IWrapper) => ({
        // minHeight: OPPONENT_INFO_HEIGHT,
        marginTop: OPPONENT_INFO_PADDING,
        // '@media screen and (min-width: 1251px) and (max-height: 1100px)': {
        //     minHeight: 75,
        // },
        "@media screen and (min-width: 1024px) and (max-height: 700px)": {
            minHeight: "auto!important",
        },
        [`@media screen and (min-width: ${MAX_L_TABLET}px) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-height: ${MAX_XL_TABLET}px)`]:
            {
                marginTop: "0px",
            },
        "@media screen and (max-width: 768px) and (max-height: 800px)": {
            marginTop: 7,
        },
        "@media screen and (max-width: 768px) and (max-height: 740px)": {
            marginTop: 3,
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            maxWidth: "100%",
            width: "100%",
            height: `${showHideStyle(elemHeight)}px`,
        },
        [`@media screen and (orientation: portrait) and (min-width: ${320}px) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]:
            {
                zIndex: 1,
            },
    }),

    myInfoWrapper: {
        [theme.breakpoints.down("xl")]: {
            marginTop: 5,
        },
        [theme.breakpoints.down("lg")]: {
            marginTop: 10,
        },
        [`@media screen and (min-width: ${450}px) and (max-width: ${MAX_L_TABLET}px) and (max-height: 700px)`]:
            {
                marginTop: 3,
            },
        [`@media screen and (min-width: ${450}px) and (max-width: ${580}px) and (max-height: 1000px)`]:
            {
                marginTop: 0,
            },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            marginTop: 0,
        },
    },

    myInfoWrapperView: {
        [theme.breakpoints.down("sm")]: {
            marginTop: 30,
        },
    },

    playerInfoInnerWrapper: {
        maxWidth: 555,
        width: "100%",
        [theme.breakpoints.up(1800)]: {
            "@media screen and (min-height: 950px)": {
                maxWidth: "100%",
                width: "100%",
            },
        },
        [theme.breakpoints.down(MIN_CHANGE_SCREEN_WIDTH)]: {
            // maxWidth: '370px',
            maxWidth: "470px",
        },
        [theme.breakpoints.between("sm", MAX_L_TABLET)]: {
            maxWidth: "455px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
        },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_XL_TABLET)]: {
            maxWidth: "500px",
            [`@media screen and (orientation: portrait) and (min-width: ${MAX_L_TABLET}px) and (max-height: 820px)`]:
                {
                    maxWidth: "440px",
                },
            [`@media screen and (orientation: portrait) and (min-width: ${MAX_L_TABLET}px) and (min-height: 1000px)`]:
                {
                    maxWidth: "720px",
                },
        },
        [theme.breakpoints.between(MAX_XL_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            maxWidth: "520px",
            [`@media screen and (orientation: portrait) and (min-width: ${MAX_L_TABLET}px) and (max-height: 820px)`]:
                {
                    maxWidth: "460px",
                },
            [`@media screen and (orientation: portrait) and (min-width: ${MAX_L_TABLET}px) and (min-height: 1000px)`]:
                {
                    maxWidth: "720px",
                },
        },
        [`@media screen and (max-width: ${MIN_L_TABLET}px) and (min-width: ${450}px) and (min-height: 900px)`]:
            {
                maxWidth: "450px",
            },
        [`@media screen and (max-width: ${MIN_L_TABLET}px) and (min-width: ${500}px) and (min-height: 900px)`]:
            {
                // maxWidth: '80%',
                maxWidth: "100%",
            },
        "@media screen and (min-width: 768px) and (max-width: 1024px) and (max-height: 770px)":
            {
                height: "auto!important",
            },
    },

    playerInfoInnerTopWrapper: {
        "&.isMultiTable": {
            paddingBottom: 4,
        },
        [`@media screen and (min-width: 1251px) and (max-height: 1300px)`]: {
            paddingBottom: 10,
        },
        [`@media screen and (min-width: 1251px) and (max-height: 1100px)`]: {
            paddingBottom: 15,
        },
        [`@media screen and (min-width: 1251px) and (max-height: 850px)`]: {
            paddingBottom: 10,
        },
        [`@media screen and (min-width: 1251px) and (max-height: 800px)`]: {
            paddingBottom: 7,
        },
        [`@media screen and (min-width: 1440px) and (max-height: 1100px)`]: {
            paddingBottom: 15,
        },
        [`@media screen and (min-width: 1440px) and (max-height: 850px)`]: {
            paddingBottom: 10,
        },
        [`@media screen and (min-width: 1440px) and (max-height: 800px)`]: {
            paddingBottom: 10,
        },
        [`@media screen and (min-width: 1440px) and (max-height: 720px)`]: {
            paddingBottom: 10,
        },
        [`@media screen and (min-width: 1440px) and (max-height: 700px)`]: {
            paddingBottom: 7,
        },
        "@media screen and (max-width: 500px) and (min-height: 801px)": {
            paddingTop: 10,
        },
        "@media screen and (min-width: 1024px) and (max-height: 550px)": {
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
        },
        "@media screen and (max-width: 500px) and (max-height: 801px)": {
            paddingTop: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
        },
        "&.viewMode": {
            paddingTop: 0,
        },
        [theme.breakpoints.between("sm", "xl")]: {
            "@media (orientation: portrait)": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "0",
                margin: "0",
            },
        },
        [theme.breakpoints.down("sm")]: {
            "@media (orientation: portrait)": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "0",
                margin: "0",
                height: "inherit",
                "&.viewMode": {
                    paddingBottom: 0,
                },
            },
        },
        "@media screen and (max-width: 450px) and (max-height: 700px)": {
            "&.viewMode": {
                paddingBottom: 0,
                // paddingBottom: 10,
            },
        },
        "@media screen and (max-width: 420px) and (max-height: 750px)": {
            paddingBottom: 0,
        },
        "@media screen and (max-width: 450px) and (max-height: 950px)": {
            paddingBottom: 0,
        },
    },

    playerInfoInnerBottomWrapper: {
        "&.isMultiTable": {
            paddingTop: 4,
        },
        // [`@media screen and (min-width: 1251px) and (max-height: 1100px)`]: {
        //     paddingTop: 15,
        // },
        // [`@media screen and (min-width: 1251px) and (max-height: 850px)`]: {
        //     paddingTop: 10,
        // },
        // [`@media screen and (min-width: 1251px) and (max-height: 800px)`]: {
        //     paddingTop: 7,
        // },
        // [`@media screen and (min-width: 1440px) and (max-height: 3000px)`]: {
        //     paddingTop: 13,
        // },
        // [`@media screen and (min-width: 1440px) and (max-height: 850px)`]: {
        //     paddingTop: 8,
        // },
        // [`@media screen and (min-width: 1440px) and (max-height: 800px)`]: {
        //     paddingTop: 8,
        // },
        // [`@media screen and (min-width: 1440px) and (max-height: 720px)`]: {
        //     paddingTop: 7,
        // },
        // [`@media screen and (min-width: 1440px) and (max-height: 700px)`]: {
        //     paddingTop: 7,
        // },
        // "@media screen and (min-width: 1024px) and (max-height: 550px)": {
        //     paddingTop: "0px!important",
        // },
        [theme.breakpoints.down(MIN_XL_TABLET)]: {
            "@media (orientation: portrait)": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "0",
                margin: "0",
                height: "inherit",
                position: "relative",
                "&.viewMode": {
                    marginTop: 0,
                    paddingTop: 0,
                    justifyContent: "flex-start",
                },
            },
        },
    },

    playerInfoBox: {
        margin: "0 auto",
        [`@media screen and (orientation: landscape) and (min-width: 1251px) and (max-height: 1100px)`]:
            {
                transform: "scale(0.9)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1251px) and (max-height: 850px)`]:
            {
                transform: "scale(0.89)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1251px) and (max-height: 800px)`]:
            {
                transform: "scale(0.86)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1440px) and (max-height: 1100px)`]:
            {
                transform: "scale(0.98)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1440px) and (max-height: 850px)`]:
            {
                transform: "scale(0.96)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1440px) and (max-height: 800px)`]:
            {
                transform: "scale(0.88)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1500px) and (max-height: 1100px)`]:
            {
                transform: "scale(0.95)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1500px) and (max-height: 850px)`]:
            {
                transform: "scale(0.92)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1500px) and (max-height: 800px)`]:
            {
                transform: "scale(0.86)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1700px) and (max-height: 1100px)`]:
            {
                transform: "scale(1)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1700px) and (max-height: 850px)`]:
            {
                transform: "scale(0.9)",
            },
        [`@media screen and (orientation: landscape) and (min-width: 1700px) and (max-height: 800px)`]:
            {
                transform: "scale(0.8)",
            },
        "@media screen and (orientation: landscape) and (min-width: 1024px) and (max-height: 700px)":
            {
                transform: "scale(0.8)!important",
            },
        "@media screen and (orientation: landscape) and (min-width: 1024px) and (max-height: 550px)":
            {
                transform: "scale(0.6)!important",
            },
        "@media screen and (max-width: 767px)": {
            transform: "scale(0.8)",
        },
        [theme.breakpoints.between(451, 600)]: {
            [`@media screen and (orientation: portrait)`]: {
                transform: "scale(1)",
                margin: 0,
                padding: 0,
                zIndex: 1,
                marginBottom: "15px",
            },
        },
        [theme.breakpoints.between(600, "xl")]: {
            [`@media screen and (orientation: portrait)`]: {
                transform: "scale(1)",
                margin: 0,
                padding: 0,
                zIndex: 1,
                marginBottom: "20px",
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingBottom: "9px",
            height: "65px",
            maxHeight: "65px",
            minHeight: "65px",
            transform: "scale(0.98)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            zIndex: 1,
        },
        [`@media screen and (max-width: 430px) and (min-height: 850px)`]: {
            marginBottom: 5,
        },
        [`@media screen and (max-width: 430px) and (max-height: 610px)`]: {
            paddingTop: "2px",
            paddingBottom: "2px",
            height: "60px",
            maxHeight: "60px",
            minHeight: "60px",
            transform: "scale(0.93)",
        },
        [`@media screen and (max-width: 375px) and (max-height: 680px)`]: {
            paddingTop: "3px",
            transform: "scale(0.95)",
        },
        "&.isMultiTable": {
            transform: "scale(1)!important",
        },
    },

    playerInfoBoxBottom: {
        // '@media screen and (max-width: 500px)': {
        //     marginTop: 10,
        //     paddingTop: 15,
        // },
        [theme.breakpoints.between(451, 600)]: {
            [`@media screen and (orientation: portrait)`]: {
                transform: "scale(1)",
                margin: 0,
                padding: 0,
                zIndex: 1,
                marginTop: "10px",
                marginBottom: "0px",
            },
        },
        [theme.breakpoints.between(600, "xl")]: {
            [`@media screen and (orientation: portrait)`]: {
                transform: "scale(1)",
                margin: 0,
                padding: 0,
                zIndex: 1,
                marginTop: "15px",
                marginBottom: "0px",
            },
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0",
            width: "100%",
            height: "55px",
            maxHeight: "55px",
            minHeight: "55px",
            transform: "scale(1)",

            // marginTop: 15,
            paddingTop: 0,
            paddingBottom: 0,
            // marginBottom: 5,
        },
        [`@media screen and (max-width: 430px) and (max-height: 700px)`]: {
            height: "55px",
            maxHeight: "55px",
            minHeight: "55px",
            // marginTop: 3,
        },
        [`@media screen and (max-width: 430px) and (max-height: 610px)`]: {
            height: "55px",
            maxHeight: "55px",
            minHeight: "55px",
        },
        [`@media screen and (max-width: 420px) and (max-height: 700px)`]: {
            height: "60px",
            maxHeight: "60px",
            minHeight: "60px",
            // marginTop: 10,
        },
        [`@media screen and (max-width: 390px) and (max-height: 700px)`]: {
            // marginTop: 15,
        },
        [`@media screen and (max-width: 390px) and (max-height: 680px)`]: {
            // marginTop: 5,
        },
    },

    playerInfoBoxBottomView: {
        [theme.breakpoints.down(MIN_XL_TABLET)]: {
            width: "100%",
            paddingTop: "0px",
            height: "65px",
            maxHeight: "65px",
            transform: "scale(1)",
            margin: "0px",
        },
    },

    userInfoBoxChessBoard: {
        [`@media screen and (max-width: 1400px) and (min-width: 769px)`]: {
            "& .player-info-left": {
                // left: -14,
            },
            "& .player-info-right": {
                // right: -14,
            },
        },
        // [theme.breakpoints.down('lg')]: {
        //     transform: 'scale(1.1)',
        // },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            transform: "scale(0.95)",
            "@media screen and (min-height: 1080px) and (orientation: portrait)":
                {
                    height: "78px",
                    transform: "scale(1)",
                },
        },
        [theme.breakpoints.between(600, MAX_L_TABLET)]: {
            transform: "scale(0.95)",

            "@media screen and (min-height: 1080px) and (orientation: portrait)":
                {
                    height: "73px",
                    transform: "scale(1)",
                },
        },
        [theme.breakpoints.down(MIN_L_TABLET)]: {
            "@media and (orientation: portrait)": {
                transform: "scale(1)",
                paddingTop: 0,
            },
        },
        [theme.breakpoints.down("sm")]: {
            "@media and (orientation: portrait)": {
                transform: "scale(1)",
                paddingTop: 0,
            },
        },
        "@media screen and (max-width: 400px)": {
            "& .player-info-figure": {
                width: "50%",
            },
        },
        "@media screen and (max-width: 430px) and (max-height: 700px)": {
            transform: "scale(0.95)",
        },
        "@media screen and (max-width: 430px) and (max-height: 650px)": {
            transform: "scale(0.92)",
        },
        "@media screen and (max-width: 430px) and (max-height: 630px)": {
            transform: "scale(0.9)",
        },
        "@media screen and (max-width: 420px) and (max-height: 630px)": {
            transform: "scale(0.85)",
        },
        "@media screen and (max-width: 390px) and (max-height: 680px)": {
            transform: "scale(0.82)",
        },
        "@media screen and (max-width: 390px) and (max-height: 630px)": {
            transform: "scale(0.75)",
        },

        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH})`]: {
            "& .pieces-wrapper": {
                transform: "scale(0.85)",
            },
        },
        "&.isMultiTable .user-avatar": {
            [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
                width: 60,
                height: 60,
                // padding: 2,
                "& .nickname-box": {
                    fontSize: 16,
                },
            },
        },
        "&.isMultiTable": {
            [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]:
                {
                    transform: "0",
                },
        },
        "& .user-avatar": {
            [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
                width: 70,
                height: 70,
                // padding: 5,
                "& .nickname-box": {
                    fontSize: 16,
                },
            },
            [theme.breakpoints.between(MAX_L_TABLET, "xl")]: {
                "@media (orientation: portrait)": {
                    width: 100,
                    height: 100,
                    // width: 70,
                    // height: 70,
                },
            },
            [theme.breakpoints.between(500, MAX_L_TABLET)]: {
                "@media (orientation: portrait)": {
                    width: 90,
                    height: 90,
                    // width: 70,
                    // height: 70,
                },
            },
            "@media screen and (max-width: 500px)": {
                width: 70,
                height: 70,
                // padding: 2,
                "& .nickname-box": {
                    fontSize: 14,
                },
            },
            "@media screen and (max-width: 360px)": {
                width: 65,
                height: 65,
                "& .nickname-box": {
                    fontSize: 13,
                },
            },
        },
        "& .player-name": {
            "@media screen and (max-width: 500px)": {
                fontSize: 16,
            },
            "@media screen and (max-width: 360px)": {
                fontSize: 14,
            },
        },
        "& .clock-wrapper": {
            [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
                fontSize: 30,
            },
            "@media screen and (max-width: 500px)": {
                fontSize: 23,
            },
        },
        "& .pieces-wrapper": {
            [`@media screen and (orientation:landscape) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-width: 769px)`]:
                {
                    transform: "scale(0.82)",
                },
            "@media screen and (max-width: 350px)": {
                transform: "scale(0.9)",
            },
        },
    },

    alertHolder: {
        width: "100%",
        padding: "0px 10px",
        [theme.breakpoints.down(MIN_XL_TABLET)]: {
            height: "100%",
            padding: 0,
            margin: 0,
            "&.showWatchersBlock": {
                height: "calc(100% - 0px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
            },
        },
        // [theme.breakpoints.between(450, "xl")]: {
        //     "@media (orientation: portrait) and (min-height: 900px)": {
        //         transform: "scale(1.25)",
        //         marginTop: "12px",
        //         padding: 0,
        //         margin: 0,
        //         zIndex: 1,
        //         // minHeight: '55%',
        //         height: "calc(100% - 65px)",
        //         "&.showWatchersBlock": {
        //             transform: "scale(1)",
        //             marginTop: "0px",
        //             height: "calc(100% - 0px)",
        //             display: "flex",
        //             flexDirection: "column",
        //             justifyContent: "flex-end",
        //         },
        //         "&.isXlTablet": {
        //             height: "80px",
        //             minHeight: "80px",
        //             maxHeight: "80px",
        //         },
        //     },
        // },
        // [theme.breakpoints.down("sm")]: {
        //     padding: 0,
        //     margin: 0,
        //     zIndex: 1,
        //     // minHeight: '55%',
        //     height: "calc(100% - 65px)",
        //     "&.showWatchersBlock": {
        //         height: "calc(100% - 0px)",
        //         display: "flex",
        //         flexDirection: "column",
        //         justifyContent: "flex-end",
        //     },
        //     "&.isXlTablet": {
        //         height: "80px",
        //         minHeight: "80px",
        //         maxHeight: "80px",
        //     },
        // },
    },

    logoWithNav: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridColumnGap: "40px",
        "@media screen and (max-width: 1440px)": {
            gridColumnGap: "5px",
        },
        "@media screen and (max-width: 1370px) and (min-width: 1024px)": {
            gridColumnGap: "5px",
        },
        "@media screen and (max-width: 1024px)": {
            gridColumnGap: "0px",
            gridTemplateColumns: "auto",
            justifyContent: "center",
            height: 40,
        },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_XL_TABLET)]: {
            height: 0,
        },
        "@media screen and (min-width: 768px) and (max-width: 1024px) and (max-height: 770px)":
            {
                display: "none!important",
            },
        "@media screen and (min-width: 0px) and (max-width: 768px) and (max-height: 450px)":
            {
                display: "none!important",
            },
        "&.isXlTablet": {
            height: 40,
        },
        "&.isTournamentGame": {
            height: 0,
        },
    },

    bankWrapper: {
        display: "flex",
        alignItems: "center",
    },

    alertHolderAnimate: {
        height: "100%",
        opacity: 0,
        [theme.breakpoints.down(MAX_L_TABLET)]: {
            animationName: "alertHolderAnimate",
            animationDelay: "1s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "1500ms",
        },
        "&.isXlTablet": {
            animationName: "alertHolderAnimate",
            animationDelay: "1s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "1500ms",
        },
    },

    btnWrap: {
        width: "50%",
    },
    dicesAndAlertHolder: {
        position: "relative",
        height: "fit-content",
        margin: "5px 0px 5px 0px",
        "@media screen and (min-width: 410px) and (max-height: 740px)": {
            padding: 0,
            margin: 0,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            "&.viewMode": {
                margin: "5px 0px 5px 0px",
            },
            "&.inProgress": {
                justifyContent: "flex-start",
                flexDirection: "column",
            },
        },
        [theme.breakpoints.between("md", MAX_CHANGE_SCREEN_WIDTH)]: {
            padding: 0,
            margin: 0,
            height: "calc(100% - 100px)",

            display: "flex",
            justifyContent: "center",
            "&.inProgress": {
                justifyContent: "space-evenly",
                flexDirection: "column",
            },
            "&.viewMode": {
                "@media screen and (orientation:landscape)": {
                    display: "none",
                },
            },
        },

        [theme.breakpoints.down("md")]: {
            padding: 0,
            margin: 0,
            height: "calc(100% - 95px)",
            // height: 'calc(100% - 85px)',
            // height: '100%',
            display: "flex",
            justifyContent: "center",
            "&.inProgress": {
                // justifyContent: 'flex-start',
                justifyContent: "space-evenly",
                flexDirection: "column",
            },
        },

        [theme.breakpoints.down("sm")]: {
            padding: 0,
            margin: 0,
            height: "calc(100% - 80px)",

            display: "flex",
            justifyContent: "center",
            "&.inProgress": {
                justifyContent: "space-evenly",
                flexDirection: "column",
            },
        },
    },

    dicesAlertsBox: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        zIndex: 4,
    },

    dicesWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 0,
        minHeight: 65,
        position: "relative",
        width: "100%",
        "& > div": {
            width: "auto",
            display: "grid",
            // gridTemplateColumns: 'auto auto auto',
            gridTemplateColumns: "1fr auto 1fr",
            gridColumnGap: "20px",
        },
        "@media screen and (max-width: 500px) and (min-height: 750px) and (max-height: 800px)":
            {
                margin: "8px 0",
            },
        "@media screen and (max-width: 500px) and (min-height: 801px)": {},
        [theme.breakpoints.down("md")]: {
            minHeight: "fit-content",
            margin: "0",
            padding: "0",
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "fit-content",
            margin: "0",
            padding: "0",
        },
        "&.isTournamentGame": {
            height: "100%",
        },
    },

    viewModeModalsWrapper: {
        width: "100%",
        height: "auto",
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridRowGap: "10px",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
    },
    viewModeDicesWrapper: {
        margin: "0px!important",
        [theme.breakpoints.down("sm")]: {
            height: "100%",
        },
    },

    viewModePlayerInfoBox: {
        margin: "15px 0",
        "@media (orientation: landscape) and (max-height: 1920px)": {
            margin: "5px 0",
        },
        "@media (orientation: landscape) and (max-height: 770px)": {
            margin: "5px 0",
        },
        "@media (orientation: landscape) and (max-height: 690px)": {
            margin: "0",
        },
        [theme.breakpoints.down("sm")]: {
            margin: 0,
            marginBottom: "5px",
            marginTop: "0px",
        },
        // '@media screen and (max-width: 430px) and (max-height: 760px)': {
        //     marginTop: '10px',
        // },
        // '@media screen and (max-width: 430px) and (max-height: 730px)': {
        //     marginTop: '5px',
        // },
        // '@media screen and (max-width: 430px) and (max-height: 700px)': {
        //     marginTop: '0',
        // },
    },

    viewMobilePotWrapper: {
        display: "flex",
        justifyContent: "center",
        minHeight: "40px",
        "@media screen and (max-width: 450px) and (min-height: 850px)": {
            paddingTop: 10,
        },
        "@media screen and (max-width: 450px) and (min-height: 870px)": {
            paddingTop: 15,
        },
        "@media screen and (max-width: 450px) and (min-height: 890px)": {
            paddingTop: 20,
        },
        "@media screen and (max-width: 390px) and (min-height: 810px)": {
            paddingTop: 10,
        },
        "@media screen and (max-width: 390px) and (min-height: 830px)": {
            paddingTop: 15,
        },
        "@media screen and (max-width: 390px) and (min-height: 850px)": {
            paddingTop: 20,
        },
        [theme.breakpoints.down("md")]: {
            "&.isTournamentGame": {
                minHeight: "0px",
                maxHeight: "0px",
            },
        },
        "@media (orientation: landscape)": {
            display: "none",
        },
    },

    viewMobilePotWrapperAnimate: {
        display: "flex",
        justifyContent: "center",
        minHeight: "40px",
        "@media screen and (max-width: 450px) and (min-height: 850px)": {
            paddingTop: 10,
        },
        "@media screen and (max-width: 450px) and (min-height: 870px)": {
            paddingTop: 15,
        },
        "@media screen and (max-width: 450px) and (min-height: 890px)": {
            paddingTop: 20,
        },
        "@media screen and (max-width: 390px) and (min-height: 810px)": {
            paddingTop: 10,
        },
        "@media screen and (max-width: 390px) and (min-height: 830px)": {
            paddingTop: 15,
        },
        "@media screen and (max-width: 390px) and (min-height: 850px)": {
            paddingTop: 20,
        },
        animationName: "viewMobilePotWrapperAnimate",
        animationDelay: "3s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "1700ms",
        [theme.breakpoints.down("md")]: {
            "&.isTournamentGame": {
                minHeight: "0px",
                maxHeight: "0px",
            },
        },
    },

    chessboardMultiGameTabsBox: {
        width: "75%",
        height: "100%",
        marginBottom: "15px",
        border: "1px solid aqua",
        borderRadius: "10px",
    },

    desktopLayoutContainer: {
        display: "grid",
        width: "100%",
        height: "fit-content",
        background: appColors.deepBlack,
        gridTemplateColumns: `1fr 1.2fr 1fr`,
        "@media  (orientation: landscape) and (min-width: 1024px)": {
            gridGap: "10px",
        },
        "@media  (orientation: landscape) and (min-width: 1250px)": {
            gridGap: "14px",
        },
    },

    toSingleGameWrapper: {
        position: "absolute",
        top: "0px",
        right: "0px",
        zIndex: 1,
        width: "fit-content",
        height: "fit-content",
        maxWidth: "80px",
        cursor: "pointer",
        borderRadius: "3px",
        transition: "all 300ms ease-in",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            transition: "all 300ms ease-in",
            filter: "drop-shadow(0px 0px 6px gold)",
        },
        "&:hover .showInfoText": {
            opacity: 1,
            transition: "all 300ms ease-in",
            fontWeight: 700,
            cursor: "default",
        },
    },

    toSingleGameWrapperText: {
        marginTop: 3,
        transition: "all 300ms ease-in",
        margin: 0,
        padding: 0,
        opacity: 0.5,
        fontSize: 10,
    },

    desktopLeftBox: {
        display: "flex",
        justifyContent: "flex-end",
    },

    desktopLeftRightItem: {
        display: "block",
        height: "100%",
        width: "100%",
        maxWidth: "500px",
    },

    desktopCenterBox: {
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "60px auto 60px",
    },

    desktopCenterPiecesWrapper: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0px 0px 12px 12px",
        background: "rgba(245, 245, 245, 0.05)",
    },

    desktopCenterSquaredWrapper: {
        width: "100%",
        height: "100%",
        aspectRatio: "1 / 1",
    },

    desktopRightBox: {
        display: "flex",
        justifyContent: "flex-start",
    },

    // ====

    // Chess Box Size Calc

    userInfoBoxChessBoardNew: {
        [`@media screen and (max-width: 1400px) and (min-width: 769px)`]: {
            "& .player-info-left": {
                // left: -14,
            },
            "& .player-info-right": {
                // right: -14,
            },
        },
        // [theme.breakpoints.down('lg')]: {
        //     transform: 'scale(1.1)',
        // },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            transform: "scale(0.95)",
            "@media screen and (min-height: 1080px) and (orientation: portrait)":
                {
                    height: "78px",
                    transform: "scale(1)",
                },
        },
        [theme.breakpoints.between(600, MAX_L_TABLET)]: {
            transform: "scale(0.95)",

            "@media screen and (min-height: 1080px) and (orientation: portrait)":
                {
                    height: "73px",
                    transform: "scale(1)",
                },
        },
        [theme.breakpoints.down(MIN_L_TABLET)]: {
            "@media and (orientation: portrait)": {
                transform: "scale(1)",
                paddingTop: 0,
            },
        },
        [theme.breakpoints.down("sm")]: {
            "@media and (orientation: portrait)": {
                transform: "scale(1)",
                paddingTop: 0,
            },
        },
        "@media screen and (max-width: 400px)": {
            "& .player-info-figure": {
                width: "50%",
            },
        },
        "@media screen and (max-width: 430px) and (max-height: 700px)": {
            transform: "scale(0.95)",
        },
        "@media screen and (max-width: 430px) and (max-height: 650px)": {
            transform: "scale(0.92)",
        },
        "@media screen and (max-width: 430px) and (max-height: 630px)": {
            transform: "scale(0.9)",
        },
        "@media screen and (max-width: 420px) and (max-height: 630px)": {
            transform: "scale(0.85)",
        },
        "@media screen and (max-width: 390px) and (max-height: 680px)": {
            transform: "scale(0.82)",
        },
        "@media screen and (max-width: 390px) and (max-height: 630px)": {
            transform: "scale(0.75)",
        },

        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH})`]: {
            "& .pieces-wrapper": {
                transform: "scale(0.85)",
            },
        },
        "&.isMultiTable .user-avatar": {
            [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
                width: 60,
                height: 60,
                // padding: 2,
                "& .nickname-box": {
                    fontSize: 16,
                },
            },
        },
        "&.isMultiTable": {
            [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]:
                {
                    transform: "0",
                },
        },
        "& .user-avatar": {
            [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
                width: 70,
                height: 70,
                // padding: 5,
                "& .nickname-box": {
                    fontSize: 16,
                },
            },
            [theme.breakpoints.between(MAX_L_TABLET, "xl")]: {
                "@media (orientation: portrait)": {
                    width: 100,
                    height: 100,
                    // width: 70,
                    // height: 70,
                },
            },
            [theme.breakpoints.between(500, MAX_L_TABLET)]: {
                "@media (orientation: portrait)": {
                    width: 90,
                    height: 90,
                    // width: 70,
                    // height: 70,
                },
            },
            "@media screen and (max-width: 500px)": {
                width: 70,
                height: 70,
                // padding: 2,
                "& .nickname-box": {
                    fontSize: 14,
                },
            },
            "@media screen and (max-width: 360px)": {
                width: 65,
                height: 65,
                "& .nickname-box": {
                    fontSize: 13,
                },
            },
        },
        "& .player-name": {
            "@media screen and (max-width: 500px)": {
                fontSize: 16,
            },
            "@media screen and (max-width: 360px)": {
                fontSize: 14,
            },
        },
        "& .clock-wrapper": {
            [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
                fontSize: 30,
            },
            "@media screen and (max-width: 500px)": {
                fontSize: 23,
            },
        },
        "& .pieces-wrapper": {
            [`@media screen and (orientation:landscape) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-width: 769px)`]:
                {
                    transform: "scale(0.82)",
                },
            "@media screen and (max-width: 350px)": {
                transform: "scale(0.9)",
            },
        },
    },
});
