import axios from "axios";

import { IGame } from "src/store/models";

import endpoints from "../../../core/endpoints";

import {
    ICreateGameRequest,
    ICreateGameResponse,
    IGetGameRequest,
    IGetGameResponse,
    IRemoveGameRequest,
    IRemoveGameResponse,
    ICreateGameRequestNew,
    ICreateGameResponseNew,
    IinviteGameRequest,
    IinviteGameResponse,
    IinviteNoramalGameResponse,
    IGameErrorResponse,
    ISportEventResponse,
} from "./requestResponses";

export const createGame = async (params: ICreateGameRequest) => {
    return await axios.post<ICreateGameResponse>(endpoints.createGame, params);
};

export const removeGame = async (params: IRemoveGameRequest) => {
    return await axios.delete<IRemoveGameResponse>(
        `${endpoints.deleteGame}/${params.gameId}`
    );
};

export const getUserList = async () => {
    return await axios.get(`${endpoints.usersList}`);
};

export const getGameById = async (params: IGetGameRequest) => {
    return await axios.get<IGetGameResponse>(
        `${endpoints.game}/${params.gameId}`
    );
};

//========
export const createGameNew = async (params: ICreateGameRequestNew) => {
    return await axios.post<ICreateGameResponseNew>(
        endpoints.createGameNew,
        params
    );
};

export const connectNormalGame = async (params: string) => {
    return await axios.post<IGetGameRequest | IGameErrorResponse>(
        endpoints.applyNormalInvite,
        {
            inviteId: params,
        }
    );
};

export const connectFreeGame = async (params: string) => {
    return await axios.post<IinviteGameResponse>(endpoints.applyFreeInvite, {
        inviteId: params,
    });
};

export const acceptFromModalNormalGame = async (params: string) => {
    return await axios.post<IinviteGameResponse>(
        endpoints.declineNormalInvite,
        {
            inviteId: params,
        }
    );
};

export const acceptFromModalFreeGame = async (params: string) => {
    return await axios.post<IinviteGameResponse>(
        endpoints.declineNormalInvite,
        {
            inviteId: params,
        }
    );
};

export const declineNormalGame = async (params: string) => {
    return await axios.post<IinviteGameResponse>(
        endpoints.declineNormalInvite,
        {
            inviteId: params,
        }
    );
};

export const cancelNormalInvite = async (params: string) => {
    return await axios.post<IinviteGameResponse>(endpoints.cancelNormalInvite, {
        inviteId: params,
    });
};

export const declineFreeGame = async (params: string) => {
    return await axios.post<IinviteGameResponse>(
        endpoints.declineNormalInvite,
        {
            inviteId: params,
        }
    );
};

export const getSportEvent = async () => {
    return await axios.get<ISportEventResponse>(endpoints.sportEvent);
};