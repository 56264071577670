import React, { memo, useState, useLayoutEffect, useEffect } from "react";
import cn from "classnames";
import PulseLoader from "react-spinners/PulseLoader";

import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import { appColors, backgroundColors } from "src/consts/app-config";
import { IStickerPackList } from "src/store/models";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import { fileHelper } from "src/shared/helpers/FileHelper";

import { useStyles } from "./styles";

// Import stickers
import stickerChtr from 'src/assets/icons/stickers/cheater.svg';
import stickerCry from 'src/assets/icons/stickers/cry.svg';
import stickerGG from 'src/assets/icons/stickers/gg.svg';
import stickerGL from 'src/assets/icons/stickers/gl.svg';
import stickerHeart from 'src/assets/icons/stickers/heart.svg';
import stickerRainbow from 'src/assets/icons/stickers/rainbow.svg';
import stickerWtf from 'src/assets/icons/stickers/wtf.svg';

export interface IStickerContainer {
    onSendSticker?: (stickerUrl: string) => void;
    stickerPack?: IStickerPackList;
}

const StickerContainer = ({
    onSendSticker,
}: IStickerContainer) => {
    const classes = useStyles();
    const { chatMobileType } = useLeftLayoutContext();
    const [skeletonState, setSkeletons] = useState<any[] | null>(null);
    const [isLoaded, setLoaded] = useState<boolean>(false);

    const stickers = [
        { id: 'sticker1', src: stickerChtr },
        { id: 'sticker2', src: stickerCry },
        { id: 'sticker3', src: stickerGG },
        { id: 'sticker4', src: stickerGL },
        { id: 'sticker5', src: stickerHeart },
        { id: 'sticker6', src: stickerRainbow },
        { id: 'sticker7', src: stickerWtf },
    ];

    const skeletonArray = () => {
        return Array.from({ length: 14 }, (_, i) => ({ id: i }));
    };

    useLayoutEffect(() => {
        if (skeletonState) return;
        const dataSkeleton = skeletonArray();
        setSkeletons(dataSkeleton);
    }, []);

    useEffect(() => {
        // Simulate loading time
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <React.Fragment key={`StickerContainer_mainFragment`}>
            {isLoaded && stickers.length > 0 && (
                <React.Fragment key={`stickers_maindivFragment`}>
                    <div
                        key={`stickers_mainDiv`}
                        className={cn(classes.stickersListBox, chatMobileType)}
                    >
                        <div
                            className={cn(classes.stickersList)}
                            key={`stickers_stickersList`}
                        >
                            {stickers.map((sticker) => (
                                <img
                                    key={sticker.id}
                                    className={cn(classes.stickersImg, {
                                        isLoaded: isLoaded,
                                    })}
                                    src={sticker.src}
                                    alt={sticker.id}
                                    onClick={() => {
                                        console.log('Sticker clicked:', sticker.id);
                                        if (onSendSticker) {
                                            onSendSticker(sticker.src); 
                                        } else {
                                            console.error('onSendSticker is not defined');
                                        }
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )}
            {!isLoaded && skeletonState && (
                <>
                    <div className={cn(classes.stickersTitle, "pulseLoader")}>
                        <PulseLoader
                            key={"pulse"}
                            color={appColors.semiwhite}
                            size={5}
                            margin="3px"
                        />
                    </div>
                    <div className={cn(classes.stickersList)}>
                        {skeletonState.map((item) => (
                            <span
                                key={item?.id}
                                className={cn(classes.stickersSkeleton, {
                                    loadingSkeleton: !isLoaded,
                                })}
                            />
                        ))}
                    </div>
                </>
            )}
        </React.Fragment>
    );
};

export default memo(StickerContainer);
