import { Box, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import loadable from "@loadable/component";
import { AxiosResponse } from "axios";

import { useLocale } from "src/providers/LocaleProvider";
import { Footer } from "src/components/wrapper/footer";
import pockerSlot from "src/assets/images/pockerSlot.png";
import sportSlot from "src/assets/images/sportSlot.png";
import chessVideo from "src/assets/chessVideo.mp4";
import chessVideoMob from "src/assets/chessVideoMob.mp4";
import halykVideoKZ from "src/assets/Halyk/kazmp4.mp4";
import halykVideoRUS from "src/assets/Halyk/compres_halyk_2.mp4";
import { fileHelper } from "src/shared/helpers/FileHelper";
import leftArrowIcon from "src/assets/icons/ArrowLeft.svg";
import { ExternalGameType, ISlotsResponse } from "src/entities/externalGame";
import { IExternalGameResponse } from "src/entities/externalGame/model/types";

import paths from "../../../../consts/paths";
import LoginForm from "../../../../dialogs/login/LoginForm";
import useStores from "../../../../hooks/useStores";
import { errorService } from "../../../../service/services";
import { ModalProvider, ModalTypes } from "../../../landing/utils/context";
import { RegisterForm } from "../../../register/RegisterForm";
import MobileNavMenu from "../mobileNavMenu/MobileNavMenu";
import { MainActionButton } from "../../../../components/buttons/newDesignButtons/mainActionButton";
import youTubeIcon from "src/assets/icons/social/YoutubeLogo.svg";
import twitchIcon from "src/assets/icons/social/TwitchLogo.svg";
import { getGames } from "../../../../service/api/externalGames";
import AppBar from "../../../../components/controls/app-bar";
import HalykAppBar from "../../../../components/controls/app-bar/components/halykAppBar";
import {
    ISportEventResponse,
    SportEventData,
} from "../../../../service/api/lobby/requestResponses";
import { getSportEvent } from "../../../../service/api/lobby";

import { useStyles } from "./styles";
import { getStreamList } from "../../../../service/api/stream";
import moment from "moment";

interface StreamData {
    imageURL: string;
    name: string;
    online: boolean;
    youtube: string;
    twitch: string;
    streamingSchedule: {
        from: string;
        to: string;
    };
}


const BusinessModal = loadable(() => import("../BusinessModal"));
const Notifications = loadable(() => import("src/components/Notifications"));

const MobileVersion = () => {
    const history = useHistory();
    const { authStore, generalStore } = useStores();
    const [showDice, setShowDice] = useState(false);

    const { search } = useLocation();
    const { language } = useLocale();
    const [video, setVideo] = useState(halykVideoRUS);

    const [mobile, setMobileStatus] = useState<string>("noMobile");

    const isHalyk = generalStore.isHalyk;

    // chess video media query for mob & desc
    const isMobile = useMediaQuery('(max-width:450px)', { noSsr: true });
    const [videoSrc, setVideoSrc] = useState(chessVideo);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        console.log('Is mobile:', isMobile);
        if (isMobile) {
            setVideoSrc(chessVideoMob);
        } else {
            setVideoSrc(chessVideo);
        }
    }, [isMobile]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
            videoRef.current.play().catch(e => console.error("Error playing video:", e));
        }
    }, [videoSrc]);


    const mobileChecker = () => {
        let regMobile =
            /iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
        regMobile || window.innerWidth <= 450
            ? setMobileStatus("mobile")
            : setMobileStatus("noMobile");
    };

    useEffect(() => {
        switch (language) {
            case "ru":
                return setVideo(halykVideoRUS);
            case "kk":
                return setVideo(halykVideoKZ);

            default:
                return setVideo(halykVideoRUS);
        }
    }, [language]);

    useEffect(() => {
        mobileChecker();
    }, [window.innerWidth]);

    useEffect(() => {
        setTimeout(() => {
            setShowDice(true);
        }, 500);
    }, []);

    const {
        mainLanding: {
            balance: { errorOnSaveState },
            skillGames,
            slots,
            viewAll,
            liveDealer,
            poker,
            sport,
        },
    } = useLocale();

    const titleLetters = skillGames("Skill Games").length;

    useEffect(() => {
        if (!authStore.isAuthorized) {
            const referralId = getRefId();
            authStore.setReferralId(referralId);
        }
    }, [authStore.isAuthorized]);

    const [modalState, setModalState] = useState({
        register: false,
        login: false,
        businessMsg: false,
        notifications: false,
    });

    const userNotifications =
        authStore.currentUser?.notification?.numberOfUnseenNotifications;
    const newNotifications = userNotifications ? userNotifications : undefined;

    const getRefId = () => {
        try {
            const { ref } = qs.parse(search.substring(1));
            if (ref) {
                localStorage.setItem("refId", ref as string);
                return ref as string;
            } else {
                return localStorage.getItem("refId");
            }
        } catch (e) {}

        return null;
    };

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    const onHiddenChange = async (balanceHidden: boolean) => {
        try {
            await authStore.updateHiddenBalance(balanceHidden);
        } catch (err) {
            errorService.sendError(
                errorOnSaveState(
                    "Ошибка при попытке сохранить состояния (ошибка сети)"
                )
            );
        }
    };

    const styles = useStyles();

    // Данные для стримов
   
    useEffect(() => {
        const fetchSportEvent = async () => {
            try {
                const response: AxiosResponse<ISportEventResponse> =
                    await getSportEvent();
                if (response.data && response.data.data) {
                    setSportEvent(response.data.data.data);
                } else {
                    setSportEvent(null);
                }
            } catch (error) {
                error;
                setSportEvent(null);
            }
        };

        fetchSportEvent();
    }, []);

    const [streams, setStreams] = useState<StreamData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        const fetchSportEvent = async () => {
            try {
                const response: AxiosResponse<ISportEventResponse> =
                    await getSportEvent();
                if (response.data && response.data.data) {
                    setSportEvent(response.data.data.data);
                } else {
                    setSportEvent(null);
                }
            } catch (error) {
                error;
                setSportEvent(null);
            }
        };

        fetchSportEvent();
    }, []);

    const isStreamLive = (stream: StreamData) => {
        // Если нет времени начала, то стрим оффлайн
        if (!stream.streamingSchedule || !stream.streamingSchedule.from) {
            return false; // Стрим оффлайн
        }

        const now = moment(); // Текущее время
        const from = moment(stream.streamingSchedule.from, 'HH:mm'); // Время начала стрима
        const to = stream.streamingSchedule.to ? moment(stream.streamingSchedule.to, 'HH:mm') : null; // Время окончания, если указано

        // Проверка корректности времени начала
        if (!from.isValid()) return false;

        // Случай 1: Стрим начался, но время окончания не указано
        if (!to) {
            // Стрим идет, если текущее время больше или равно времени начала
            return now.isSameOrAfter(from);
        }

        // Случай 2: Стрим начинается и заканчивается в течение одного дня
        if (from.isBefore(to)) {
            return now.isBetween(from, to, null, '[)');
        }

        // Случай 3: Стрим начинается поздно и продолжается после полуночи
        if (from.isAfter(to)) {
            return now.isAfter(from) || now.isBefore(to);
        }

        // Случай 4: Стрим идет круглосуточно (начало и конец совпадают)
        if (from.isSame(to)) {
            return true;
        }

        return false;
    };


    useEffect(() => {
        const fetchStreams = async () => {
            try {
                const response = await getStreamList();
                console.log("Stream data:", response.data.data);

                const updatedStreams = response.data.data.data.map(stream => ({
                    ...stream,
                    online: isStreamLive(stream)
                }));

                setStreams(updatedStreams);
                setIsLoading(false);
            } catch (err) {
                console.error("Error fetching streams:", err);
                setError('Failed to load streams');
                setIsLoading(false);
            }
        };

        fetchStreams();

        const intervalId = setInterval(() => {
            console.log(`[${moment().format('HH:mm:ss')}] Updating stream statuses`);
            setStreams(prevStreams =>
                prevStreams.map(stream => ({
                    ...stream,
                    online: isStreamLive(stream)
                }))
            );
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);


    //Тест для игр
    const [slotItems, setSlotItems] = useState<ISlotsResponse[]>([]); // Explicitly typed initial state
    const [liveDealers, setLiveDealers] = useState<IExternalGameResponse[]>([]); // Explicitly typed initial state

    useEffect(() => {
        const fetchSlots = async () => {
            try {
                const response = await getGames({
                    limit: 20,
                    offset: 0,
                    type: ExternalGameType.SLOTS,
                });
                setSlotItems(response.data.games);
            } catch (error) {
                console.error("Error fetching slots:", error);
            }
        };

        const fetchLiveDealers = async () => {
            try {
                const response = await getGames({
                    limit: 20,
                    offset: 0,
                    type: ExternalGameType.LIVE_DEALER,
                });
                setLiveDealers(response.data.games);
            } catch (error) {
                console.error("Error fetching live dealers:", error);
            }
        };

        fetchSlots();
        fetchLiveDealers();
    }, []);

    const [sportEvent, setSportEvent] = useState<SportEventData | null>(null);

    useEffect(() => {
        const fetchSportEvent = async () => {
            try {
                const response: AxiosResponse<ISportEventResponse> =
                    await getSportEvent();
                if (response.data && response.data.data) {
                    setSportEvent(response.data.data.data);
                } else {
                    console.error("No data received from getSportEvent");
                    setSportEvent(null);
                }
            } catch (error) {
                console.error(
                    "Ошибка при получении данных о спортивном событии:",
                    error
                );
                setSportEvent(null);
            }
        };

        fetchSportEvent();
    }, []);

    return (
        <Box className={styles.containerLobby}>
            <ModalProvider.Provider value={{ openModal, closeModal }}>
                {modalState.register && <RegisterForm />}
                {modalState.login && <LoginForm />}
                {modalState.businessMsg && <BusinessModal />}
                {modalState.notifications && <Notifications />}
                <div className={styles.header}>
                    {isHalyk ? (
                        <HalykAppBar
                            isGamePage={false}
                            sideMenuIsOpen={false}
                        />
                    ) : (
                        <AppBar isGamePage={false} sideMenuIsOpen={false} />
                    )}
                    <MobileNavMenu />
                </div>

                <Box component="div" className={styles.content}>
                    <div className={styles.lobbyTitle}>
                        {generalStore.isHalyk ? (
                            <div className={styles.underHeroContentHalyk}>
                                <div className={styles.heroSectionHalyk}>
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        className={styles.heroVideo}
                                    >
                                        <source src={video} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        ) : (
                                <div className={styles.heroSection}>
                                    <video
                                        ref={videoRef}
                                        key={videoSrc} // Force re-render when source changes
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        className={styles.heroVideo}
                                    >
                                        <source src={videoSrc} type="video/mp4" />
                                    </video>
                                    <MainActionButton
                                        appearanceTheme="primary"
                                        text="I want Dicechess"
                                        className={styles.playButton}
                                        onClick={() => {
                                            history.push(paths.diceChessLobby);
                                        }}
                                    />
                                </div>
                        )}

                        <div className={styles.underSportContentHalyk}>
                            <div className={styles.pockerSlotsHalyk}>
                                <img
                                    src={pockerSlot}
                                    alt="Pocker Slots"
                                    className={styles.pockerImageHalyk}
                                />
                                <MainActionButton
                                    appearanceTheme="primary"
                                    text={poker("Poker")}
                                    className={styles.buttonHalyk}
                                    onClick={() => {
                                        history.push(paths.jackpoker);
                                    }}
                                />
                            </div>
                            <div className={styles.pockerSlotsHalyk}>
                                {sportEvent &&
                                    sportEvent.participants &&
                                    sportEvent.participants.length > 0 ? (
                                    <div
                                        className={
                                            styles.halykSportEventDashboard
                                        }
                                    >
                                        <div className={styles.eventHeader}>
                                            <div
                                                className={styles.eventIcon}
                                            ></div>
                                            <span
                                                className={styles.eventName}
                                            >
                                                {sportEvent.title}
                                            </span>
                                            <div
                                                className={styles.eventIcon}
                                                style={{
                                                    visibility: "hidden",
                                                }}
                                            ></div>
                                        </div>
                                        <div
                                            className={styles.participants}
                                        >
                                            <div
                                                className={
                                                    styles.participantContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.participant
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.participantAvatar
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                sportEvent
                                                                    .participants[0]
                                                                    .avatarURL
                                                                    ? fileHelper.getMediaFileURL(
                                                                        sportEvent
                                                                            .participants[0]
                                                                            .avatarURL
                                                                    )
                                                                    : ""
                                                            }
                                                            alt={
                                                                sportEvent
                                                                    .participants[0]
                                                                    .name
                                                            }
                                                            className={
                                                                styles.avatarImage
                                                            }
                                                        />
                                                    </div>
                                                    <span
                                                        className={
                                                            styles.participantPercentage
                                                        }
                                                    >
                                                        {
                                                            sportEvent
                                                                .participants[0]
                                                                .coefficient
                                                        }
                                                        %
                                                    </span>
                                                    <span
                                                        className={
                                                            styles.participantName
                                                        }
                                                    >
                                                        {
                                                            sportEvent
                                                                .participants[0]
                                                                .name
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    styles.versusContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.versusLine
                                                    }
                                                ></div>
                                                <span
                                                    className={
                                                        styles.versus
                                                    }
                                                >
                                                    VS
                                                </span>
                                                <div
                                                    className={
                                                        styles.versusLine
                                                    }
                                                ></div>
                                            </div>

                                            <div
                                                className={
                                                    styles.participantContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.participant
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.participantAvatar
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                sportEvent
                                                                    .participants[1]
                                                                    .avatarURL
                                                                    ? fileHelper.getMediaFileURL(
                                                                        sportEvent
                                                                            .participants[1]
                                                                            .avatarURL
                                                                    )
                                                                    : ""
                                                            }
                                                            alt={
                                                                sportEvent
                                                                    .participants[1]
                                                                    .name
                                                            }
                                                            className={
                                                                styles.avatarImage
                                                            }
                                                        />
                                                    </div>
                                                    <span
                                                        className={
                                                            styles.participantPercentage
                                                        }
                                                    >
                                                        {
                                                            sportEvent
                                                                .participants[1]
                                                                .coefficient
                                                        }
                                                        %
                                                    </span>
                                                    <span
                                                        className={
                                                            styles.participantName
                                                        }
                                                    >
                                                        {
                                                            sportEvent
                                                                .participants[1]
                                                                .name
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.odds}>
                                            {sportEvent.participants.map(
                                                (participant, index) => (
                                                    <div
                                                        key={index}
                                                        className={
                                                            styles.odd
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.oddNumber
                                                            }
                                                        >
                                                            {index + 1}
                                                        </span>
                                                        <span
                                                            className={
                                                                styles.oddValue
                                                            }
                                                        >
                                                            {
                                                                participant.coefficient
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div>No sport event data available</div>
                                )}

                                <MainActionButton
                                    appearanceTheme="primary"
                                    text="Sport"
                                    className={styles.buttonHalyk}
                                    onClick={() => {
                                        // Действие, которое должно произойти при нажатии на кнопку
                                        history.push(paths.sports);
                                    }}
                                />
                            </div>
                        </div>

                        <div className={styles.slotsContainer}>
                            <div className={styles.line}></div>
                            <div className={styles.slotsHeader}>
                                <p className={styles.slotsTitle}>
                                    {slots("Slots")}
                                </p>
                                <MainActionButton
                                    appearanceTheme="primary"
                                    text={viewAll("View All")}
                                    className={styles.viewAllButton}
                                    onClick={() => {
                                        history.push(paths.algaltente);
                                    }}
                                />
                            </div>
                            <div className={styles.slotsGrid}>
                                {slotItems.map((slot, index) => (
                                    <img
                                        key={index}
                                        src={fileHelper.getMediaFileURL(
                                            slot.image
                                        )}
                                        onClick={async () => {
                                            history.push(
                                                `/slots/${slot.id}/play`
                                            );
                                        }}
                                        alt={slot.name}
                                        className={styles.slotImage}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className={styles.liveCasinoBlock}>
                            <div className={styles.line}></div>
                            <div className={styles.slotsHeader}>
                                <p className={styles.slotsTitle}>
                                    {liveDealer("Live Casino")}
                                </p>
                                <MainActionButton
                                    appearanceTheme="primary"
                                    text={viewAll("View All")}
                                    className={styles.viewAllButton}
                                    onClick={() => {
                                        history.push(paths.liveDealer);
                                    }}
                                />
                            </div>
                            <div className={styles.slotsGrid}>
                                {liveDealers.map((dealer, index) => (
                                    <img
                                        key={index}
                                        src={fileHelper.getMediaFileURL(
                                            dealer.image
                                        )}
                                        onClick={async () => {
                                            history.push(
                                                `/live-dealer/${dealer.id}/play`
                                            );
                                        }}
                                        alt={dealer.name}
                                        className={styles.slotImage}
                                    />
                                ))}
                            </div>
                        </div>

                        {!generalStore.isHalyk && (
                            <div className={styles.streamChannelsBlock}>
                                <div className={styles.line}></div>
                                <div className={styles.streamHeader}>
                                    <p className={styles.streamTitle}>Our Stream Channels</p>
                                </div>

                                {isLoading ? (
                                    <p>Loading streams...</p>
                                ) : (
                                    <div className={styles.streamGrid}>
                                        {streams.map((stream, index) => (
                                            <div key={index} className={styles.streamSlots}>
                                                <img
                                                    src={fileHelper.getMediaFileURL(stream.imageURL)}
                                                    alt={stream.name}
                                                    className={styles.streamImage}
                                                />
                                                <p className={styles.streamName}>{stream.name}</p>
                                                <div className={styles.bottomBlock}>
                                                    <p className={`${styles.streamStatus} ${stream.online ? styles.online : styles.offline}`}>
                                                        {stream.online ? "Online" : "Offline"}
                                                    </p>
                                                    <div className={styles.streamButtons}>
                                                        {stream.youtube && (
                                                            <a href={stream.youtube} target="_blank" rel="noopener noreferrer" className={styles.streamButton}>
                                                                <img className={styles.streamIcon} src={youTubeIcon} alt="YouTube" />
                                                            </a>
                                                        )}
                                                        {stream.twitch && (
                                                            <a href={stream.twitch} target="_blank" rel="noopener noreferrer" className={styles.streamButton}>
                                                                <img className={styles.streamIcon} src={twitchIcon} alt="Twitch" />
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>                        
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div className={styles.line}></div>
                            </div>
                        )}

                    </div>
                    <Footer isMainPage={true} />
                </Box>
            </ModalProvider.Provider>
        </Box>
    );
};

export default observer(MobileVersion);
