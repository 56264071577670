import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        placeItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        "&.disabled": {
            opacity: 0.5,
            cursor: "wait",
        },
    },

    primary: {
        background: "#00BA6C",
        borderRadius: 4,
        padding: "10px 10px",
        fontSize: 20,
        fontWeight: 700,
        color: "#000",
    },

    secondary: {
        borderRadius: 55,
        border: "2px solid #00BA6C",
        background: "#00BA6C4D",
        padding: "15px 32px",
        color: "#00BA6C",
        fontSize: 16,
        fontWeight: 700,
    },

    contained: {},

    outlined: {
        background: "none",
        borderRadius: 4,
        padding: "10px 10px",
    },

    silver: {
        border: "2px solid #F5F5F599",
        color: "#F5F5F599",
    },

    green: {
        border: "2px solid #00BA6C",
        background: "#00BA6C4D",
        color: "#00BA6C",
    },
    red: {
        border: "2px solid #FF1C29",
        background: "#FF1C294D",
        color: "#FF1C29",
    },

    shadow: {
        border: "2px solid #F5F5F599",
        color: "#F5F5F599",
        background: "rgba(245, 245, 245, 0.1)",
    },

    borderNone: {
        border: "none",
    },

    textSpan: {
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    textInButton: {},

    primaryDisabled: {
        opacity: "0.5",
    },
});
