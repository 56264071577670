import {
    ColorVariant,
    EGameRematchStatus,
    GameResultData,
    GameStatus,
    IPlayer,
    OnlineStatus,
} from "../models";

export type Dice = [number] | [number, number] | [number, number, number];

export interface IClockPerPlayer {
    color: ColorVariant;
    timeleft: number;
    running: boolean;
}

export interface IConnectivityPerPlayer {
    idx: number;
    status: OnlineStatus;
}

export enum ERolledFigures {
    p = "p",
    n = "n",
    b = "b",
    r = "r",
    q = "q",
    k = "k",
}

export enum ERolledFiguresNumber {
    p = 1,
    n = 2,
    b = 3,
    r = 4,
    q = 5,
    k = 6,
}

export interface ChessMoveHistoryItem {
    date: string;
    fenAfter: string;
    isCastling: boolean;
    move: string;
    moveFigure: ERolledFigures;
    // КТО побил фигуру
    user: string;
    // Битая Фигура
    beatFigure?: ERolledFigures;
}

export interface IConvertedRoleFigure {
    figure: ERolledFiguresNumber;
    active: boolean;
    id: number;
}

export interface IGameState {
    fen: string;
    history: string[];
    legalMoves: string[];
    legalPieces: string[];
    rolledFigures: IConvertedRoleFigure[] | undefined;
    movesHistory: ChessMoveHistoryItem[];
    undo: null;
}

export interface IGameSettings {
    bet: number;
    doubling: boolean;
    fen: string;
    private: boolean;
    privateChat: boolean;
    rake: number;
    randomColor: boolean;
    rounds: number;
    timeControl: { time: number; increment: number };
    increment: number;
    time: number;
    undo: boolean;
}

export interface IDoublingData {
    multiplier: number;
    accepted?: boolean;
    aggressor?: ColorVariant;
}

export interface INextRoundMatchData {
    gameId: string;
    startAt: string;
}

export interface IRematchInfo {
    [ColorVariant.white]: null | boolean;
    [ColorVariant.black]: null | boolean;
    gameId?: string;
    gameCreatedTime?: string;
}

export interface IGuest {
    id: string;
    nickname: string;
    avatar?: string;
}
export interface IInitGameData {
    clock: [IClockPerPlayer, IClockPerPlayer];
    connectivity: [IConnectivityPerPlayer, IConnectivityPerPlayer];
    doubling: IDoublingData;
    drawingProposer: null | ColorVariant;
    history: string[];
    inactivity: null;
    messages: [];
    players: [IPlayer, IPlayer];
    round: number;
    settings: IGameSettings;
    state: IGameState;
    status: GameStatus;
    over: GameResultData | null;
    rematch: IRematchInfo;
    guests: IGuest[];
}

export interface IPiece {
    color: ColorVariant;
    piece: string;
}

export interface IGuestNew {
    _id: string;
    agreement: boolean;
    blockReason: null;
    email: string;
    isBot: boolean;
    isSupport: boolean;
    isSystemAvatar: boolean;
    nickname: string;
    online: boolean;
    referralProgramId: null;
    type: string;
    avatar: string;
}

export type IBoard = {};

export interface IBaseGameDialogMessage {
    message;
    color: ColorVariant;
    time: number;
}
export interface IGameDialogMessage extends IBaseGameDialogMessage {
    position: "rtl" | "ltr" | "center";
    props?: any;
}

export const isGameDialogMessage = (
    t: IGameMessage
): t is IGameDialogMessage => {
    return (t as IGameDialogMessage).message !== undefined;
};

export const isGameNotification = (t: IGameMessage): t is INotification => {
    return (t as IGameMoveMessage).color === undefined;
};

export interface IGameMoveMessage {
    color: ColorVariant;
    pieces: {
        dice: number;
        type: string;
        moved: boolean;
        move: string;
    }[];
    time: number;
    position: "ltr" | "rtl";
    castling?: "long" | "short";
}

export const isGameMoveMessage = (t: IGameMessage): t is IGameMoveMessage => {
    return (t as IGameMoveMessage).pieces !== undefined;
};

export type IGameMessage =
    | IGameDialogMessage
    | IGameNotification
    | INotification
    | IGameMoveMessage;

export interface ILocaleItem {
    defaultValue: string;
    label: string;
    props?: any;
}

export interface IGameNotification {
    gameStatus?: GameStatus;
    message: ILocaleItem;
    position: "center" | "left" | "right";
    time: number;
    props: any;
}

export interface INotification {
    gameStatus?: GameStatus;
    message: ILocaleItem;
    position: "center" | "left" | "right";
    time: number;
    props: any;
}

export interface ITournamentAlertsState {
    quotesModalState: boolean;
}

export enum ETournamentAlertsState {
    newMatchAlert = "newMatchAlert",
    inProgressState = "inProgressState",
    quotesModalState = "quotesModalState",
}

//========
export interface ICommunicationChatMessage {
    position: "rtl" | "ltr" | "center";
    rank: string;
    message: string;
    owner: {
        agreement: boolean;
        blockReason: null;
        email: string;
        isAdmin: boolean;
        isBot: boolean;
        isSupport: boolean;
        isSystemAvatar: boolean;
        nickname: string;
        online: boolean;
        password: string;
        referralProgramId: null;
        userType: string;
        _id: string;
    };
    time: string;
    type: number;
    props?: any;
}

export interface ICommunicationGameMain {
    color: ColorVariant;
    pieces: {
        dice: number;
        type: string;
        moved: boolean;
        move: string;
    }[];
    props: any;
    message: any;
    figures: any;
    isLastElem: any;
}

export interface ICommunicationGameHistory extends ICommunicationGameMain {
    from: string;
    to: string;
    from2: string;
    to2: string;
    figureChange: string;
    beatOnMove: string;
    beatOnMoveFigure: string;
    time: number;
    player: IPlayer;
    fen: string;
    moveNumber: number;
    position: string;
    animated: boolean;
}

export interface ICommunicationGameHistoryNew extends ICommunicationGameMain {
    position: "rtl" | "center" | "ltr";
    time: number;
    figures: string[];
}

export interface ICommunicationGameNotification extends ICommunicationGameMain {
    position: "center" | "left" | "right";
    time: number;
    gameStatus?: GameStatus;
}

export type ICommunicationHistoryNotification =
    | ICommunicationGameNotification
    | ICommunicationGameHistoryNew;

export interface IRematchCollector {
    rematchStatus: EGameRematchStatus;
    rematchStartedBy?: string;
    matchCount?: number;
    gameDieTime?: string;
    nextMatch?: string;
    matchId?: number;
    rematchEnd?: string;
}

export const isGameDialogMessageNew = (t: any): t is any => {
    return (t as any).message !== undefined;
};

export const isGameMoveMessageNew = (t: any): t is any => {
    return (t as any).pieces !== undefined;
};

export const isGameNotificationNew = (t: any): t is any => {
    return (t as any).color === undefined;
};
